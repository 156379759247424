import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import classNames from 'classnames';
import PrimaryButton from 'pages/Dashboard/components/PrimaryButton';
import PrimaryRangePicker from 'pages/Dashboard/components/PrimaryRangePicker';
import PrimarySelect from 'pages/Dashboard/components/PrimarySelect';
import { containerClassName, titleClassName } from 'pages/Dashboard/constants';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Icon1 } from 'styles/images/marketingIndex/icon-marketing-index-1.svg';
import { ReactComponent as Icon2 } from 'styles/images/marketingIndex/icon-marketing-index-2.svg';
import { ReactComponent as Icon3 } from 'styles/images/marketingIndex/icon-marketing-index-3.svg';
import { ReactComponent as Icon4 } from 'styles/images/marketingIndex/icon-marketing-index-4.svg';
import { ReactComponent as Icon5 } from 'styles/images/marketingIndex/icon-marketing-index-5.svg';

interface MarketingIndexProps {
  isButton?: boolean;
}

function MarketingIndex({ isButton = true }: MarketingIndexProps) {
  const navigate = useNavigate();

  const handleRedirectToAffiliate = () => {
    navigate('/dashboard/affiliate');
  };

  const options = [
    {
      label: 'Ngày trước',
      value: 1,
    },
    {
      label: 'Tuần trước',
      value: 2,
    },
    {
      label: 'Tháng trước',
      value: 3,
    },
    {
      label: 'Quý trước',
      value: 4,
    },
  ];

  return (
    <div className={classNames('bg-gray-100', containerClassName)}>
      <div className="flex flex-col gap-2.5 xmd:flex-row justify-between xmd:items-center">
        <div className="flex items-center gap-x-4">
          <h2 className={titleClassName}>Chỉ số tiếp thị</h2>
          {isButton && (
            <PrimaryButton
              title="Affiliates"
              onClick={handleRedirectToAffiliate}
            />
          )}
        </div>
        <FormTera className="grid grid-cols-2 xmd:flex gap-x-2.5">
          <FormTeraItem name="prepare" className="mb-0">
            <PrimarySelect options={options} />
          </FormTeraItem>
          <FormTeraItem name="date" className="mb-0">
            <PrimaryRangePicker />
          </FormTeraItem>
        </FormTera>
      </div>
      <div className="grid grid-cols-2 xmd:grid-cols-4 gap-2.5 mt-4">
        <div className="flex flex-col gap-2.5 bg-white/80 rounded-[10px] shadow-[0_4px_4px_0_rgba(0,0,0,0.1),0_-4px_4px_0_rgba(0,0,0,0.03)] p-4">
          <div className="flex justify-between">
            <span className="font-bold text-2xl">0 +</span>
            <Icon1 />
          </div>
          <div className="flex justify-between">
            <span className="text-sm text-gray-500">Click</span>
            <span className="flex gap-x-1 items-center">
              <Icon5 />
              <span className="text-sm text-[#374151]">0%</span>
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-2.5 bg-white/80 rounded-[10px] shadow-[0_4px_4px_0_rgba(0,0,0,0.1),0_-4px_4px_0_rgba(0,0,0,0.03)] p-4">
          <div className="flex justify-between">
            <span className="font-bold text-2xl">0 +</span>
            <Icon2 />
          </div>
          <div className="flex justify-between">
            <span className="text-sm text-gray-500">Người đăng ký</span>
            <span className="flex gap-x-1 items-center">
              <Icon5 />
              <span className="text-sm text-[#374151]">0%</span>
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-2.5 bg-white/80 rounded-[10px] shadow-[0_4px_4px_0_rgba(0,0,0,0.1),0_-4px_4px_0_rgba(0,0,0,0.03)] p-4">
          <div className="flex justify-between">
            <span className="font-bold text-2xl">0 +</span>
            <Icon3 />
          </div>
          <div className="flex justify-between">
            <span className="text-sm text-gray-500">Chuyển đổi</span>
            <span className="flex gap-x-1 items-center">
              <Icon5 />
              <span className="text-sm text-[#374151]">0%</span>
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-2.5 bg-white/80 rounded-[10px] shadow-[0_4px_4px_0_rgba(0,0,0,0.1),0_-4px_4px_0_rgba(0,0,0,0.03)] p-4">
          <div className="flex justify-between">
            <span className="font-bold text-2xl">0 +</span>
            <Icon4 />
          </div>
          <div className="flex justify-between">
            <span className="text-sm text-gray-500">Layout</span>
            <span className="flex gap-x-1 items-center">
              <Icon5 />
              <span className="text-sm text-[#374151]">0%</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketingIndex;
