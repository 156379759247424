import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DocumentTextSolid,
  HomeSolid,
  RectangleStackSolid,
  Tooltip,
  UserCircleSolid,
  WalletSolid,
} from 'tera-dls';

const itemClassName =
  'group cursor-pointer w-10 h-10 flex justify-center items-center rounded-md hover:bg-blue-50 hover:shadow-[0_4px_4px_0_rgba(0,0,0,0.05),0_4px_4px_0_rgba(0,0,0,0.03)]';

const activeClassName =
  'bg-blue-50 shadow-[0_4px_4px_0_rgba(0,0,0,0.05),0_4px_4px_0_rgba(0,0,0,0.03)]';

const iconClassName = 'w-6 h-6 text-gray-500 group-hover:text-[#00AFEF]';
const iconActiveClassName = 'text-[#00AFEF]';

export const menus = [
  {
    title: 'Trang chủ',
    icon: (
      <HomeSolid className={classNames(iconClassName, iconActiveClassName)} />
    ),
    url: '/dashboard',
    classNames: activeClassName,
  },
  {
    title: 'Gói dịch vụ',
    icon: <RectangleStackSolid className={classNames(iconClassName)} />,
    url: '/service-package',
  },
  {
    title: 'Thông tin cá nhân',
    icon: <UserCircleSolid className={classNames(iconClassName)} />,
    url: '/user/information',
  },
  {
    title: 'Ví Tera',
    icon: <WalletSolid className={classNames(iconClassName)} />,
    url: '/wallet',
  },
  {
    title: 'Hóa đơn',
    icon: <DocumentTextSolid className={classNames(iconClassName)} />,
    url: '/invoice',
  },
];

function Nav() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-y-5">
      {menus.map((menu) => (
        <Tooltip title={menu.title} placement="right">
          <div
            onClick={() => navigate(menu.url)}
            className={classNames(itemClassName, menu.classNames)}
          >
            {menu.icon}
          </div>
        </Tooltip>
      ))}
    </div>
  );
}

export default Nav;
