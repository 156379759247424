import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const WalletEndpoint = `${endpoint}/portal/wallet`;

const WalletApi = {
  recharge: async ({ params }) =>
    await api
      .post(`${WalletEndpoint}/recharge`, params)
      .then((result) => result.data),
  withdrawal: async ({ params }) =>
    await api
      .post(`${WalletEndpoint}/withdrawal`, params)
      .then((result) => result.data),
  getQr: async ({ params }) =>
    await api
      .get(`${WalletEndpoint}/get-qr`, params)
      .then((result) => result.data?.data),
  getAmount: async () =>
    await api
      .get(`${WalletEndpoint}/get-amount`)
      .then((result) => result.data?.data),
  sendMail: async () =>
    await api
      .post(`${WalletEndpoint}/create-transaction-session`)
      .then((result) => result.data),
  verifyOtp: async ({ params }) =>
    await api
      .post(`${WalletEndpoint}/verify-otp`, params)
      .then((result) => result.data),
};

export default WalletApi;
