import { useMutation, useQuery } from '@tanstack/react-query';
import { messageError } from '_common/constants/message';
import FormTera from '_common/dof/FormTera';
import { useStores } from '_common/hooks';
import _ from 'lodash';
import { toJS } from 'mobx';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, notification } from 'tera-dls';
import { UserApi } from './api';
import UserForm from './containers/UserForm';

const UserInformation = ({ value }) => {
  const form = useForm({ mode: 'onChange' });
  const ref = useRef(null);
  const { handleSubmit } = form;
  const navigate = useNavigate();
  const { authStore } = useStores();
  const { user } = authStore;
  const { type } = toJS(user);
  const [avatar, setAvatar] = useState<any>();

  const { data: detail, refetch } = useQuery(
    ['get-user-profile'],
    () => UserApi.getProfile(),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    (variables: any) => UserApi.update(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          refetch();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        const errorMessage = error?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  const handleSubmitForm = (values) => {
    const data = _.pick(values, [
      'full_name',
      'phone',
      'job_title',
      'department',
    ]);
    mutateUpdate({
      ...data,
      ...(avatar?.url && {
        file_upload: {
          url: avatar?.url,
        },
      }),
    });
  };

  useEffect(() => {
    if (!ref?.current) return;
    const element = ref?.current?.querySelector(value);
    element && element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [ref, value]);

  useEffect(() => {
    if (!detail) return;
    setAvatar({ url: detail.avatar_url });
    form.reset({
      full_name: detail.full_name,
      email: detail.email,
      phone: detail.phone,
      job_title: detail.job_title,
      department: detail.department,
      username: detail.username,
      password: '1123123223',
      register_time: detail.register_time ? moment(detail.register_time) : '',
      expiration_time: detail.expiration_time
        ? moment(detail.expiration_time)
        : '',
      trial_time: detail.trial_time,
    });
  }, [detail]);

  const handleClickRegistration = () => navigate('/business/information');

  return (
    <div className="px-[100px] py-[50px]" ref={ref}>
      <div id="e1" className="flex flex-col justify-center items-center gap-5">
        {/* <div className="p-2.5 flex gap-2.5 text-[13px] font-normal border-[1px] items-center border-red-500 rounded-[5px] w-full justify-start bg-red-50">
          <InformationCircleOutlined className="w-5 h-5 text-red-500" />
          <div className="text-[13px] flex gap-1 ">
            Thời gian dùng thử còn{' '}
            <span className="text-red-600">{detail?.trial_time} ngày</span>
          </div>
        </div> */}
        <div className="flex flex-col gap-2.5 justify-center items-center">
          <div className="text-[#0095D9] text-[28px] leading-[28px] font-bold">
            Thông Tin Cá Nhân
          </div>
          <div className="text-xl font-normal">Quản lý thông tin cá nhân</div>
        </div>
        <div className="flex justify-between w-full">
          {['owner', 'member'].includes(type) ? (
            <div />
          ) : (
            <div className="flex gap-2.5 items-center">
              <span className="text-red-400 text-sm">
                Bạn chưa đăng ký tài khoản doanh nghiệp.
              </span>
              <span
                className="text-blue-600 text-sm cursor-pointer underline"
                onClick={handleClickRegistration}
              >
                Đăng ký tại đây
              </span>
            </div>
          )}
          <Button
            onClick={handleSubmit(handleSubmitForm)}
            loading={isLoadingUpdate}
            className="bg-green-500 hover:bg-green-700 rounded-xsm px-[12px] py-[8px] text-sm font-normal"
          >
            Lưu
          </Button>
        </div>
        <FormTera
          form={form}
          onSubmit={handleSubmit(handleSubmitForm)}
          className="w-full bg-white"
        >
          <UserForm avatar={avatar} updateAvatar={setAvatar} value={detail} />
        </FormTera>
      </div>
    </div>
  );
};

export default UserInformation;
