import { useState } from 'react';
import { Button, formatNumber } from 'tera-dls';
import Registration from '../Registration';

const Icon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={19}
      fill="none"
      {...props}
    >
      <path
        fill="#0E9F6E"
        d="M.773 3.125A4.483 4.483 0 0 1 3.75 2h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0 0 17.25.5H3.75A3 3 0 0 0 .773 3.125ZM.773 6.125A4.483 4.483 0 0 1 3.75 5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0 0 17.25 3.5H3.75A3 3 0 0 0 .773 6.125ZM3.75 6.5a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h13.5a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3H13.5a.75.75 0 0 0-.75.75 2.25 2.25 0 0 1-4.5 0 .75.75 0 0 0-.75-.75H3.75Z"
      />
    </svg>
  );
};

const Item = ({ value }) => {
  const [openRegistrationModal, setOpenRegistrationModal] =
    useState<boolean>(false);

  const canRegistration = !Boolean(value?.package_empty);

  return (
    <>
      <div className="hover:bg-blue-50 flex flex-wrap rounded-[10px] py-[10px] px-[16px] border-[1px] border-green-500 bg-gray-50 w-full h-auto">
        <div className="flex gap-[16px] justify-between w-full">
          <div className="flex gap-[16px]">
            {value?.file_url ? (
              <img
                className="size-[50px] rounded-[6px]"
                src={value?.file_url}
              />
            ) : (
              <div className="p-[15px] bg-[#0E9F6E40] size-[50px] rounded-[6px] flex">
                <Icon className="text-green-500" />
              </div>
            )}
            <div className="flex flex-col gap-[5px] flex-1 break-word ">
              <div className="text-base font-medium flex items-center gap-[5px] leading-[16px]">
                <span className="flex items-end gap-[5px] ">
                  <div className="flex-1 line-clamp-2">{value?.name}</div>-
                  <span className="text-[10px] leading-[11px] shrink-0">
                    Chỉ từ
                  </span>
                </span>
                <span className="text-yellow-600 font-bold text-[24px] leading-[28px] flex gap-[2px] shrink-0">
                  {value?.min_price ? formatNumber(value?.min_price) : 0}
                  <span className="text-yellow-600 font-bold text-base">đ</span>
                </span>
              </div>
              {value?.description && (
                <div className="text-gray-500 font-normal line-clamp-2 w-full max-h-[38px]">
                  {value?.description}
                </div>
              )}
              <div className="text-gray-500 font-normal leading-[15px] flex gap-1">
                {value?.packages?.length > 0 && (
                  <>
                    <span className="shrink-0 text-gray-700">
                      {' '}
                      Gói sản phẩm:
                    </span>
                    <div className="line-clamp-1 w-full ">
                      {value?.packages?.map((item, index) => {
                        return (
                          <span key={item.id}>
                            <span className="underline text-blue-600 whitespace-nowrap ">
                              {item?.name}
                            </span>
                            {index < value?.packages?.length - 1 && ', '}
                          </span>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {canRegistration && (
            <div className="shrink-0">
              <Button
                className="rounded-full leading-[15px] px-[28px] py-[8px] font-normal"
                onClick={() => setOpenRegistrationModal(true)}
              >
                Kích hoạt
              </Button>
            </div>
          )}
        </div>
      </div>
      {openRegistrationModal && (
        <Registration
          value={value?.id}
          open={openRegistrationModal}
          onClose={() => setOpenRegistrationModal(false)}
        />
      )}
    </>
  );
};

export default Item;
