import DefaultImage from '_common/component/DefaultImage';
import { useStores } from '_common/hooks';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import logo from 'styles/images/uiNew/logo.png';
import { Bars3BottomLeftSolid } from 'tera-dls';
import PrimaryButton from './components/PrimaryButton';
import PrimarySearch from './components/PrimarySearch';
import Affiliate from './containers/Main/Affiliate';
import Footer from './containers/Main/Footer';
import Invoice from './containers/Main/Invoice';
import ListModule from './containers/Main/ListModule';
import MarketingIndex from './containers/Main/MarketingIndex';
import News from './containers/Main/News';
import ServicePackage from './containers/Main/ServicePackage';
import DrawerMenu from './containers/Menu/DrawerMenu';
import DrawerSide from './containers/SideBar/DrawerSideBar';

const Dashboard = () => {
  const {
    authStore: { user },
  } = useStores();
  const { is_publisher } = toJS(user);
  const [openSideBar, setOpenSideBar] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  return (
    <main className="transition-all flex-1 bg-white xl:rounded-l-[30px] xl:shadow-[-4px_0_10px_-0_rgba(0,0,0,0.1)] scrollbar-none  overflow-scroll">
      <div className="flex flex-col xl:px-[15px]">
        <div className="xl:hidden h-[62px] sticky z-50 top-0 p-4 bg-[#F3F3F9] flex justify-between items-center">
          <div className="flex items-center gap-x-4">
            <Bars3BottomLeftSolid
              className="w-5 h-5 text-[#00AFEF]"
              onClick={() => setOpenMenu(true)}
            />
            <img src={logo} className="w-12" />
          </div>
          <div onClick={() => setOpenSideBar(true)}>
            <DefaultImage
              src={user?.avatar_url}
              alt={user?.full_name}
              className="w-[30px] h-[30px] rounded-full"
            />
          </div>
        </div>
        <div className="w-full sticky top-[62px] px-5 py-4 xl:top-0 xl:px-[15px] xl:py-[30px] bg-white z-50">
          <div className="flex justify-between items-center gap-x-4">
            <PrimarySearch />
            <PrimaryButton size="lg" title="+ Thêm ứng dụng" />
          </div>
        </div>
        <div className="flex flex-col gap-[30px] px-5 xl:px-[15px] pt-2">
          <ListModule />
          <Invoice />
          {Boolean(is_publisher) ? <MarketingIndex /> : <Affiliate />}
          <ServicePackage />
          <News />
          {/* <TutorialVideo /> */}
          <Footer />
        </div>
      </div>
      {openSideBar && (
        <DrawerSide open={openSideBar} onClose={() => setOpenSideBar(false)} />
      )}
      {openMenu && (
        <DrawerMenu open={openMenu} onClose={() => setOpenMenu(false)} />
      )}
    </main>
  );
};

export default observer(Dashboard);
