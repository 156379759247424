import classNames from 'classnames';
import { forwardRef } from 'react';

interface ItemProps {
  active?: boolean;
  bankName?: string;
  image?: string;
  fullName?: string;
  number?: string;
  type?: number;
  onClick?: () => void;
}

function Item(props: ItemProps, ref) {
  const {
    active,
    image,
    number,
    type,
    bankName = 'Techcombank',
    fullName = 'Nguyen Van A',
    onClick,
  } = props;
  return (
    <div
      ref={ref}
      onClick={onClick}
      className={classNames(
        'flex items-center gap-x-3 px-2.5 py-3 shadow-[0_4px_4px_0_rgba(0,0,0,0.1)] border rounded-[10px] border-gray-200 cursor-pointer',
        {
          '!border-blue-600 bg-blue-50': active,
        },
      )}
    >
      <div className="w-[30px] h-[30px] shrink-0">
        {image && <img src={image} alt={bankName} className="w-full" />}
      </div>
      <div className="flex flex-col gap-y-[5px] flex-1">
        <span className="font-base text-gray-700 font-medium line-clamp-1 break-word">
          {type == 1 ? bankName : number}
        </span>
        <span className="font-base text-gray-500 uppercase font-light line-clamp-1 break-word">
          {fullName}
        </span>
      </div>
    </div>
  );
}

export default forwardRef(Item);
