import { menus } from '_common/component/Layout/Menu/menus';
import { makeAutoObservable } from 'mobx';

export class CommonStore {
  activeMenu = menus[0]?.key;
  module = 'portal';
  openMenuMore = false;

  constructor() {
    makeAutoObservable(this);
  }

  setActiveMenu = (key: string) => {
    this.activeMenu = key;
  };

  setOpenMenuMore = (open: boolean) => {
    this.openMenuMore = open;
  };
}
