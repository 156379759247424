import React, { useEffect, useState } from 'react';
import Searching from './containers/Searching';
import {
  Button,
  FunnelOutlined,
  getQueryParams,
  PaginationProps,
  updateQueryParams,
} from 'tera-dls';
import { useLocation, useNavigate } from 'react-router-dom';
import InvoiceTable from './containers/Table';
import { useQuery } from '@tanstack/react-query';
import InvoiceApi from './api';
import InvoiceFilter from './containers/Filter';

const Invoice = () => {
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const navigate = useNavigate();
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);

  const {
    data: response,
    refetch,
    isLoading,
  } = useQuery(
    ['get-invoice-list', queryParams],
    () => {
      return InvoiceApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...queryParams,
        },
      });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  const handleFilter = (values: any): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  return (
    <>
      <div className="p-5 flex gap-[16px]">
        <div className="flex flex-col gap-[16px]">
          <div className="flex flex-col gap-2.5">
            <div className="text-[#0095D9] text-[16px] leading-[20px] font-bold">
              Hóa Đơn
            </div>
            <div className="flex gap-2.5">
              <Searching onSearch={handleSearch} />
              <Button
                onClick={() => setOpenFilterModal(true)}
                type="alternative"
                className="rounded-xsm py-1 px-[6px] bg-blue-50"
                icon={
                  <FunnelOutlined className=" text-blue-500 rounded-[4px] shrink-0" />
                }
              />
            </div>
          </div>
          <InvoiceTable
            loading={isLoading}
            data={response?.data}
            pagination={{
              onChange: handleChangePage,
              to: response?.to,
              from: response?.from,
              current: response?.current_page,
              pageSize: response?.per_page,
              total: response?.total,
            }}
          />
        </div>
      </div>
      {openFilterModal && (
        <InvoiceFilter
          open={openFilterModal}
          onClose={() => setOpenFilterModal(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </>
  );
};

export default Invoice;
