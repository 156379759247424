import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const ModuleEndpoint = `${endpoint}/portal/member`;

const UserApi = {
  getList: async (param?) =>
    await api
      .get(`${ModuleEndpoint}/list`, param)
      .then((result) => result.data?.data),
  addToModule: async (param?) =>
    await api
      .post(`${ModuleEndpoint}/add-member-to-module`, param)
      .then((result) => result.data),
  removeMemberInModule: async (param?) =>
    await api
      .post(`${ModuleEndpoint}/remove-member-in-module`, param)
      .then((result) => result.data),
};

export default UserApi;
