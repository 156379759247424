import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageWarning } from '_common/constants/message';

import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { MemberApi } from 'pages/EmployeeInformation/api';
import { USER_STATUS } from 'pages/EmployeeInformation/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification, OptionProps } from 'tera-dls';

interface IProps {
  open: boolean;
  value?: number;
  onClose: () => void;
}

const UpdateStatus = (props: IProps) => {
  const { open = false, value: id, onClose } = props;
  const queryClient = useQueryClient();
  const form = useForm({
    mode: 'onChange',
  });
  const confirm = useConfirm();

  const { data: detail, refetch } = useQuery(
    ['get-user-detail', id],
    () => MemberApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    id && refetch();
  }, [id]);

  useEffect(() => {
    if (detail) {
      form.setValue('is_active', String(detail?.is_active), {
        shouldDirty: false,
      });
    }
  }, [detail]);

  const handleClose = (): void => {
    onClose();
  };

  const { mutate: mutateForm } = useMutation(
    (variables: any) => MemberApi.updateStatus(variables),
    {
      onSuccess: ({ code, msg }) => {
        if (code === 200) {
          queryClient.invalidateQueries(['get-member-list']);
          onClose();
          notification.success({
            message: msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (form?.formState?.isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        className: 'modal-button-center',
        onOk: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  const handleSubmitForm = (values): void => {
    mutateForm({ params: { ...values, member_id: id } });
  };

  const activityOptions: OptionProps[] = Object.entries(USER_STATUS).map(
    ([value, label]) => ({ label, value }),
  );

  return (
    <Modal
      centered={true}
      title="Cập nhật trạng thái"
      open={open}
      width={500}
      closeIcon={false}
      okText="Lưu"
      cancelText="Huỷ"
      onOk={form?.handleSubmit(handleSubmitForm)}
      onCancel={handleCloseConfirm}
      destroyOnClose={true}
    >
      <FormTera form={form} className="h-full" onSubmit={handleSubmitForm}>
        <FormTeraItem name="is_active">
          <Select allowClear={false} options={activityOptions} />
        </FormTeraItem>
      </FormTera>
    </Modal>
  );
};

export default UpdateStatus;
