import { adminEndpoint } from '_common/constants/common';
import api from 'states/drivers';

const AffiliateEndpoint = `${adminEndpoint}/administrator/affiliates`;

const AffiliateApi = {
  register: async () =>
    await api
      .post(`${AffiliateEndpoint}/register-publisher`)
      .then((result) => result.data),
};

export default AffiliateApi;
