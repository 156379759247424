import { observer } from 'mobx-react-lite';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as IconCart } from 'styles/images/Icons/cart.svg';
import { ReactComponent as IconCoin } from 'styles/images/Icons/coin.svg';
import { ReactComponent as IconDashboard } from 'styles/images/Icons/dashboard.svg';
import { ReactComponent as IconHelp } from 'styles/images/Icons/help.svg';
import LogoTera from 'styles/images/Icons/logo-tera.png';
import { ReactComponent as IconProfile } from 'styles/images/Icons/profile.svg';
import backgroundImage from 'styles/images/uiNew/bg-form.png';

import { useMutation, useQuery } from '@tanstack/react-query';
import ModalConfirm from '_common/component/ModalConfirm2';
import { useStores } from '_common/hooks';
import { toJS } from 'mobx';
import CartApi from 'pages/Cart/api';
import { CART_URL } from 'pages/Cart/url';
import FirstLogin from 'pages/Welcome/FirstLogin';
import { useEffect, useState } from 'react';
import { AuthApi } from 'states/api';
import {
  AcademicCapOutlined,
  Badge,
  BuildingOffice2Outlined,
  BuildingStorefrontOutlined,
  DocumentCheckOutlined,
  Dropdown,
  DropdownItem,
  HomeSolid,
  SquaresPlusOutlined,
  UserOutlined,
  WalletOutlined,
  notification,
} from 'tera-dls';

function BasicLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { authStore } = useStores();
  const { user } = authStore;
  const [firstLogin, setFirstLogin] = useState<boolean>(false);
  const {
    authStore: { clear },
  } = useStores();

  const { business, type, is_first } = toJS(user);

  useEffect(() => {
    is_first === 1 && setFirstLogin(Boolean(is_first));
  }, [is_first]);

  const isCancel = business?.status === 'cancelled';
  const { data: total } = useQuery(
    ['get-cart-total'],
    () => CartApi.countCart(),
    {
      enabled: !!business?.id && type === 'owner',
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: () => {
      clear();
    },
    onError: (error: any) => {
      clear();
      const errorMessage = error?.msg;

      notification.error({
        message: errorMessage,
      });
    },
  });

  const itemsMenu: DropdownItem[] = [
    {
      key: 1,
      label: <span className="text-red-500">Đăng xuất</span>,
      onClick: () => onLogout(),
    },
  ];

  const MENU = [
    {
      key: 'dashboard',
      url: '/dashboard',
      name: 'Dashboard',
      icon: IconDashboard,
      sub_menu: null,
    },
    {
      key: 'user',
      url: '/user/information',
      name: 'Thông tin cá nhân',
      icon: UserOutlined,
      sub_menu: [
        {
          key: 'information',
          url: '/user/information',
          name: 'Thông tin cá nhân',
        },
        {
          key: 'login',
          url: '/user/login',
          name: 'Thông tin đăng nhập',
        },
        // {
        //   key: 'registration',
        //   url: '/user/registration',
        //   name: 'Thông tin đăng ký',
        // },
      ],
    },
    ...(['owner', 'individual'].includes(type) && !isCancel
      ? [
          {
            key: 'business',
            url: '/business/information',
            name: 'Thông tin doanh nghiệp',
            icon: BuildingOffice2Outlined,
            sub_menu: [
              {
                key: 'information',
                url: '/business/information',
                name: 'Thông tin doanh nghiệp',
              },
              {
                key: 'information-represent',
                url: '/business/represent',
                name: 'Thông tin đại diện',
              },
              {
                key: 'other',
                url: '/business/other',
                name: 'Thông tin khác',
              },
            ],
          },
        ]
      : []),
    ...(['owner', 'individual'].includes(type) && !isCancel
      ? [
          {
            key: 'employee',
            url: '/employee/information',
            name: 'Thành viên',
            icon: UserOutlined,
            sub_menu: null,
          },
        ]
      : []),
    ...(!isCancel
      ? [
          {
            key: 'application',
            url: '/application',
            name: 'Ứng dụng',
            icon: SquaresPlusOutlined,
          },
        ]
      : []),
    {
      key: 'wallet',
      url: '/wallet',
      name: 'Ví Tera',
      icon: WalletOutlined,
    },
    ...(['owner'].includes(type) && !isCancel
      ? [
          {
            key: 'permission-group',
            url: '/permission-group',
            name: 'Nhóm quyền',
            icon: WalletOutlined,
          },
          {
            key: 'service-package',
            url: '/service-package',
            name: 'Gói dịch vụ',
            icon: AcademicCapOutlined,
          },
        ]
      : []),
    {
      key: 'invoice',
      url: '/invoice',
      name: 'Hóa đơn',
      icon: DocumentCheckOutlined,
    },
    {
      key: 'store',
      url: '/store',
      name: 'Cửa hàng',
      icon: BuildingStorefrontOutlined,
    },
    // {
    //   key: 'policy',
    //   url: '/policy',
    //   name: 'Chính sách quyền riêng tư',
    //   icon: DocumentCheckOutlined,
    // },
    // {
    //   key: 'introduce',
    //   url: '/introduce',
    //   name: 'Giới thiệu / Tích điểm',
    //   icon: SparklesOutlined,
    //   sub_menu: null,
    // },
    // {
    //   key: 'account-protection',
    //   url: '/account protection',
    //   name: 'Bảo vệ tài khoản',
    //   icon: ShieldCheckOutlined,
    //   sub_menu: null,
    // },
    // {
    //   key: 'license-payment',
    //   url: '/license-payment',
    //   name: 'Giấy phép và thanh toán',
    //   icon: CreditCardOutlined,
    // },
  ];

  return (
    <>
      {firstLogin && (
        <FirstLogin open={firstLogin} onClose={() => setFirstLogin(false)} />
      )}
      <div
        className="flex w-full h-screen"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: '100% 100%',
        }}
      >
        <div className="w-[303px] h-full p-3 flex flex-col gap-y-[38px] shadow-md bg-[#0095D9]">
          <div className="flex items-center justify-between">
            <img src={LogoTera} alt="logo-terasolutions" />
            <HomeSolid
              className="w-[30px] h-[30px] cursor-pointer"
              onClick={() => navigate('/dashboard')}
              color="#fff"
            />
          </div>
          <div className="flex flex-col gap-[5px] h-full overflow-auto">
            {MENU.map((menu) => (
              <div key={menu.key} className="flex flex-col">
                <Link to={menu.url}>
                  <div
                    className={`flex items-center justify-start gap-5 px-4 py-2.5 rounded-[5px]  ${
                      location.pathname.includes(menu.key) ? `bg-[#fff]` : ''
                    }`}
                  >
                    <menu.icon
                      className="w-[20px] h-[20px]"
                      color={
                        location.pathname.includes(menu.key)
                          ? `#0095D9`
                          : `#fff`
                      }
                    />
                    <p
                      className={`font-semibold text-base ${
                        location.pathname.includes(menu.key)
                          ? `text-[#0095D9] `
                          : `text-white`
                      }`}
                    >
                      {menu.name}
                    </p>
                  </div>
                </Link>
                {location.pathname.includes(menu.key) && menu.sub_menu && (
                  <div className="flex flex-col ml-[54px] relative">
                    <div className="absolute w-1 h-[calc(100%-20px)] left-0 top-5 bg-[#9CA3AF]" />
                    {menu.sub_menu.map((sm) => (
                      <div key={sm.key} className="mt-5 relative">
                        <Link to={`${sm.url}`}>
                          <p
                            className={`font-medium text-base pl-2.5 border-l-4 z-10 ${
                              location.pathname.startsWith(sm.url)
                                ? `border-[#0095D9] text-[#0095D9] border-l-white`
                                : `border-[#9CA3AF]`
                            }`}
                          >
                            <span className="text-white text-sm font-semibold">
                              {sm.name}
                            </span>
                          </p>
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="w-[calc(100vw-302px)] h-full flex flex-col gap-y-2.5">
          <div className="w-full h-[70px] px-10 py-2.5 flex items-center justify-end gap-3 shadow-md">
            <div className="flex gap-x-3 items-center rounded-full bg-gray-200 pr-[14px]">
              <IconCoin className="w8 h-8" color="#4B5563" />
              <span className="font-semibold">123</span>
            </div>
            {business?.id && type === 'owner' && !isCancel && (
              <Badge
                count={total}
                showZero={false}
                overflowCount={99}
                className="w-[15px] bg-red-400 text-white text-[8px] top-[6px] right-[2px]"
              >
                <span
                  className="cursor-pointer"
                  onClick={() => navigate(CART_URL)}
                >
                  <IconCart className="w8 h-8" color="#4B5563" />
                </span>
              </Badge>
            )}

            <IconHelp className="w8 h-8" color="#4B5563" />
            <Dropdown menu={{ items: itemsMenu }} trigger="click">
              <IconProfile className="w8 h-8 cursor-pointer" color="#0095D9" />
            </Dropdown>
          </div>
          <div className="h-full overflow-auto flex-1">
            <Outlet />
          </div>
        </div>
      </div>
      <ModalConfirm />
    </>
  );
}

export default observer(BasicLayout);
