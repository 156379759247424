export const PERMISSION_KEY = {
  STORE_VIEW_LIST: 'portal_store_view_store_list',
  STORE_CREATE: 'portal_store_create_store',
  STORE_UPDATE: 'portal_store_update_store',
  STORE_DETAIL: 'portal_store_view_store_detail',
  STORE_DELETE: 'portal_store_delete_store',
  STORE_SEARCH: 'portal_store_search_store',
  STORE_IMPORT_EXCEL: 'portal_store_import_excel',
  STORE_EXPORT_EXCEL: 'portal_store_export_excel',
};
