import { RefObject, useEffect, useState } from 'react';

export type IScrollType = 'none' | 'toLeft' | 'toRight' | 'toBottom' | 'toTop';

const startScroll = (
  ref: RefObject<HTMLDivElement>,
  type: IScrollType,
  speed: number,
) => {
  const typeObject = {
    toLeft: [-1 * speed, 0],
    toRight: [speed, 0],
    toBottom: [0, speed],
    toTop: [0, -1 * speed],
  };
  const value = typeObject[type];
  if (!value) return;

  return ref?.current?.scrollBy(value[0], value[1]);
};

interface IProps {
  targetRef: RefObject<HTMLDivElement>;
  speed?: number;
  dependencies?: Array<any>;
}
const useScroll = (value: IProps) => {
  const { targetRef, speed = 15, dependencies = [] } = value;
  const [canScroll, setCanScroll] = useState<boolean>(false);
  const [isScrollingLeft, setIsScrollingLeft] = useState<boolean>(false);
  const [isScrollingRight, setIsScrollingRight] = useState<boolean>(false);

  const handleSetScrollType = (type: IScrollType) =>
    startScroll(targetRef, type, speed);

  useEffect(() => {
    const scrollableElement = targetRef.current;
    if (scrollableElement) {
      const { scrollWidth, clientWidth } = scrollableElement;

      if (scrollWidth > clientWidth) {
        setCanScroll(true);
      } else {
        setCanScroll(false);
      }
    }
  }, [targetRef.current, ...dependencies]);

  useEffect(() => {
    const scrollableElement = targetRef.current;

    const handleScroll = () => {
      if (scrollableElement) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollableElement;
        const isLeft = scrollLeft > 0;
        const isRight = scrollLeft < scrollWidth - clientWidth;

        setIsScrollingLeft(isLeft);
        setIsScrollingRight(isRight);
      }
    };

    if (scrollableElement) {
      handleScroll();
      scrollableElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [targetRef.current, ...dependencies]);

  return {
    onScroll: handleSetScrollType,
    canScroll,
    isScrollingLeft,
    isScrollingRight,
  };
};

export default useScroll;
