import BankCard from './BankCard';
import SupportFunction from './SupportFunction';

function Bank() {
  return (
    <div>
      <BankCard />
      <SupportFunction />
    </div>
  );
}

export default Bank;
