import DefaultImage from '_common/component/DefaultImage';
import { useStores } from '_common/hooks';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { ReactComponent as IconSub } from 'styles/images/dashboard/sub.svg';
import { ReactComponent as IconClockReturn } from 'styles/images/Icons/clock-return.svg';
import { EllipsisVerticalSolid, Popover, Tooltip, UserSolid } from 'tera-dls';
import Content from './Popover';

interface HeaderRightBar {
  open: boolean;
  onOpen?: () => void;
}

function HeaderRightBar({ open, onOpen }: HeaderRightBar) {
  const {
    authStore: { user },
  } = useStores();
  return (
    <div
      className={classNames('p-[30px] shrink-0', {
        'flex flex-col h-full items-center': !open,
      })}
    >
      <div
        className={classNames('flex justify-between items-center gap-x-2.5', {
          'flex-col gap-5': !open,
        })}
      >
        <div className="flex items-center gap-x-5">
          <DefaultImage
            src={user?.avatar_url}
            alt={user?.full_name}
            className="rounded-full w-[60px] h-[60px]"
          />
          {open && (
            <p className="line-clamp-1 break-word text-[#878787] text-base font-semibold ">
              {user?.full_name}
            </p>
          )}
        </div>
        <Popover trigger="click" content={<Content />} placement="bottom-end">
          <div>
            <EllipsisVerticalSolid className="w-6 h-6 text-[#878787] cursor-pointer" />
          </div>
        </Popover>
      </div>
      <div
        className={classNames('flex justify-around items-center mt-[30px]', {
          'flex-col gap-y-10 mt-[80px]': !open,
        })}
      >
        <div className="text-center">
          <span className="font-bold text-3xl text-gray-700">0</span>
          <div
            className={classNames('flex gap-x-[5px] items-center', {
              'mt-2.5': !open,
            })}
          >
            <p className="text-[#878787] text-sm flex gap-x-1">
              <UserSolid className="w-4 h-5 text-gray-500" />{' '}
              {open && <span>Thành viên</span>}
            </p>
          </div>
        </div>
        <div className="text-center">
          <span className="font-bold text-3xl text-gray-700">0</span>
          <div
            className={classNames('flex gap-x-[5px] items-center', {
              'mt-2.5': !open,
            })}
          >
            <p className="text-[#878787] text-sm flex gap-x-1">
              <IconSub />
              {open && <span>Người theo dõi</span>}
            </p>
          </div>
        </div>
      </div>
      <Tooltip title="Lịch sử hoạt động" placement="left" showArrow={false}>
        <div
          className={classNames('cursor-pointer', {
            hidden: open,
            'mt-auto': !open,
          })}
          onClick={onOpen}
        >
          <IconClockReturn />
        </div>
      </Tooltip>
    </div>
  );
}

export default observer(HeaderRightBar);
