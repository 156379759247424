import { useQuery } from '@tanstack/react-query';
import DefaultImage from '_common/component/DefaultImage';
import HoverQuickView from '_common/component/HoverQuickView';
import TableTera from '_common/dof/TableTera';
import { MemberApi } from 'pages/EmployeeInformation/api';
import {
  statusTypeColor,
  USER_STATUS,
} from 'pages/EmployeeInformation/constants';
import EmployeeFilter from 'pages/EmployeeInformation/containers/Filter';
import Searching from 'pages/EmployeeInformation/containers/Searching';
import { useEffect, useState } from 'react';
import { Button, FunnelOutlined, Modal, PaginationProps, Tag } from 'tera-dls';

interface MemberListProps {
  id: string | number;
  open: boolean;
  onCancel: () => void;
}

function MemberList({ id, open, onCancel }: MemberListProps) {
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [params, setParams] = useState(null);

  const handleSearch = ({ keyword }) => {
    setParams({ ...params, keyword, page: 1 });
  };

  const handleFilter = (values) => {
    setParams({ ...params, ...values, page: 1 });
  };

  const columns: any = [
    {
      title: 'Họ và tên',
      dataIndex: 'full_name',
      width: 200,
      render: (full_name, record) => (
        <HoverQuickView
          avatarUrl={record?.avatar_url}
          email={record?.email}
          name={full_name}
          phone={record?.phone}
          className="line-clamp-2"
        >
          <div className="flex gap-3 items-center">
            <DefaultImage
              src={record?.avatar_url}
              alt={record?.avatar_url}
              className="w-[40px] h-[40px] rounded-full border"
            />
            <span>{full_name}</span>
          </div>
        </HoverQuickView>
      ),
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      width: 150,
      render: (text) => <p className="line-clamp-2">{text}</p>,
    },
    {
      title: 'Phân quyền',
      dataIndex: 'role',
      render: (val) => val?.title,
      width: 150,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text) => <p className="line-clamp-2">{text}</p>,
      width: 150,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      width: 150,
    },
    {
      title: 'Chức danh',
      dataIndex: 'job_title_text',
      render: (job_title) => <p className="line-clamp-2">{job_title?.title}</p>,
      width: 150,
    },
    {
      title: 'Phòng ban/ Bộ phận',
      dataIndex: 'department_text',
      width: 150,
      render: (department) => (
        <p className="line-clamp-2">{department?.title}</p>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'is_active',
      width: 150,
      render: (value) =>
        USER_STATUS[value] && (
          <Tag color={statusTypeColor[value]}>{USER_STATUS[value]}</Tag>
        ),
    },
  ];
  const {
    data: list,
    refetch,
    isLoading,
  } = useQuery(
    ['get-member-list', id, params],
    () =>
      MemberApi.getList({
        limit: 10,
        page: 1,
        ...params,
        role_id: id,
      }),
    {
      enabled: !id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    id && refetch();
  }, [id]);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    setParams((prev) => ({ ...prev, page: page, limit: pageSize }));
  };

  return (
    <Modal
      title="Danh sách thành viên"
      open={open}
      width="80%"
      okText="Đóng"
      onCancel={onCancel}
      onOk={onCancel}
      cancelButtonProps={{ className: 'hidden' }}
      destroyOnClose
      bodyClassName="min-h-[80vh] overflow-auto"
    >
      <div className="flex flex-col gap-y-2.5">
        <div className="flex gap-x-2.5 items-center">
          <Searching onSearch={handleSearch} />
          <Button
            type="alternative"
            className="rounded-xsm"
            icon={<FunnelOutlined className="w-5 h-5 text-gray-400 shrink-0" />}
            onClick={() => setOpenFilterModal(true)}
          />
        </div>
        <TableTera
          loading={isLoading}
          rowKey={'id'}
          columns={columns}
          data={list?.data ?? []}
          pagination={{
            onChange: handleChangePage,
            total: list?.total,
            current: list?.current_page,
            pageSize: list?.per_page,
            to: list?.to,
            from: list?.from,
          }}
        />
      </div>
      {openFilterModal && (
        <EmployeeFilter
          open={openFilterModal}
          onClose={() => setOpenFilterModal(false)}
          onFilter={handleFilter}
          initialValue={params}
        />
      )}
    </Modal>
  );
}

export default MemberList;
