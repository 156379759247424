import { useQuery } from '@tanstack/react-query';
import ServiceModal from '_common/component/ServiceModal';
import ServicePackageApi from 'pages/ServicePackage/_api';
import useScroll from 'pages/ServicePackage/_hooks/useScroll';
import { useEffect, useRef, useState } from 'react';
import { customTwMerge } from 'tailwind-merge.config';
import {
  Button,
  ChevronLeftOutlined,
  ChevronRightOutlined,
  Spin,
} from 'tera-dls';
import Card from '../Registration/Card';

interface IProps {
  open: boolean;
  onClose?: () => void;
  packageId: number;
  serviceId: number;
  onSubmit: (currentId: number, upgradeId: number) => void;
}
const Upgrade = (props: IProps) => {
  const { onClose, open, packageId, serviceId, onSubmit } = props;
  const ref = useRef(null);

  const [selectedPackage, setSelectedPackage] = useState<any>();

  const {
    data: response,
    refetch,
    isLoading: loadingData,
  } = useQuery(
    ['get-service-package-upgrade', packageId, serviceId],
    () =>
      ServicePackageApi.getRegistrationDetail({
        params: {
          service_id: serviceId,
          min_id: packageId,
        },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { onScroll, canScroll, isScrollingLeft, isScrollingRight } = useScroll({
    targetRef: ref,
    speed: 270,
    dependencies: [response],
  });

  useEffect(() => {
    packageId && serviceId && refetch();
  }, [packageId, serviceId]);

  return (
    <ServiceModal
      destroyOnClose
      closeIcon={false}
      onCancel={onClose}
      width={1000}
      maskClosable={false}
      open={open}
      centered={true}
    >
      <Spin spinning={loadingData}>
        <div className="w-[865px] flex flex-col gap-[16px]">
          <div className="flex flex-col justify-center">
            <h3 className="font-light text-base text-center tracking-[4px] uppercase">
              Nâng cấp gói dịch vụ
            </h3>
            <h2 className="text-[30px] leading-[35px] font-bold text-center mt-[20px] tracking-[6px] uppercase">
              {response?.name}
            </h2>
            <div className="flex justify-center">
              <p className="font-light text-center mt-[16px] w-[65%]">
                {response?.description}
              </p>
            </div>
          </div>
          <div
            className={customTwMerge('flex', !canScroll && 'justify-center')}
          >
            <div
              className={customTwMerge(
                'flex items-center px-2',
                (!canScroll || !isScrollingLeft) && 'invisible',
              )}
            >
              <ChevronLeftOutlined
                className="w-8 cursor-pointer"
                onClick={() => {
                  onScroll('toLeft');
                }}
                onMouseUp={() => onScroll('none')}
              />
            </div>
            <div
              className={customTwMerge(
                'flex gap-[25px] rounded-[8px] overflow-x-auto snap-mandatory scroll-smooth justify-start pb-2.5',
              )}
              ref={ref}
            >
              {response?.packages?.map((item) => (
                <Card
                  value={item}
                  usedModules={response?.modules}
                  modules={response?.modules_no_using}
                  onSelect={setSelectedPackage}
                  selected={selectedPackage === item?.id}
                />
              ))}
            </div>
            <div
              className={customTwMerge(
                'flex items-center px-2',
                (!canScroll || !isScrollingRight) && 'invisible',
              )}
            >
              <ChevronRightOutlined
                className="w-8 cursor-pointer"
                onClick={() => onScroll('toRight')}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-5">
          <div className="grid grid-cols-2 w-[500px] gap-[16px] justify-center">
            <Button
              onClick={onClose}
              type={'light'}
              className="rounded-[54px] col-span-1 text-base leading-[18px] text-blue-500"
            >
              Hủy
            </Button>
            <Button
              className="rounded-[54px] text-base col-span-1 leading-[18px]"
              disabled={!selectedPackage}
              onClick={() => {
                onSubmit && onSubmit(packageId, selectedPackage);
                onClose();
              }}
            >
              Thanh toán
            </Button>
          </div>
        </div>
      </Spin>
    </ServiceModal>
  );
};

export default Upgrade;
