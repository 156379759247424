import { useQuery } from '@tanstack/react-query';
import { useStores } from '_common/hooks';
import { CryptoJSAesEncrypt } from '_common/utils/hashHelper';
import classNames from 'classnames';
import {
  containerClassName,
  titleClassName,
  viewMoreClassName,
} from 'pages/Dashboard/constants';
import PermissionGroupApi from 'pages/PermissionGroup/_api';
import React from 'react';
import { HomeOutlined, updateQueryParams } from 'tera-dls';
import { ReactComponent as IconCare } from 'styles/images/dashboard/icon-care.svg';
import { ReactComponent as IconFin } from 'styles/images/dashboard/icon-finance.svg';
import { ReactComponent as IconHr } from 'styles/images/dashboard/icon-hr.svg';
import { ReactComponent as IconLog } from 'styles/images/dashboard/icon-logistic.svg';
import { ReactComponent as IconMkt } from 'styles/images/dashboard/icon-marketing.svg';
import { ReactComponent as IconOperate } from 'styles/images/dashboard/icon-operate.svg';
import { ReactComponent as IconOperation } from 'styles/images/dashboard/icon-operation.svg';
import { ReactComponent as IconPurchase } from 'styles/images/dashboard/icon-purchase.svg';
import { ReactComponent as IconSale } from 'styles/images/dashboard/icon-sale.svg';
import { ReactComponent as IconWork } from 'styles/images/dashboard/icon-work.svg';
import { ReactComponent as IconWh } from 'styles/images/dashboard/icon-warehouse.svg';
import { ReactComponent as ApplicationEmpty } from 'styles/images/dashboard/application-empty.svg';
import { useNavigate } from 'react-router-dom';
import { APPLICATION_URL } from 'pages/Application/constants/url';

const iconModule = {
  hr: <IconHr />,
  log: <IconLog />,
  cs: <IconCare />,
  mkt: <IconMkt />,
  purchase: <IconPurchase />,
  sale: <IconSale />,
  fin: <IconFin />,
  wh: <IconWh />,
  work: <IconWork />,
  operation: <IconOperation />,
  operate: <IconOperate />,
};

function ListModule() {
  const { authStore } = useStores();
  const navigate = useNavigate();

  const { data: listModule } = useQuery(
    ['get-list-module'],
    () => PermissionGroupApi.getModules(),
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleRedirectLink = (link) => {
    if (!link) return;
    const bodyParams = {
      access_id: authStore?.access_id,
    };
    const queryParams = updateQueryParams({
      client_id: 'tera',
      req: JSON.stringify(CryptoJSAesEncrypt(bodyParams)),
    });
    window.open(`${link}${queryParams}`, '_self');
  };

  return (
    <div className={classNames('bg-gray-100', containerClassName)}>
      <div className="flex justify-between items-center">
        <h2 className={titleClassName}>Ứng dụng của tôi</h2>
        <span
          className={viewMoreClassName}
          onClick={() => navigate(APPLICATION_URL.list.path)}
        >
          Xem thêm
        </span>
      </div>
      {listModule?.length > 0 ? (
        <div className="pt-5 flex gap-x-[26px] overflow-auto">
          {listModule?.map((module) => (
            <div className="flex flex-col gap-y-1 items-center">
              <div
                className={classNames(
                  'bg-blue-500 relative overflow-hidden h-[70px] w-[70px] rounded-[5px] flex justify-center items-center shrink-0 module-item cursor-pointer',
                  `${module?.color || 'bg-blue-500'}`,
                )}
                onClick={() => handleRedirectLink(module?.url)}
              >
                {iconModule[module?.code] || (
                  <HomeOutlined className="w-12 h-12 text-white" />
                )}
              </div>
              <span className="text-[#374151]">{module?.title}</span>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col gap-y-2.5 justify-center items-center">
          <ApplicationEmpty />
          <p className="text-gray-500 font-light italic">
            Bạn chưa có ứng dụng
          </p>
        </div>
      )}
    </div>
  );
}

export default ListModule;
