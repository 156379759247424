import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { messageError } from '_common/constants/message';
import FormTera from '_common/dof/FormTera';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, notification } from 'tera-dls';
import { BusinessApi } from './api';
import BusinessForm from './containers/BusinessForm';
import { UserApi } from 'pages/UserInformation/api';
import { useStores } from '_common/hooks';
import NoPermission from '_common/component/NoPermission';
import { observer } from 'mobx-react-lite';

export const noActiveStatus = ['waiting_for_activation', 'no_activated'];
const BusinessInformation = ({ value }) => {
  const form = useForm({ mode: 'onChange' });
  const { handleSubmit } = form;
  const queryClient = useQueryClient();
  const [registrationTemp, setRegistrationTemp] = useState<boolean>(false);

  const ref = useRef(null);
  const {
    authStore: { user },
  } = useStores();
  const { business: detail } = user;

  useEffect(() => {
    !detail ? setRegistrationTemp(false) : setRegistrationTemp(true);
  }, [detail]);

  const { data: userDetail } = useQuery(
    ['get-user-profile'],
    () => UserApi.getProfile(),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !detail,
    },
  );

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    (variables: any) => BusinessApi.update(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get_profile']);
          queryClient.invalidateQueries(['get-user-profile']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        const errorMessage = error?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
    (variables: any) => BusinessApi.create(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          setRegistrationTemp(true);
          queryClient.invalidateQueries(['get_profile']);
          queryClient.invalidateQueries(['get-user-profile']);
          queryClient.invalidateQueries(['get_business_detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        const errorMessage = error?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  const handleSubmitForm = (values) => {
    const data = _.pick(values, [
      'name',
      'name_registration',
      'email',
      'employee_size',
      'payment_methods',
      'address',
      'intro',
      'owner_phone',
      'owner_name',
      'owner_job_title',
      'owner_department',
      'website',
      'facebook',
      'instagram',
      'tiktok',
      'linkedin',
    ]);
    if (!detail) {
      mutateCreate(data);
      return;
    }
    mutateUpdate({
      ...data,
    });
  };

  useEffect(() => {
    if (!ref?.current) return;
    const element = ref?.current?.querySelector(value);
    element && element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [ref, value]);

  useEffect(() => {
    if (!detail) {
      return;
    }
    const value = {
      name: detail.name,
      name_registration: detail.name_registration,
      email: detail.email,
      employee_size: detail.employee_size,
      payment_methods: detail.payment_methods,
      address: detail.address,
      intro: detail.intro,
      owner_name: detail.owner_name,
      owner_email: detail.owner_email,
      owner_phone: detail.owner_phone,
      owner_job_title: detail.owner_job_title,
      owner_department: detail.owner_department,
      website: detail.website,
      facebook: detail.facebook,
      instagram: detail.instagram,
      tiktok: detail.tiktok,
      linkedin: detail.linkedin,
      register_time: detail.register_time ? moment(detail.register_time) : '',
      expiration_time: detail.expiration_time
        ? moment(detail.expiration_time)
        : '',
      trial_time: detail.trial_time,
    };
    Object.entries(value).forEach(([name, value]) =>
      form.setValue(name, value),
    );
  }, [detail]);

  useEffect(() => {
    if (!detail && userDetail) {
      const value = {
        owner_name: userDetail.full_name,
        owner_email: userDetail.email,
        owner_phone: userDetail.phone,
        owner_job_title: userDetail.job_title,
        owner_department: userDetail.department,
      };

      Object.entries(value).forEach(([name, value]) =>
        form.setValue(name, value),
      );
    }
  }, [userDetail, detail]);

  const isCancel = detail?.status === 'cancelled';
  if (isCancel) return <NoPermission />;
  return (
    <div className="px-[100px] py-[50px]" ref={ref}>
      <div
        id="e1"
        className="flex flex-col justify-center items-center gap-2.5"
      >
        <div className="flex flex-col gap-2.5 justify-center items-center">
          <div className="text-[#0095D9] text-[28px] leading-[28px] font-bold">
            Thông tin doanh nghiệp
          </div>
          <div className="text-xl font-normal">
            Quản lý thông tin doanh nghiệp
          </div>
        </div>
        <div className="flex justify-between w-full items-center">
          {noActiveStatus.includes(detail?.status) ? (
            <div className="text-red-400 italic font-normal">
              Thông tin doanh nghiệp đang chờ xác nhận
            </div>
          ) : (
            <div />
          )}
          <Button
            onClick={handleSubmit(handleSubmitForm)}
            loading={isLoadingUpdate || isLoadingCreate}
            className="bg-green-500 hover:bg-green-700 rounded-xsm px-[12px] py-[8px] text-sm font-normal"
          >
            {registrationTemp || detail
              ? 'Lưu'
              : 'Đăng ký tài khoản doanh nghiệp'}
          </Button>
        </div>
        <FormTera
          form={form}
          onSubmit={handleSubmit(handleSubmitForm)}
          className="w-full bg-white"
        >
          <BusinessForm />
        </FormTera>
      </div>
    </div>
  );
};

export default observer(BusinessInformation);
