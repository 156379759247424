import { DATE_FORMAT } from '_common/constants/common';
import moment from 'moment';
import {
  STATUS_SERVICE_BUSINESS,
  STATUS_SERVICE_BUSINESS_COLOR,
} from 'pages/ServicePackage/constants';
import { useState } from 'react';
import CardBackground from 'styles/images/servicePackage/card-background.png';
import { customTwMerge } from 'tailwind-merge.config';
import { Button } from 'tera-dls';
import Renew from '../Renew';
import Upgrade from '../Upgrade';
import PaymentQuickly from '../Upgrade/PaymentQuickly';
import UsedPackageDetail from './Detail';

const Icon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={13}
      fill="none"
      {...props}
    >
      <path
        fill="#fff"
        d="M.015 2.25A2.989 2.989 0 0 1 2 1.5h9c.76 0 1.456.283 1.985.75A2 2 0 0 0 11 .5H2A2 2 0 0 0 .015 2.25ZM.015 4.25A2.989 2.989 0 0 1 2 3.5h9c.76 0 1.456.283 1.985.75A2 2 0 0 0 11 2.5H2A2 2 0 0 0 .015 4.25ZM2 4.5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H8.5A.5.5 0 0 0 8 5a1.5 1.5 0 1 1-3 0 .5.5 0 0 0-.5-.5H2Z"
      />
    </svg>
  );
};
const IconCalendarWhite = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      {...props}
    >
      <path
        fill="#E1EFFE"
        fillRule="evenodd"
        d="M3 7.5a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H3Zm0 1v1h1v-1H3Zm3 0a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-1Zm1 0h1v1H7v-1Zm4-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1Zm0 1v1h1v-1h-1Zm-9 4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1Zm2 0v1H3v-1h1Zm3-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H7Zm1 1H7v1h1v-1Z"
        clipRule="evenodd"
      />
      <path
        fill="#E1EFFE"
        fillRule="evenodd"
        d="M3 .5a.5.5 0 0 1 1 0V3a.5.5 0 1 0 1 0V1.5h5v-1a.5.5 0 0 1 1 0V3a.5.5 0 0 0 1 0V1.5h1.5A1.5 1.5 0 0 1 15 3v8.035a3.5 3.5 0 1 1-3.373 5.465H1.5A1.5 1.5 0 0 1 0 15V3a1.5 1.5 0 0 1 1.5-1.5H3v-1Zm8 14a3.5 3.5 0 0 1 3-3.465V6H1v9a.5.5 0 0 0 .5.5h9.645a3.502 3.502 0 0 1-.145-1Zm6 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
        clipRule="evenodd"
      />
      <path
        stroke="#E1EFFE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.3}
        d="m13.5 14.25.8.8 1.2-1.8"
      />
    </svg>
  );
};

const IconCalendarGreen = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      {...props}
    >
      <path
        fill="#84E1BC"
        fillRule="evenodd"
        d="M3 7.5a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H3Zm0 1v1h1v-1H3Zm3 0a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-1Zm1 0h1v1H7v-1Zm4-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1Zm0 1v1h1v-1h-1Zm-9 4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1Zm2 0v1H3v-1h1Zm3-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H7Zm1 1H7v1h1v-1Z"
        clipRule="evenodd"
      />
      <path
        fill="#84E1BC"
        d="M15 13.25a.5.5 0 0 0-1 0v1.457l.646.646a.5.5 0 0 0 .707-.707L15 14.293V13.25Z"
      />
      <path
        fill="#84E1BC"
        fillRule="evenodd"
        d="M3 .5a.5.5 0 0 1 1 0V3a.5.5 0 1 0 1 0V1.5h5v-1a.5.5 0 0 1 1 0V3a.5.5 0 0 0 1 0V1.5h1.5A1.5 1.5 0 0 1 15 3v8.035a3.5 3.5 0 1 1-3.373 5.465H1.5A1.5 1.5 0 0 1 0 15V3a1.5 1.5 0 0 1 1.5-1.5H3v-1Zm8 14a3.5 3.5 0 0 1 3-3.465V6H1v9a.5.5 0 0 0 .5.5h9.645a3.502 3.502 0 0 1-.145-1Zm6 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const IconCalendarRed = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      {...props}
    >
      <path
        fill="#F8B4B4"
        fillRule="evenodd"
        d="M3 7.5a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H3Zm0 1v1h1v-1H3Zm3 0a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-1Zm1 0h1v1H7v-1Zm4-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1Zm0 1v1h1v-1h-1Zm-9 4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1Zm2 0v1H3v-1h1Zm3-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H7Zm1 1H7v1h1v-1Z"
        clipRule="evenodd"
      />
      <path
        fill="#F8B4B4"
        fillRule="evenodd"
        d="M3 .5a.5.5 0 0 1 1 0V3a.5.5 0 1 0 1 0V1.5h5v-1a.5.5 0 0 1 1 0V3a.5.5 0 0 0 1 0V1.5h1.5A1.5 1.5 0 0 1 15 3v8.035a3.5 3.5 0 1 1-3.373 5.465H1.5A1.5 1.5 0 0 1 0 15V3a1.5 1.5 0 0 1 1.5-1.5H3v-1Zm8 14a3.5 3.5 0 0 1 3-3.465V6H1v9a.5.5 0 0 0 .5.5h9.645a3.502 3.502 0 0 1-.145-1Zm6 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
        clipRule="evenodd"
      />
      <path
        stroke="#F8B4B4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.3}
        d="m13.5 15.5 2-2m-2 0 2 2"
      />
    </svg>
  );
};

const UsedPackageItem = ({ value }) => {
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [openUpgradeModal, setOpenUpgradeModal] = useState<boolean>(false);
  const [openRenewModal, setOpenRenewModal] = useState<boolean>(false);
  const [openPayQuicklyModal, setOpenPayQuicklyModal] = useState<any>({
    open: false,
  });

  const isActive = value?.status === 'is_active';
  const isRenew =
    Boolean(value?.package?.extension_allowed) &&
    Boolean(value?.package?.status);
  const isUpgrade = Boolean(value?.is_upgrade);
  const isReActive = ['expired', 'finished'].includes(value?.status);
  const isTrial = value?.service?.type === 'trial';
  return (
    <>
      <div
        className="rounded-[16px] p-[16px] border-[1px] overflow-hidden border-[#0095D9] lg:col-span-1 md:col-span-2 sm:col-span-4"
        style={{
          background: `url(${CardBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'cover',
          backgroundSize: '101% 100%',
        }}
      >
        <main className="flex gap-2">
          <div className="flex gap-[5px] flex-1 flex-col">
            <div className="flex items-center gap-[5px]">
              {value?.service?.file_url ? (
                <img
                  className="size-[32px] rounded-[6px]"
                  src={value?.service?.file_url}
                />
              ) : (
                <div className="rounded-full size-[32px] bg-[#FFFFFF40] flex">
                  <Icon className="w-3 m-auto" />
                </div>
              )}

              <div className="flex flex-col gap-[5px]">
                <h4 className="font-light leading-[15px] text-white break-word">
                  {value?.service?.name}
                </h4>
                <h3 className="text-base leading-[18px] text-white break-word">
                  {value?.package?.name}
                </h3>
              </div>
            </div>
            <div className="flex flex-col gap-2.5">
              <h3
                className={customTwMerge(
                  'text-green-300 tracking-[3px] leading-[15px]',
                  STATUS_SERVICE_BUSINESS_COLOR[value?.status],
                )}
              >
                {STATUS_SERVICE_BUSINESS[value?.status] ?? ''}
              </h3>
              <div className="flex flex-col gap-[5px]">
                <div className="flex gap-[6px] text-white">
                  <IconCalendarWhite className="size-5 text-white" />
                  <span> {moment(value?.date_active).format(DATE_FORMAT)}</span>
                </div>
                <div className="flex gap-[6px] text-white">
                  <IconCalendarGreen className="size-5 text-white" />
                  <span>{value?.time} tháng</span>
                </div>
                <div className="flex gap-[6px] text-white">
                  <IconCalendarRed className="size-5 text-white" />
                  <span>{moment(value?.date_expired).format(DATE_FORMAT)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[13px] mt-[25px] items-start w-[110px]">
            <Button
              onClick={() => setOpenDetail(true)}
              className="bg-[#FFFFFF40] rounded-[10px] w-full hover:bg-[#FFFFFF40] py-[3px] font-normal px-[15px] text-[13px] leading-[15px]"
            >
              Xem chi tiết
            </Button>
            {isReActive && !isTrial && (
              <Button
                onClick={() =>
                  setOpenPayQuicklyModal({
                    open: true,
                    currentId: value?.package?.id,
                    mode: 'restart',
                  })
                }
                className="bg-[#FFFFFF40] shrink-0 rounded-[10px] w-full hover:bg-[#FFFFFF40] py-[3px] font-normal px-[15px] text-[13px] leading-[15px]"
              >
                Kích hoạt lại
              </Button>
            )}
            {isActive && !isTrial ? (
              <>
                {isRenew && (
                  <Button
                    onClick={() => setOpenRenewModal(true)}
                    className="bg-[#FFFFFF40] rounded-[10px] w-full hover:bg-[#FFFFFF40] py-[3px] font-normal px-[15px] text-[13px] leading-[15px]"
                  >
                    Gia hạn
                  </Button>
                )}
                {isUpgrade && (
                  <Button
                    onClick={() => setOpenUpgradeModal(true)}
                    className="bg-[#FFFFFF40] rounded-[10px] w-full hover:bg-[#FFFFFF40] py-[3px] font-normal px-[15px] text-[13px] leading-[15px]"
                  >
                    Nâng cấp
                  </Button>
                )}
              </>
            ) : (
              <>
                <div className="h-[21px]" />
                <div className="h-[21px]" />
              </>
            )}
          </div>
        </main>
      </div>
      {openDetail && (
        <UsedPackageDetail
          open={openDetail}
          onClose={() => setOpenDetail(false)}
          value={value?.id}
        />
      )}
      {openUpgradeModal && (
        <Upgrade
          serviceId={value?.service?.id}
          packageId={value?.package?.id}
          open={openUpgradeModal}
          onClose={() => setOpenUpgradeModal(false)}
          onSubmit={(currentId, upgradeId) =>
            setOpenPayQuicklyModal({
              open: true,
              currentId,
              upgradeId,
              mode: 'upgrade',
            })
          }
        />
      )}
      {openPayQuicklyModal?.open && (
        <PaymentQuickly
          currentId={openPayQuicklyModal?.currentId}
          upgradeId={openPayQuicklyModal?.upgradeId}
          open={openPayQuicklyModal?.open}
          onClose={() => setOpenPayQuicklyModal({ open: false })}
          mode={openPayQuicklyModal?.mode}
        />
      )}

      {openRenewModal && (
        <Renew
          serviceId={value?.service?.id}
          packageId={value?.package?.id}
          open={openRenewModal}
          onClose={() => setOpenRenewModal(false)}
          onSubmit={(currentId) =>
            setOpenPayQuicklyModal({ open: true, currentId, mode: 'extend' })
          }
        />
      )}
    </>
  );
};

export default UsedPackageItem;
