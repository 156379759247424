import React from 'react';
import Help from './Help';
import logo from 'styles/images/uiNew/logo.png';
import Nav from './Nav';
function Menu() {
  return (
    <nav className="flex flex-col justify-between h-full w-[117px] p-[30px] ">
      <div className="flex flex-col gap-y-[30px]">
        <img src={logo} className="w-full" />
        <Nav />
      </div>
      <Help />
    </nav>
  );
}

export default Menu;
