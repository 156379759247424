import { Drawer, DrawerProps } from 'tera-dls';
import logo from 'styles/images/uiNew/logo.png';
import { menus } from './Nav';
import classNames from 'classnames';
import { menusHelp } from './Help';
import { useNavigate } from 'react-router-dom';

function DrawerMenu({ open, onClose }: DrawerProps) {
  const navigate = useNavigate();
  return (
    <Drawer
      open={open}
      onClose={onClose}
      containerClassName="bg-[#F3F3F9] p-4 flex flex-col justify-between"
      placement="left"
    >
      <div>
        <img src={logo} className="w-12" />
        <div className="mt-[30px] flex flex-col gap-y-5">
          {menus.map((menu) => (
            <div
              className={classNames(
                'px-4 py-3 rounded-[10px] flex items-center gap-x-2.5 hover:text-blue-600',
                menu.classNames,
              )}
              onClick={() => navigate(menu.url)}
            >
              {menu.icon}
              <span>{menu.title}</span>
            </div>
          ))}
        </div>
      </div>
      <div>
        {menusHelp.map((menu) => (
          <div
            className={classNames(
              'px-4 py-3 rounded-[10px] flex items-center gap-x-2.5 hover:text-blue-600',
            )}
            onClick={() => navigate(menu.url)}
          >
            {menu.icon}
            <span>{menu.title}</span>
          </div>
        ))}
      </div>
    </Drawer>
  );
}

export default DrawerMenu;
