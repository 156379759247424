import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

const useTriggerQuickPage = (callback: (nextPathname) => void) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    const onChange = () => {
      callback && callback(window.location.href);
    };
    window.addEventListener('popstate', onChange);
  }, [callback]);

  useEffect(() => {
    const push = navigator.push;
    navigator.push = (...args: Parameters<typeof push>) => {
      const nextPathname = (args?.[0] as any)?.pathname;
      callback && callback(nextPathname);
      push(...args);
    };
    return () => {
      navigator.push = push;
    };
  }, [navigator, callback]);
  return {};
};

export default useTriggerQuickPage;
