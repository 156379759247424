import { adminEndpoint } from '_common/constants/common';
import api from 'states/drivers';

export const AdminApi = {
  enableBusiness: async (params: any) =>
    await api
      .post(`${adminEndpoint}/administrator/business/enable`, params)
      .then((result) => result.data),
  verifyToken: async (params: any) =>
    await api
      .post(`${adminEndpoint}/administrator/business/verify-token`, params)
      .then((result) => result.data),
};
