import { IRouteProps } from '_common/interface/router';
import InvoiceDetail from './containers/Detail';
import Invoice from '.';

export const InvoiceRouter: IRouteProps[] = [
  {
    key: '1',
    path: '',
    component: <Invoice />,
  },
  {
    key: '2',
    path: 'detail/:id',
    component: <InvoiceDetail />,
  },
];
