import React from 'react';
import { Button, Drawer } from 'tera-dls';

interface FilterProps {
  open: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  onFilter?: () => void;
  onReset?: () => void;
  children?: React.ReactNode;
  title?: string;
}

function Filter({
  open,
  onClose,
  onCancel,
  onFilter,
  onReset,
  children,
  title = 'Lọc',
}: FilterProps) {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      containerClassName="max-h-screen overflow-hidden z-[70]"
    >
      <div className="flex flex-col gap-y-2.5 h-full">
        <p className="text-xl font-medium pb-2.5 border-b border-gray-300 ">
          {title}
        </p>
        <div className="flex-1 overflow-auto">
          {children}
          {onReset && (
            <a
              className="text-sm font-normal text-red-500 cursor-pointer"
              onClick={onReset}
            >
              Hủy bộ lọc
            </a>
          )}
        </div>
        <div className="flex justify-end gap-x-2 pt-2.5 border-t border-gray-300 ">
          <Button type="alternative" onClick={onCancel}>
            Hủy
          </Button>
          <Button onClick={onFilter}>Lọc</Button>
        </div>
      </div>
    </Drawer>
  );
}

export default Filter;
