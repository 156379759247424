import Filter from '_common/component/Filter';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import {
  TransactionMethod,
  TransactionStatus,
  TransactionType,
} from 'pages/Wallet/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface HistoryFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function HistoryFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: HistoryFilterProps) {
  const form = useForm();

  const handleSubmitForm = (values) => {
    onFilter(values);
    onClose();
  };

  const handleReset = () => {
    const values = {
      status: null,
      transaction_type: null,
      methods: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = _.pick(initialValue, [
      'status',
      'transaction_type',
      'methods',
    ]);

    form.reset(values);
  }, [initialValue]);

  const optionsStatus = Object.entries(TransactionStatus).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );
  const optionsMethod = Object.entries(TransactionMethod).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );
  const optionsType = Object.entries(TransactionType).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Trạng thái" name="status">
          <Select options={optionsStatus} />
        </FormTeraItem>
        <FormTeraItem label="Loại giao dịch" name="transaction_type">
          <Select options={optionsType} />
        </FormTeraItem>
        <FormTeraItem label="Phương thức thanh toán" name="methods">
          <Select options={optionsMethod} />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default HistoryFilter;
