import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { forwardRef, useImperativeHandle } from 'react';
import { formatCurrency, InputNumber } from 'tera-dls';
import TagInfo from './TagInfo';
import { useMutation, useQuery } from '@tanstack/react-query';
import WalletApi from 'pages/Wallet/_api';
import { MAX_AMOUNT, MIN_AMOUNT } from '.';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';

function Amount(prop, ref) {
  const {
    transactionStore: {
      type,
      amount,
      account,
      setAmount,
      nextStep,
      setSessionId,
      setStep,
    },
  } = useStores();

  const balance = useQuery(['get-balance'], () => WalletApi.getAmount(), {
    cacheTime: 300000,
    staleTime: 300000,
  });

  const { mutate: mutateSendMail } = useMutation(() => WalletApi.sendMail(), {
    onSuccess: (res) => {
      if (res?.code === 200) {
        setSessionId(res?.data?.session_id);
      }
    },
    onError(error: any) {
      ErrorToast({ errorProp: error?.data });
    },
  });
  const lengthAmount = String(amount)?.length;

  useImperativeHandle(ref, () => {
    return {
      nextStep: () => {
        if (type === 'withdrawal') {
          nextStep();
          mutateSendMail();
          return;
        }
        setStep(3);
      },
    };
  });

  return (
    <div className="flex flex-col gap-y-4">
      <p>Đến</p>
      <TagInfo
        cardName={account?.cardName}
        name={account?.cardholder}
        number={account?.number}
      />
      <p>Bạn muốn chuyển bao nhiêu?</p>

      <div className="h-[300px] flex items-center justify-center flex-col">
        <div className="flex items-center gap-x-2.5">
          <InputNumber
            min={0}
            max={
              type === 'withdrawal'
                ? balance.data?.availability_amount
                : undefined
            }
            value={amount}
            className="border-none focus:ring-0 p-0 text-5xl"
            style={{
              width: amount && lengthAmount > 0 ? 35 * lengthAmount : 35,
            }}
            allowStepHandler={false}
            onChange={(value: number) => setAmount(value)}
            formatter={(value) =>
              String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            placeholder=""
          />
          <span>đ</span>
        </div>
        {type === 'withdrawal' && (
          <p className="mt-2.5">
            Còn lại: {formatCurrency(balance.data?.availability_amount)}
          </p>
        )}
        {amount < MIN_AMOUNT && (
          <p className="mt-2.5 text-red-500">
            Tối thiếu: {formatCurrency(MIN_AMOUNT)}
          </p>
        )}
        {type === 'withdrawal' && amount > MAX_AMOUNT && (
          <p className="mt-2.5 text-red-500">
            Tối đa: {formatCurrency(MAX_AMOUNT)}
          </p>
        )}
      </div>
    </div>
  );
}

export default observer(forwardRef(Amount));
