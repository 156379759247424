import classNames from 'classnames';
import { containerClassName, titleClassName } from 'pages/Dashboard/constants';
import MarketingIndex from 'pages/Dashboard/containers/Main/MarketingIndex';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import affiliates1 from 'styles/images/affiliates/affiliates_1.png';
import affiliates2 from 'styles/images/affiliates/affiliates_2.png';
import {
  ArrowLeftSolid,
  DocumentDuplicateOutlined,
  PlayOutlined,
  formatCurrency,
} from 'tera-dls';
import LineChartCustom from './LineChartCustom';

const data = [
  {
    id: 1,
    time: 'Hôm nay',
    total: 0,
  },
  {
    id: 2,
    time: 'Hôm qua',
    total: 0,
  },
  {
    id: 3,
    time: 'Tháng này',
    total: 0,
  },
  {
    id: 4,
    time: 'Quý 1',
    total: 0,
  },
  {
    id: 5,
    time: 'Quý 2',
    total: 0,
  },
];
const IConCard = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={28}
      fill="none"
      {...props}
    >
      <path
        fill="#1E429F"
        d="M27.6 5.597h-24a1.2 1.2 0 0 1 0-2.4H24a1.2 1.2 0 1 0 0-2.4H3.6a3.6 3.6 0 0 0-3.6 3.6v19.2a3.6 3.6 0 0 0 3.6 3.6h24a2.4 2.4 0 0 0 2.4-2.4v-16.8a2.4 2.4 0 0 0-2.4-2.4Zm0 19.2h-24a1.2 1.2 0 0 1-1.2-1.2V7.79c.385.137.791.206 1.2.206h24v16.8Zm-7.2-9a1.8 1.8 0 1 1 3.6 0 1.8 1.8 0 0 1-3.6 0Z"
      />
    </svg>
  );
};
const AffiliateDetail = () => {
  const [showButton, setShowButton] = useState<boolean>(false);
  const ref = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!ref.current) return;
    const handleScroll = (event) => {
      const target: HTMLElement = event.target;
      const isShow =
        target.scrollTop + target.clientHeight >= window.innerHeight;
      isShow ? setShowButton(true) : setShowButton(false);
    };
    ref.current.addEventListener('scroll', handleScroll);

    return () => {
      ref.current && ref.current.removeEventListener('scroll', handleScroll);
    };
  }, [ref.current]);

  const scrollToTop = () => {
    ref.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="px-[15px] pb-[30px] transition-all flex-1 bg-white xl:rounded-l-[30px] xl:shadow-[-4px_0_10px_-0_rgba(0,0,0,0.1)] scrollbar-none overflow-scroll">
      {showButton && (
        <div
          onClick={scrollToTop}
          className="absolute bottom-[20px] z-[60] right-[26px] hover:bg-blue-200 p-[6px] rounded-full bg-gray-200 inline-block cursor-pointer"
        >
          <PlayOutlined className="w-6 rotate-[-90deg]" />
        </div>
      )}
      <div className="flex justify-between mb-2.5 sticky top-0 xl:px-[15px] xl:py-[30px] z-50 bg-white">
        <div className="text-[#1E429F] text-base flex gap-2.5 header">
          <ArrowLeftSolid
            className="w-5 cursor-pointer"
            onClick={() => navigate('/dashboard')}
          />
        </div>
        <h2 className="font-semibold text-gray-700 text-2xl	tracking-widest">
          AFFILIATES
        </h2>
      </div>
      <main ref={ref} className="overflow-auto px-5 xl:px-[15px] pt-[10px]">
        <div className="header flex flex-col gap-[32px] ">
          <section className={classNames('bg-gray-100', containerClassName)}>
            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2.5 px-2.5">
                  <h3 className={titleClassName}>Tổng quan</h3>
                  <h1 className="text-[30px] leading-[35px] font-medium tracking-widest text-blue-800">
                    {formatCurrency(0)}
                  </h1>
                </div>
                <div className="flex gap-[16px] items-center">
                  <div className="p-[12px] rounded-[4px] bg-blue-100 inline-block">
                    <IConCard className={'w-full'} />
                  </div>
                  <div className="flex flex-col gap-[5px]">
                    <h3 className="font-normal text-base leading-[18px] text-blue-800">
                      Tổng tiền hoa hồng
                    </h3>
                    <h4 className="text-gray-700">
                      Thu nhập từ: dd/mm/yyyy - dd/mm/yyyy
                    </h4>
                  </div>
                </div>
              </div>
              <img src={affiliates1} alt="affiliates_1" className="shrink-0" />
            </div>
          </section>
          <MarketingIndex isButton={false} />
          <section className={classNames('bg-blue-50', containerClassName)}>
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-y-4">
                <div>
                  <p className="text-base font-semibold text-blue-800 tracking-widest">
                    Affilliates code
                  </p>
                  <p className="pt-[5px]">
                    Use your Affilliates code to promote and get customers
                  </p>
                </div>
                <div>
                  <p>Code link</p>
                  <div className="flex gap-x-2.5 items-center mt-[5px]">
                    <p className="text-base p-2.5 bg-blue-100 rounded-[2px]">
                      https://bit.ly/3GHprJz
                    </p>
                    <DocumentDuplicateOutlined className="w-5 h-5 text-blue-600 hover:text-blue-800 cursor-pointer" />
                  </div>
                </div>
              </div>
              <img src={affiliates2} alt="affiliates_2" className="shrink-0" />
            </div>
          </section>
          <section className={classNames('bg-gray-100 ', containerClassName)}>
            <LineChartCustom />
          </section>
          <section className={classNames('bg-gray-100 ', containerClassName)}>
            <h3 className={titleClassName}>Doanh thu gần đây</h3>
            <ul className="flex flex-col gap-2 pt-2.5">
              {data.map((item) => {
                return (
                  <li className="flex justify-between pb-2.5 border-b-[#E5E7EB] border-b-[1px]">
                    <span className="text-[#3F83F8] font-medium">
                      {item?.time}
                    </span>
                    <span>{formatCurrency(item?.total)}</span>
                  </li>
                );
              })}
            </ul>
          </section>
        </div>
      </main>
    </div>
  );
};

export default AffiliateDetail;
