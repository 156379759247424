import { adminEndpoint, webApi } from '_common/constants/common';
import api from 'states/drivers';

const ModuleEndpoint = `${adminEndpoint}/administrator/module`;
const WebEndpoint = `${webApi}/web/blog`;

const ModuleApi = {
  getList: async (param?) =>
    await api
      .get(`${ModuleEndpoint}/list`, param)
      .then((result) => result.data?.data),
  getNews: async ({ params }) =>
    await api
      .get(`${WebEndpoint}/list`, params)
      .then((result) => result.data?.data),
};

export default ModuleApi;
