import Filter from '_common/component/Filter';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectJobTitle from '_common/dof/Select/SelectJobTitle';
import SelectPosition from '_common/dof/Select/SelectPosition';
import { IFilter } from 'pages/EmployeeInformation/interface';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue?: IFilter;
}

function EmployeeFilter({ open, onClose, onFilter, initialValue }: IProps) {
  const newForm = useForm({
    mode: 'onChange',
  });

  const handleSubmitForm = (values: IFilter) => {
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const data = {
      job_title: initialValue?.job_title,
      department: initialValue?.department,
    };

    initialValue &&
      newForm.reset({
        ...data,
      });
  }, [initialValue]);

  const handleReset = () => {
    const values: IFilter = {
      job_title: null,
      department: null,
    };
    onFilter(values);
    onClose();
  };

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => newForm?.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={newForm} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Chức danh" name="job_title">
          <SelectJobTitle />
        </FormTeraItem>
        <FormTeraItem label="Phòng ban/Bộ phận" name="department">
          <SelectPosition />
        </FormTeraItem>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default EmployeeFilter;
