import { PERMISSION_KEY } from './permission';

export const STORE_URL = {
  list: {
    key: PERMISSION_KEY.STORE_VIEW_LIST,
    path: '/store',
    shortenUrl: '',
  },
  create: {
    key: PERMISSION_KEY.STORE_CREATE,
    path: '/store/create',
    shortenUrl: 'create',
  },
  update: {
    key: PERMISSION_KEY.STORE_UPDATE,
    path: '/store/update',
    shortenUrl: 'update/:id',
  },
  detail: {
    key: PERMISSION_KEY.STORE_DETAIL,
    path: '/store/detail',
    shortenUrl: 'detail/:id',
  },
};
