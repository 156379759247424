import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CartEndpoint = `${endpoint}/portal/cart`;

const CartApi = {
  getList: async ({ params }) =>
    await api
      .get(`${CartEndpoint}/list`, params)
      .then((result) => result.data?.data),
  countCart: async () =>
    await api
      .get(`${CartEndpoint}/count-cart`)
      .then((result) => result.data?.data),
  getPayment: async ({ params }) =>
    await api
      .post(`${CartEndpoint}/payment-cart`, params)
      .then((result) => result.data?.data),
  create: async (params: any) =>
    await api
      .post(`${CartEndpoint}/create`, params)
      .then((result) => result.data),
  updateQuantity: async ({ id, params }) =>
    await api
      .put(`${CartEndpoint}/update/${id}`, params)
      .then((result) => result.data),
  changePackage: async ({ id, params }) =>
    await api
      .put(`${CartEndpoint}/replace/${id}`, params)
      .then((result) => result.data),
  delete: async ({ id }) =>
    await api
      .delete(`${CartEndpoint}/delete/${id}`)
      .then((result) => result.data),
};

export default CartApi;
