import DefaultImage from '_common/component/DefaultImage';
import classNames from 'classnames';
import Empty from 'pages/Wallet/containers/Empty';
import EmptyDashboard from 'styles/images/wallet/empty_dashboard.png';
import { CalendarDaysOutlined } from 'tera-dls';

function ActivityHistory({ open }) {
  return (
    <div
      className={classNames(
        'flex-1 h-full bg-white rounded-t-[30px] shadow-[0_-4px_4px_0_rgba(0,0,0,0.05)] p-[30px]',
        {
          hidden: !open,
        },
      )}
    >
      <span className="text-[#878787] text-base font-semibold">
        Lịch sử hoạt động
      </span>
      <div className="h-full overflow-auto mt-[27px]">
        {false ? (
          <div className="flex flex-col gap-y-4">
            <div className="px-[14px] py[6px] flex gap-x-2.5 ">
              <CalendarDaysOutlined className="w-5 h-5 text-[#F8B4B4]" />
              <span className="font-semibold">dd/mm/yyyy</span>
            </div>
            <div className="pb-4 [&:not(:last-child)]:border-b border-dashed flex gap-x-2.5">
              <DefaultImage
                src=""
                alt=""
                className="rounded-full w-6 h-6 flex-shrink-0"
              />
              <div className="flex flex-col">
                <div className="flex justify-between items-center">
                  <span className="line-clamp-1 break-word text-[#1C64F2] text-[13px]">
                    Vũ Gia An
                  </span>
                  <span className="text-[#374151] font-light italic">
                    hh:mm
                  </span>
                </div>

                <p className="mt-[5px]">
                  đã <span className="font-medium	text-blue-800">thêm mới</span>{' '}
                  nhân viên{' '}
                  <span className="text-blue-600">Nguyễn Bảo Long</span>
                </p>
              </div>
            </div>
            <div className="pb-4 [&:not(:last-child)]:border-b border-dashed flex gap-x-2.5">
              <DefaultImage
                src=""
                alt=""
                className="rounded-full w-6 h-6 flex-shrink-0"
              />
              <div className="flex flex-col">
                <div className="flex justify-between items-center">
                  <span className="line-clamp-1 break-word text-[#1C64F2] text-[13px]">
                    Vũ Gia An
                  </span>
                  <span className="text-[#374151] font-light italic">
                    hh:mm
                  </span>
                </div>

                <p className="mt-[5px]">
                  đã <span className="font-medium	text-blue-800">thêm mới</span>{' '}
                  nhân viên{' '}
                  <span className="text-blue-600">Nguyễn Bảo Long</span>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="fade-enter">
            <Empty
              sub="Chưa có dữ liệu "
              image={<img src={EmptyDashboard} />}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ActivityHistory;
