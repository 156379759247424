import Recharge from '_common/component/Icons/Recharge';
import Withdrawal from '_common/component/Icons/Withdrawal';
import React from 'react';
import { Button, ButtonProps } from 'tera-dls';

interface ButtonWalletProps extends ButtonProps {
  title: string;
  typeBtn?: 'recharge' | 'withdrawal';
}

function ButtonWallet(props: ButtonWalletProps) {
  const { title, typeBtn = 'recharge', ...rest } = props;
  const icon = {
    recharge: <Recharge />,
    withdrawal: <Withdrawal />,
  };

  return (
    <Button
      {...rest}
      className={`min-w-[140px] rounded-full bg-blue-50 hover:bg-blue-100 py-2.5 px-auto shadow-[0_6.5px_5.5px_0_rgba(0,0,0,0.028),0_4px_10px_0_rgba(0,0,0,0.035)]`}
    >
      <div className="flex items-center gap-x-2.5">
        {icon[typeBtn]}
        <span className={`text-blue-800`}>{title}</span>
      </div>
    </Button>
  );
}

export default ButtonWallet;
