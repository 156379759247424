import ImageBackground from '_common/component/ImageBackground';
import { REGEX } from '_common/constants/common';
import Input from '_common/dof/Control/Input';
import InputPassword from '_common/dof/Control/InputPassword';
import { FormTeraItem } from '_common/dof/FormTera';
import SelectDepartment from '_common/dof/Select/SelectDepartment';
import SelectJobTitle from '_common/dof/Select/SelectJobTitle';
import { useState } from 'react';
import { PencilSquareOutlined } from 'tera-dls';
import ChangingPassword from './ChangingPassword';

interface IProps {
  avatar?: any;
  updateAvatar?: (value) => void;
  value: any;
}

const UserForm = (props: IProps) => {
  const { avatar, updateAvatar, value } = props;

  const [changingPasswordModel, setChangingPasswordModel] = useState<any>({
    open: false,
  });

  return (
    <>
      <div className="flex flex-col gap-[16px] w-full">
        <div className="p-4 rounded-[10px] border-[1px] border-gray-200 flex flex-col gap-[31px] shadow-lg">
          <div className="text-[22px] leading-[22px] text-[#1F2937] font-medium">
            Thông tin cá nhân
          </div>
          <div className="flex gap-[16px]">
            <div>
              <ImageBackground
                onChange={updateAvatar}
                value={avatar}
                isShowBtnDelete
                object_key={'portal_user_information'}
                folder={'portal'}
              />
            </div>
            <div className="w-full">
              <FormTeraItem
                label="Họ và tên"
                layout="inline"
                labelClassName="w-[150px]"
                className="flex items-start"
                rules={[{ required: 'Vui lòng nhập dữ liệu' }]}
                name="full_name"
              >
                <Input
                  placeholder="Vui lòng nhập"
                  maxLength={100}
                  className="py-[13px]"
                />
              </FormTeraItem>
              <FormTeraItem
                label="Email"
                name="email"
                layout="inline"
                className="flex items-start"
                labelClassName="w-[150px]"
                isRequired={false}
                rules={[
                  {
                    required: 'Vui lòng nhập dữ liệu',
                    pattern: {
                      value: REGEX.EMAIL,
                      message: 'Sai định dạng email',
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Vui lòng nhập"
                  disabled
                  className="py-[13px]"
                />
              </FormTeraItem>
              <FormTeraItem
                label="Số điện thoại"
                name="phone"
                layout="inline"
                className="flex items-start"
                labelClassName="w-[150px]"
                rules={[
                  {
                    pattern: {
                      value: REGEX.PHONE_NUMBER,
                      message: 'Số điện thoại không hợp lệ',
                    },
                  },
                  {
                    minLength: {
                      value: 8,
                      message: 'Nhập tối thiểu 8 kí tự',
                    },
                  },
                  { required: 'Vui lòng nhập dữ liệu' },
                ]}
              >
                <Input maxLength={20} className="py-[13px]" />
              </FormTeraItem>
              <FormTeraItem
                name={'job_title'}
                label="Chức danh"
                className="flex items-start"
                layout="inline"
                labelClassName="w-[150px]"
              >
                <SelectJobTitle
                  placeholder="Vui lòng chọn"
                  className="py-[4px]"
                />
              </FormTeraItem>
              <FormTeraItem
                name={'department'}
                label="Phòng ban/ Bộ phận"
                layout="inline"
                className="flex items-start"
                labelClassName="w-[150px]"
              >
                <SelectDepartment
                  placeholder="Vui lòng chọn"
                  className="py-[4px]"
                />
              </FormTeraItem>
            </div>
          </div>
        </div>
        <div
          id="e2"
          className="shadow-lg p-4 rounded-[10px] border-[1px] border-gray-200 flex flex-col gap-[31px]"
        >
          <div className="text-[22px] leading-[22px] text-[#1F2937] font-medium">
            Thông tin đăng nhập
          </div>
          <div className="flex gap-[16px] flex-col">
            <FormTeraItem
              label="Tên đăng nhập"
              name="username"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[150px]"
            >
              <Input
                placeholder="Vui lòng nhập"
                disabled
                className="py-[13px]"
              />
            </FormTeraItem>
            <FormTeraItem
              label="Mật khẩu"
              name="password"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[150px]"
            >
              <InputPassword
                placeholder="Vui lòng nhập"
                disabled
                className="py-[13px]"
                visibilityToggle={{
                  visible: false,
                }}
                iconRender={() => (
                  <PencilSquareOutlined
                    onClick={() =>
                      setChangingPasswordModel({ open: true, value: '' })
                    }
                    className="w-5 h-5 text-green-500 cursor-pointer"
                  />
                )}
              />
            </FormTeraItem>
          </div>
        </div>
        {/* <div
          id="e3"
          className="p-4 shadow-lg rounded-[10px] border-[1px] border-gray-200 flex flex-col gap-[31px]"
        >
          <div className="text-[22px] leading-[22px] text-[#1F2937] font-medium">
            Thông tin đăng ký
          </div>
          <div className="flex gap-[16px] flex-col">
            <FormTeraItem
              label="Ngày đăng ký"
              name="register_time"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[150px]"
            >
              <DatePicker
                placeholder="dd/mm/yyyy"
                disabled
                format={DATE_FORMAT}
                inputClassName="py-[15px]"
              />
            </FormTeraItem>
            <FormTeraItem
              label="Ngày hết hạn"
              name="expiration_time"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[150px]"
            >
              <DatePicker
                placeholder="dd/mm/yyyy"
                disabled
                format={DATE_FORMAT}
                inputClassName="py-[13px]"
              />
            </FormTeraItem>
            <FormTeraItem
              label="Thời gian dùng thử"
              name="trial_time"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[150px]"
            >
              <Input placeholder="Vui lòng nhập" disabled />
            </FormTeraItem>
          </div>
        </div> */}
      </div>
      {changingPasswordModel?.open && (
        <ChangingPassword
          open={changingPasswordModel?.open}
          value={value}
          onClose={() => setChangingPasswordModel({ open: false })}
        />
      )}
    </>
  );
};

export default UserForm;
