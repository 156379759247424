import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import NotPermission from 'pages/EmployeeInformation/containers/NotPermission';
import { Outlet } from 'react-router-dom';

const CheckBusiness = observer(() => {
  const { authStore } = useStores();

  if (authStore.user?.type === 'individual') {
    return (
      <div className="h-full flex justify-center items-center">
        <NotPermission />
      </div>
    );
  }
  return <Outlet />;
});

export default CheckBusiness;
