import { useQuery } from '@tanstack/react-query';
import Expire from '_common/component/Expire';
import NoData from '_common/component/NoData';
import NoPermission from '_common/component/NoPermission';
import { useStores } from '_common/hooks';
import { toJS } from 'mobx';
import NotPermission from 'pages/EmployeeInformation/containers/NotPermission';
import { useState } from 'react';
import { TabItemType, Tabs } from 'tera-dls';
import ModuleApi from './_api/admin';
import MemberList from './containers/MemberList';
import ModuleItem from './containers/ModuleItem';
import SearchApplication from './containers/Search';

function Application() {
  const [params, setParams] = useState({
    keyword: '',
    type: 'individual',
  });
  const [modalMember, setModalMember] = useState({
    open: false,
    module_id: null,
  });

  const {
    authStore: { user },
  } = useStores();
  const { business } = toJS(user);

  const { data } = useQuery(
    ['get-module-list', params],
    () => ModuleApi.getList(params),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const handleSearch = (values) => {
    setParams({ ...params, keyword: values?.keyword });
  };

  const handleChangeTab = (key) => {
    setParams({ ...params, type: key });
  };

  const itemsTab: TabItemType[] = [
    {
      key: 'individual',
      label: 'Cá nhân',
    },
    {
      key: 'business',
      label: 'Doanh nghiệp',
    },
  ];
  const isCancel = business?.status === 'cancelled';
  const renderUI = (data, type, status) => {
    if (data?.code === 210)
      return (
        <div className="py-2.5">
          <Expire />
        </div>
      );
    if (status === 'business' && type === 'individual') {
      return (
        <div className="py-2.5">
          <NotPermission />
        </div>
      );
    }

    return (
      <>
        {data?.data?.length > 0 ? (
          data?.data?.map((module) => (
            <ModuleItem
              data={module}
              showButton={user?.type === 'owner' && params?.type === 'business'}
              onClickButton={() =>
                setModalMember({ open: true, module_id: module?.id })
              }
            />
          ))
        ) : (
          <NoData />
        )}
      </>
    );
  };
  if (isCancel) {
    return <NoPermission />;
  }
  return (
    <div className="flex flex-col gap-y-4 p-5">
      <h1 className="portal-heading">Ứng dụng</h1>
      <div className="flex justify-between gap-x-2.5">
        <SearchApplication onSearch={handleSearch} />
      </div>
      <div>
        <div className="bg-white shadow-xsm rounded-[5px] overflow-hidden h-full">
          <Tabs items={itemsTab} onChange={handleChangeTab} className="mb-0" />
        </div>
        {renderUI(data, user?.type, params?.type)}
      </div>

      {modalMember?.open && (
        <MemberList
          module_id={modalMember?.module_id}
          open={modalMember?.open}
          onClose={() => setModalMember({ open: false, module_id: null })}
        />
      )}
    </div>
  );
}

export default Application;
