const RegisterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#99919F"
      d="M12.565 19.227H1.315V6.289a1.437 1.437 0 0 1 1.438-1.437h7.987c1.007 0 1.825.818 1.825 1.825v12.55Z"
    />
    <path
      fill="url(#a)"
      d="M12.565 19.227H1.315V6.289a1.437 1.437 0 0 1 1.438-1.437h7.987c1.007 0 1.825.818 1.825 1.825v12.55Z"
    />
    <path
      fill="url(#b)"
      d="M12.565 19.227H1.315V6.289a1.437 1.437 0 0 1 1.438-1.437h7.987c1.007 0 1.825.818 1.825 1.825v12.55Z"
    />
    <path
      fill="url(#c)"
      d="M12.565 19.227H1.315V6.289a1.437 1.437 0 0 1 1.438-1.437h7.987c1.007 0 1.825.818 1.825 1.825v12.55Z"
    />
    <path
      fill="#B5AEBA"
      d="M18.815 19.227H7.565V6.289a1.437 1.437 0 0 1 1.438-1.437h8.375a1.437 1.437 0 0 1 1.437 1.437v12.938Z"
    />
    <path
      fill="url(#d)"
      d="M18.815 19.227H7.565V6.289a1.437 1.437 0 0 1 1.438-1.437h8.375a1.437 1.437 0 0 1 1.437 1.437v12.938Z"
    />
    <path
      fill="url(#e)"
      d="M18.815 19.227H7.565V6.289a1.437 1.437 0 0 1 1.438-1.437h8.375a1.437 1.437 0 0 1 1.437 1.437v12.938Z"
    />
    <path
      fill="url(#f)"
      d="M18.815 19.227H7.565V6.289a1.437 1.437 0 0 1 1.438-1.437h8.375a1.437 1.437 0 0 1 1.437 1.437v12.938Z"
    />
    <path
      fill="url(#g)"
      d="M18.815 19.227H7.565V6.289a1.437 1.437 0 0 1 1.438-1.437h8.375a1.437 1.437 0 0 1 1.437 1.437v12.938Z"
    />
    <g filter="url(#h)">
      <path
        fill="url(#i)"
        d="M17.263 7.367h-1.865a.312.312 0 0 0-.312.313v1.245c0 .172.14.312.312.312h1.865c.173 0 .313-.14.313-.312V7.68a.312.312 0 0 0-.313-.313Z"
      />
    </g>
    <g filter="url(#j)">
      <path
        fill="url(#k)"
        d="M4.732 7.367H2.867a.312.312 0 0 0-.312.313v1.245c0 .172.14.312.312.312h1.865c.173 0 .313-.14.313-.312V7.68a.312.312 0 0 0-.313-.313Z"
      />
    </g>
    <g filter="url(#l)">
      <path
        fill="url(#m)"
        d="M17.263 10.453h-1.865a.312.312 0 0 0-.312.313v1.245c0 .172.14.312.312.312h1.865c.173 0 .313-.14.313-.312v-1.245a.312.312 0 0 0-.313-.313Z"
      />
    </g>
    <g filter="url(#n)">
      <path
        fill="url(#o)"
        d="M4.732 10.453H2.867a.312.312 0 0 0-.312.313v1.245c0 .172.14.312.312.312h1.865c.173 0 .313-.14.313-.312v-1.245a.312.312 0 0 0-.313-.313Z"
      />
    </g>
    <g filter="url(#p)">
      <path
        fill="url(#q)"
        d="M17.263 13.555h-1.865a.312.312 0 0 0-.312.312v1.245c0 .173.14.313.312.313h1.865c.173 0 .313-.14.313-.313v-1.245a.312.312 0 0 0-.313-.312Z"
      />
    </g>
    <g filter="url(#r)">
      <path
        fill="url(#s)"
        d="M4.732 13.555H2.867a.312.312 0 0 0-.312.312v1.245c0 .173.14.313.312.313h1.865c.173 0 .313-.14.313-.313v-1.245a.312.312 0 0 0-.313-.312Z"
      />
    </g>
    <g filter="url(#t)">
      <path
        fill="url(#u)"
        d="M16.315 19.227h-12.5V3.264c0-.85.688-1.537 1.538-1.537h9.425c.85 0 1.537.687 1.537 1.537v15.963Z"
      />
      <path
        fill="url(#v)"
        d="M16.315 19.227h-12.5V3.264c0-.85.688-1.537 1.538-1.537h9.425c.85 0 1.537.687 1.537 1.537v15.963Z"
      />
      <path
        fill="url(#w)"
        d="M16.315 19.227h-12.5V3.264c0-.85.688-1.537 1.538-1.537h9.425c.85 0 1.537.687 1.537 1.537v15.963Z"
      />
      <path
        fill="url(#x)"
        d="M16.315 19.227h-12.5V3.264c0-.85.688-1.537 1.538-1.537h9.425c.85 0 1.537.687 1.537 1.537v15.963Z"
      />
    </g>
    <path
      fill="url(#y)"
      d="M16.315 19.227h-12.5V3.264c0-.85.688-1.537 1.538-1.537h9.425c.85 0 1.537.687 1.537 1.537v15.963Z"
    />
    <g filter="url(#z)">
      <path
        fill="url(#A)"
        d="M11.94 19.227H8.19v-2.344c0-.431.35-.781.782-.781h2.187c.431 0 .788.35.788.787v2.338h-.007Z"
      />
      <path
        fill="url(#B)"
        d="M11.94 19.227H8.19v-2.344c0-.431.35-.781.782-.781h2.187c.431 0 .788.35.788.787v2.338h-.007Z"
      />
    </g>
    <g filter="url(#C)">
      <path
        fill="url(#D)"
        d="M9.118 3.625H7.253a.312.312 0 0 0-.313.313v1.244c0 .173.14.313.313.313h1.865c.173 0 .312-.14.312-.313V3.938a.312.312 0 0 0-.312-.312Z"
      />
    </g>
    <g filter="url(#E)">
      <path
        fill="url(#F)"
        d="M9.118 6.719H7.253a.312.312 0 0 0-.313.312v1.245c0 .173.14.313.313.313h1.865c.173 0 .312-.14.312-.313V7.031a.312.312 0 0 0-.312-.312Z"
      />
    </g>
    <g filter="url(#G)">
      <path
        fill="url(#H)"
        d="M9.118 9.813H7.253a.312.312 0 0 0-.313.312v1.245c0 .173.14.313.313.313h1.865c.173 0 .312-.14.312-.313v-1.245a.312.312 0 0 0-.312-.313Z"
      />
    </g>
    <g filter="url(#I)">
      <path
        fill="url(#J)"
        d="M9.118 13H7.253a.312.312 0 0 0-.313.313v1.244c0 .173.14.313.313.313h1.865c.173 0 .312-.14.312-.313v-1.245A.312.312 0 0 0 9.118 13Z"
      />
    </g>
    <g filter="url(#K)">
      <path
        fill="url(#L)"
        d="M12.858 3.625h-1.865a.312.312 0 0 0-.312.313v1.244c0 .173.14.313.312.313h1.865c.173 0 .313-.14.313-.313V3.938a.312.312 0 0 0-.313-.312Z"
      />
    </g>
    <path
      fill="#FB4EFF"
      fillOpacity={0.5}
      d="M12.858 6.719h-1.865a.312.312 0 0 0-.312.312v1.245c0 .173.14.313.312.313h1.865c.173 0 .313-.14.313-.313V7.031a.312.312 0 0 0-.313-.312Z"
    />
    <g filter="url(#M)">
      <path
        fill="url(#N)"
        d="M12.858 6.719h-1.865a.312.312 0 0 0-.312.312v1.245c0 .173.14.313.312.313h1.865c.173 0 .313-.14.313-.313V7.031a.312.312 0 0 0-.313-.312Z"
      />
    </g>
    <g filter="url(#O)">
      <path
        fill="url(#P)"
        d="M12.858 9.813h-1.865a.312.312 0 0 0-.312.312v1.245c0 .173.14.313.312.313h1.865c.173 0 .313-.14.313-.313v-1.245a.312.312 0 0 0-.313-.313Z"
      />
    </g>
    <g filter="url(#Q)">
      <path
        fill="url(#R)"
        d="M12.858 13h-1.865a.312.312 0 0 0-.312.313v1.244c0 .173.14.313.312.313h1.865c.173 0 .313-.14.313-.313v-1.245a.312.312 0 0 0-.313-.312Z"
      />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1={4.172}
        x2={2.972}
        y1={14.343}
        y2={14.343}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#605965" />
        <stop offset={1} stopColor="#605965" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="b"
        x1={4.37}
        x2={4.37}
        y1={4.497}
        y2={6.501}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#959595" />
        <stop offset={1} stopColor="#959595" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="c"
        x1={1.315}
        x2={2.052}
        y1={14.99}
        y2={14.99}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7B777E" />
        <stop offset={1} stopColor="#7B777E" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={16.214}
        x2={16.624}
        y1={15.711}
        y2={15.711}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A59FA9" />
        <stop offset={1} stopColor="#A59FA9" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="e"
        x1={16.331}
        x2={16.331}
        y1={4.773}
        y2={5.594}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B3B3B3" />
        <stop offset={1} stopColor="#B3B3B3" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="f"
        x1={19.026}
        x2={17.776}
        y1={14.852}
        y2={14.852}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D2CFD4" />
        <stop offset={1} stopColor="#D2CFD4" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="i"
        x1={15.278}
        x2={17.576}
        y1={8.302}
        y2={8.302}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58E6FF" />
        <stop offset={1} stopColor="#44B8FF" />
      </linearGradient>
      <linearGradient
        id="k"
        x1={2.748}
        x2={5.045}
        y1={8.302}
        y2={8.302}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#47ABE5" />
        <stop offset={1} stopColor="#44B8FF" />
      </linearGradient>
      <linearGradient
        id="m"
        x1={15.278}
        x2={17.576}
        y1={11.388}
        y2={11.388}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58E6FF" />
        <stop offset={1} stopColor="#44B8FF" />
      </linearGradient>
      <linearGradient
        id="o"
        x1={2.748}
        x2={5.045}
        y1={11.388}
        y2={11.388}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#47ABE5" />
        <stop offset={1} stopColor="#44B8FF" />
      </linearGradient>
      <linearGradient
        id="q"
        x1={15.278}
        x2={17.576}
        y1={14.49}
        y2={14.49}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58E6FF" />
        <stop offset={1} stopColor="#44B8FF" />
      </linearGradient>
      <linearGradient
        id="s"
        x1={2.748}
        x2={5.045}
        y1={14.49}
        y2={14.49}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#47ABE5" />
        <stop offset={1} stopColor="#44B8FF" />
      </linearGradient>
      <linearGradient
        id="u"
        x1={4.927}
        x2={14.459}
        y1={17.635}
        y2={17.635}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ACA3B3" />
        <stop offset={1} stopColor="#BEB3C4" />
      </linearGradient>
      <linearGradient
        id="v"
        x1={10.065}
        x2={10.065}
        y1={1.57}
        y2={3.225}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B8B7B8" />
        <stop offset={1} stopColor="#B8B7B8" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="w"
        x1={16.315}
        x2={14.951}
        y1={11.594}
        y2={11.594}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E0DDE2" />
        <stop offset={1} stopColor="#E0DDE2" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="A"
        x1={8.19}
        x2={12.246}
        y1={17.666}
        y2={17.666}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4E405B" />
        <stop offset={1} stopColor="#3F324C" />
      </linearGradient>
      <linearGradient
        id="B"
        x1={8.334}
        x2={8.92}
        y1={19.227}
        y2={19.227}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#574C64" />
        <stop offset={1} stopColor="#574C64" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="D"
        x1={7.134}
        x2={9.43}
        y1={4.56}
        y2={4.56}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58E6FF" />
        <stop offset={1} stopColor="#44B8FF" />
      </linearGradient>
      <linearGradient
        id="F"
        x1={7.134}
        x2={9.43}
        y1={7.654}
        y2={7.654}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58E6FF" />
        <stop offset={1} stopColor="#44B8FF" />
      </linearGradient>
      <linearGradient
        id="H"
        x1={7.134}
        x2={9.43}
        y1={10.748}
        y2={10.748}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58E6FF" />
        <stop offset={1} stopColor="#44B8FF" />
      </linearGradient>
      <linearGradient
        id="J"
        x1={7.134}
        x2={9.43}
        y1={13.935}
        y2={13.935}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58E6FF" />
        <stop offset={1} stopColor="#44B8FF" />
      </linearGradient>
      <linearGradient
        id="L"
        x1={10.874}
        x2={13.171}
        y1={4.56}
        y2={4.56}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58E6FF" />
        <stop offset={1} stopColor="#44B8FF" />
      </linearGradient>
      <linearGradient
        id="N"
        x1={10.874}
        x2={13.171}
        y1={7.654}
        y2={7.654}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58E6FF" />
        <stop offset={1} stopColor="#44B8FF" />
      </linearGradient>
      <linearGradient
        id="P"
        x1={10.874}
        x2={13.171}
        y1={10.748}
        y2={10.748}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58E6FF" />
        <stop offset={1} stopColor="#44B8FF" />
      </linearGradient>
      <linearGradient
        id="R"
        x1={10.874}
        x2={13.171}
        y1={13.935}
        y2={13.935}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58E6FF" />
        <stop offset={1} stopColor="#44B8FF" />
      </linearGradient>
      <filter
        id="h"
        width={2.678}
        height={2.055}
        x={14.898}
        y={7.367}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-0.188} dy={0.188} />
        <feGaussianBlur stdDeviation={0.3} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.501961 0 0 0 0 0.878431 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3726_5019" />
      </filter>
      <filter
        id="j"
        width={2.678}
        height={2.055}
        x={2.367}
        y={7.367}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-0.188} dy={0.188} />
        <feGaussianBlur stdDeviation={0.3} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.501961 0 0 0 0 0.878431 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3726_5019" />
      </filter>
      <filter
        id="l"
        width={2.678}
        height={2.055}
        x={14.898}
        y={10.453}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-0.188} dy={0.188} />
        <feGaussianBlur stdDeviation={0.3} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.501961 0 0 0 0 0.878431 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3726_5019" />
      </filter>
      <filter
        id="n"
        width={2.678}
        height={2.055}
        x={2.367}
        y={10.453}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-0.188} dy={0.188} />
        <feGaussianBlur stdDeviation={0.3} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.501961 0 0 0 0 0.878431 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3726_5019" />
      </filter>
      <filter
        id="p"
        width={2.678}
        height={2.055}
        x={14.898}
        y={13.555}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-0.188} dy={0.188} />
        <feGaussianBlur stdDeviation={0.3} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.501961 0 0 0 0 0.878431 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3726_5019" />
      </filter>
      <filter
        id="r"
        width={2.678}
        height={2.055}
        x={2.367}
        y={13.555}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-0.188} dy={0.188} />
        <feGaussianBlur stdDeviation={0.3} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.501961 0 0 0 0 0.878431 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3726_5019" />
      </filter>
      <filter
        id="t"
        width={13.25}
        height={17.575}
        x={3.815}
        y={1.727}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={1.5} dy={0.075} />
        <feGaussianBlur stdDeviation={0.375} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3726_5019" />
      </filter>
      <filter
        id="z"
        width={4.056}
        height={3.425}
        x={7.89}
        y={16.102}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-0.3} dy={0.3} />
        <feGaussianBlur stdDeviation={0.225} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.164706 0 0 0 0 0.101961 0 0 0 0 0.223529 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3726_5019" />
      </filter>
      <filter
        id="C"
        width={2.903}
        height={2.167}
        x={6.753}
        y={3.513}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-0.188} dy={0.188} />
        <feGaussianBlur stdDeviation={0.3} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.501961 0 0 0 0 0.878431 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3726_5019" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={0.225} dy={-0.113} />
        <feGaussianBlur stdDeviation={0.225} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.501961 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3726_5019"
          result="effect2_innerShadow_3726_5019"
        />
      </filter>
      <filter
        id="E"
        width={2.903}
        height={2.167}
        x={6.753}
        y={6.606}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-0.188} dy={0.188} />
        <feGaussianBlur stdDeviation={0.3} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.501961 0 0 0 0 0.878431 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3726_5019" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={0.225} dy={-0.113} />
        <feGaussianBlur stdDeviation={0.225} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.501961 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3726_5019"
          result="effect2_innerShadow_3726_5019"
        />
      </filter>
      <filter
        id="G"
        width={2.79}
        height={2.167}
        x={6.753}
        y={9.7}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-0.188} dy={0.188} />
        <feGaussianBlur stdDeviation={0.3} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.501961 0 0 0 0 0.878431 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3726_5019" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={0.113} dy={-0.113} />
        <feGaussianBlur stdDeviation={0.225} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.501961 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3726_5019"
          result="effect2_innerShadow_3726_5019"
        />
      </filter>
      <filter
        id="I"
        width={2.903}
        height={2.167}
        x={6.753}
        y={12.887}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-0.188} dy={0.188} />
        <feGaussianBlur stdDeviation={0.3} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.501961 0 0 0 0 0.878431 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3726_5019" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={0.225} dy={-0.113} />
        <feGaussianBlur stdDeviation={0.225} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.501961 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3726_5019"
          result="effect2_innerShadow_3726_5019"
        />
      </filter>
      <filter
        id="K"
        width={2.903}
        height={2.167}
        x={10.493}
        y={3.513}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-0.188} dy={0.188} />
        <feGaussianBlur stdDeviation={0.3} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.501961 0 0 0 0 0.878431 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3726_5019" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={0.225} dy={-0.113} />
        <feGaussianBlur stdDeviation={0.225} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.501961 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3726_5019"
          result="effect2_innerShadow_3726_5019"
        />
      </filter>
      <filter
        id="M"
        width={2.903}
        height={2.167}
        x={10.493}
        y={6.606}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-0.188} dy={0.188} />
        <feGaussianBlur stdDeviation={0.3} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.501961 0 0 0 0 0.878431 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3726_5019" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={0.225} dy={-0.113} />
        <feGaussianBlur stdDeviation={0.225} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.501961 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3726_5019"
          result="effect2_innerShadow_3726_5019"
        />
      </filter>
      <filter
        id="O"
        width={2.903}
        height={2.167}
        x={10.493}
        y={9.7}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-0.188} dy={0.188} />
        <feGaussianBlur stdDeviation={0.3} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.501961 0 0 0 0 0.878431 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3726_5019" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={0.225} dy={-0.113} />
        <feGaussianBlur stdDeviation={0.225} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.501961 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3726_5019"
          result="effect2_innerShadow_3726_5019"
        />
      </filter>
      <filter
        id="Q"
        width={2.903}
        height={2.167}
        x={10.493}
        y={12.887}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-0.188} dy={0.188} />
        <feGaussianBlur stdDeviation={0.3} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.501961 0 0 0 0 0.878431 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3726_5019" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={0.225} dy={-0.113} />
        <feGaussianBlur stdDeviation={0.225} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.501961 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3726_5019"
          result="effect2_innerShadow_3726_5019"
        />
      </filter>
      <radialGradient
        id="g"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(-1.11329 .9961 -.91141 -1.01864 18.694 5.047)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.214} stopColor="#D7D6D8" />
        <stop offset={1} stopColor="#D7D6D8" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="x"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(136.93 7.94 4.13) scale(2.35849 1.73587)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E8E7E9" />
        <stop offset={1} stopColor="#E8E7E9" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="y"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(0 -2.28493 5.53443 0 10.065 19.227)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.217} stopColor="#AFA5B7" />
        <stop offset={1} stopColor="#AFA5B7" stopOpacity={0} />
      </radialGradient>
    </defs>
  </svg>
);
export default RegisterIcon;
