import { yupResolver } from '@hookform/resolvers/yup';
// import { useGoogleLogin } from '@react-oauth/google';
import { useMutation } from '@tanstack/react-query';
import Template from '_common/component/Template';
import { CryptoJSAesEncrypt } from '_common/utils/hashHelper';
import { throttle } from 'lodash';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import GoogleIcon from 'styles/images/Icons/GoogleIcon';
import PasswordIcon from 'styles/images/Icons/PasswordIcon';
import RegisterIcon from 'styles/images/Icons/RegisterIcon';
import UserIcon from 'styles/images/Icons/UserIcon';
import backgroundRightLogin from 'styles/images/uiNew/bg-right-login.png';
import teraLogo from 'styles/images/uiNew/tera-logo.png';
import {
  Button,
  Col,
  EyeOutlined,
  EyeSlashOutlined,
  Form,
  FormItem,
  Input,
  InputPassword,
  notification,
  updateQueryParams,
} from 'tera-dls';
import * as yup from 'yup';
import { AuthApi } from '../_api/auth';
import { useStores } from '_common/hooks';

const schema = yup.object().shape({
  username: yup.string().required('Vui lòng nhập tài khoản').trim(),
  password: yup.string().required('Vui lòng nhâp mật khẩu').trim(),
});

const Content = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const {
    authStore: { updateAccessId },
  } = useStores();

  const [visible, setVisible] = useState<boolean>(false);
  const [errorAnimation, setErrorAnimation] = useState<boolean>(false);

  // const { authStore } = useStores();

  const navigate = useNavigate();
  // const location = useLocation();
  // const params: { [key: string]: any } = getQueryParams(location.search);

  const isError = !!errors?.username || !!errors?.password;

  // const login = useGoogleLogin({
  //   onSuccess: (tokenResponse) => console.log(tokenResponse.access_token),
  // });

  const handleError = useCallback(
    throttle(() => {
      setErrorAnimation(true);
      const id = setTimeout(() => {
        setErrorAnimation(false);
        clearTimeout(id);
      }, 600);
    }, 600),
    [isError, setErrorAnimation],
  );
  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.login(variables),
    {
      onSuccess: (res) => {
        res?.data?.access_id && updateAccessId(res?.data?.access_id);
        if (!!res?.data?.verify_auth) {
          setTimeout(() => {
            navigate(`/auth/otp/${res?.data?.user?.id}`);
          }, 200);
        } else {
          const bodyParams = {
            access_id: res?.data?.access_id,
          };
          const queryParams = updateQueryParams({
            client_id: 'tera',
            req: JSON.stringify(CryptoJSAesEncrypt(bodyParams)),
          });
          setTimeout(() => {
            navigate(`/auth/check-auth${queryParams}`);
          }, 10);

          // if (params?.callback) {
          //   // window.open(`${params?.callback}${queryParams}`, '_self');
          //   // console.log('params?.callback', params?.callback);
          // } else {
          //   console.log('authStore?.redirect_url', authStore?.redirect_url);
          //   navigate(
          //     `${authStore?.redirect_url}/auth/check-auth${queryParams}`,
          //   );

          //   // window.open(
          //   //   `${authStore?.redirect_url}/auth/check-auth${queryParams}`,
          //   //   '_self',
          //   // );
          // }
        }
      },

      onError: (error: any) => {
        if (error?.data?.code === 501) {
          notification.error({
            message: error?.data?.msg,
          });
          return;
        }
        if (error?.data?.code === 403) {
          notification.error({
            message: error?.data?.msg,
          });
          return;
        }
        const { field, message } = error?.data?.msg;

        if (field && message) {
          setError(field, {
            type: 'error',
            message,
          });
          handleError();
        }
      },
    },
  );

  const handleLogin = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <Template>
      <Col
        className={`w-[476px] rounded-[30px] flex items-center ${
          errorAnimation ? 'animation-ring' : ''
        }`}
        style={{
          background: `url(${backgroundRightLogin}), #BEE8EE80`,
          backgroundSize: '50% cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className=" w-full px-[30px]">
          <div className="mb-[60px] w-full flex justify-center">
            <img src={teraLogo} className="w-[270px] h-[140px] " />
          </div>
          <Form onSubmit={handleSubmit(handleLogin, handleError)}>
            <FormItem
              name={'username'}
              label=""
              className="mb-5"
              messages={errors?.username?.message}
              isError={!!errors?.username}
            >
              <Input
                maxLength={320}
                autoFocus
                placeholder="Vui lòng nhập tài khoản"
                prefix={
                  <div className="border-r-[2px] px-3 pr-4 border-r-gray-700">
                    <UserIcon viewBox="0 0 23 23" width={23} height={23} />
                  </div>
                }
                className="py-2.5 pl-[90px] text-xl text-white caret-white rounded-[10px] font-normal placeholder:text-[#FFFFFF4D] placeholder:text-base"
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}
                {...register('username')}
              />
            </FormItem>
            <FormItem
              name={'password'}
              label=""
              messages={errors?.password?.message}
              isError={!!errors?.password}
            >
              <InputPassword
                maxLength={16}
                placeholder="Vui lòng nhập mật khẩu"
                prefix={
                  <div className="border-r-[2px] border-r-gray-700 px-3 pr-4 grid place-items-center">
                    <PasswordIcon viewBox="0 0 21 26" width={23} height={23} />
                  </div>
                }
                className="bg-[#00000066] py-2.5 pl-[90px] text-xl text-white caret-white rounded-[10px] font-normal placeholder:text-[#FFFFFF4D] placeholder:text-base"
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}
                suffixProps={{
                  className: '[&>*:first-child]:h-auto [&>*:first-child]:mr-5',
                }}
                visibilityToggle={{ visible, onVisibleChange: setVisible }}
                iconRender={(visible) => (
                  <div className="text-gray-700">
                    {visible ? (
                      <EyeSlashOutlined
                        onClick={() => setVisible(false)}
                        className="w-6 h-6 cursor-pointer"
                      />
                    ) : (
                      <EyeOutlined
                        onClick={() => setVisible(true)}
                        className="w-6 h-6 cursor-pointer"
                      />
                    )}
                  </div>
                )}
                {...register('password')}
              />
            </FormItem>
            <div className="mb-[20px]">
              <span
                className="text-[15px] cursor-pointer italic leading-[15px] "
                style={{ color: '#005880' }}
                onClick={() => {
                  navigate('/auth/forgot-password');
                }}
              >
                Quên mật khẩu?
              </span>
            </div>
            <Button
              htmlType="submit"
              loading={isLoading}
              className={
                'bg-[#0095D9] w-full flex justify-center text-[#FFF] font-medium text-xl h-[50px] rounded-[10px] hover:bg-[#007fd9]'
              }
            >
              Đăng nhập
            </Button>

            <div className="text-[#0095D9] mt-2.5 mb-[16px] text-[18px] leading-[18px] font-medium w-full text-center">
              hoặc
            </div>
          </Form>
          <div className="flex gap-x-[16px]">
            <Button
              htmlType="button"
              className="gap-2.5 h-[50px] hover:bg-white w-full rounded-[10px] text-[18px] leading-[18px] flex justify-center text-[#111827] font-light bg-white py-[14px]"
              // onClick={() => login()}
            >
              <GoogleIcon />
              <span>Google</span>
            </Button>
            <Button
              onClick={() => navigate('/auth/register')}
              htmlType="button"
              className="gap-2.5 h-[50px] hover:bg-white  w-full rounded-[10px] text-[18px] leading-[18px] flex justify-center text-[#111827] font-light bg-white py-[14px]"
            >
              <RegisterIcon />
              <span> Đăng ký</span>
            </Button>
          </div>
        </div>
      </Col>
    </Template>
  );
};

export default Content;
