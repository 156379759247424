import { adminEndpoint } from '_common/constants/common';
import { parserParamsArray } from '_common/utils';
import api from 'states/drivers';

const AdministratorEndpoint = `${adminEndpoint}/administrator`;

const AdministratorApi = {
  getListModule: async (param?) =>
    await api
      .get(`${AdministratorEndpoint}/module/list`, param)
      .then((result) => result?.data?.data),
  getListMenu: async (param?) =>
    await api
      .get(`${AdministratorEndpoint}/page-menu/list`, param)
      .then((result) => result?.data?.data),
  getListEpic: async (param?) =>
    await api
      .get(`${AdministratorEndpoint}/epic/list`, param)
      .then((result) => result?.data?.data),
  getListPage: async (param?) =>
    await api
      .get(`${AdministratorEndpoint}/page/list`, param)
      .then((result) => result?.data?.data),
  getTableConfig: async (param?) =>
    await api
      .get(`${AdministratorEndpoint}/page-table/get-config`, param)
      .then((result) => result?.data?.data),
  getListTableConfig: async (param?) =>
    await api
      .get(`${AdministratorEndpoint}/page-table/list`, param)
      .then((result) => result?.data?.data),
  getListFormConfig: async (param?) =>
    await api
      .get(`${AdministratorEndpoint}/form-data/list`, param)
      .then((result) => result?.data?.data),
  getListFieldConfig: async (param?) =>
    await api
      .get(`${AdministratorEndpoint}/form-field/list`, parserParamsArray(param))
      .then((result) => result?.data?.data),
  getListControlConfig: async (param?) =>
    await api
      .get(`${AdministratorEndpoint}/page-control/list`, param)
      .then((result) => result?.data?.data),
  getListColumnConfig: async (param?) =>
    await api
      .get(
        `${AdministratorEndpoint}/page-table-column/list`,
        parserParamsArray(param),
      )
      .then((result) => result?.data?.data),
  getListIcon: async (param?) =>
    await api
      .get(`${AdministratorEndpoint}/icon/list`, param)
      .then((result) => result?.data),
  getListRole: async (param?) =>
    await api
      .get(`${AdministratorEndpoint}/role/list`, param)
      .then((result) => result?.data?.data),
  getListJobTitle: async (param?) =>
    await api
      .get(`${AdministratorEndpoint}/jobtitle/list`, param)
      .then((result) => result?.data?.data),
  getListPosition: async (param?) =>
    await api
      .get(`${AdministratorEndpoint}/position/list`, param)
      .then((result) => result?.data?.data),
  getListDataType: async (params?) =>
    await api
      .get(`${AdministratorEndpoint}/data-type/list`, params)
      .then((result) => result?.data?.data),
  getListBank: async (params?) =>
    await api
      .get(`${AdministratorEndpoint}/payment-method/bank`, params)
      .then((result) => result?.data?.data),
  getListUser: async (param?) =>
    await api
      .get(`${AdministratorEndpoint}/user/list`, param)
      .then((result) => result?.data?.data),
  getListPageConfig: async (param?) =>
    await api
      .get(`${AdministratorEndpoint}/page/list`, param)
      .then((result) => result?.data?.data),

  getListObjectType: async (params?) =>
    await api
      .get(`${AdministratorEndpoint}/object-type/list`, params)
      .then((result) => result?.data?.data),
  getListStatusType: async (params?) =>
    await api
      .get(`${AdministratorEndpoint}/object-status/list`, params)
      .then((result) => result?.data?.data),

  getListDataStatus: async (params?) =>
    await api
      .get(`${AdministratorEndpoint}/data-status/list`, params)
      .then((result) => result?.data?.data),

  getListEmailConfig: async (params?) =>
    await api
      .get(`${AdministratorEndpoint}/mail-config/list`, params)
      .then((result) => result?.data?.data),
  getListTemplateEmail: async (params?) =>
    await api
      .get(`${AdministratorEndpoint}/mail-template/list`, params)
      .then((result) => result?.data?.data),
  getTemplateEmailDetail: async (id?) =>
    await api
      .get(`${AdministratorEndpoint}/mail-template/detail/${id}`)
      .then((result) => result?.data?.data),
};

export default AdministratorApi;
