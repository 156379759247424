import { useStores } from '_common/hooks';
import useConfirm from '_common/hooks/useConfirm';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef } from 'react';
import { TTransaction } from 'states/stores/interface';
import { Button, Modal } from 'tera-dls';
import Amount from './Amount';
import OTP from './Otp';
import SelectPaymentMethod from './SelectPaymentMethod';
import Transfer from './Transfer';

type TBtn = 'back' | 'cancel' | 'next';

interface IProps {
  open: boolean;
  onClose: () => void;
  type: TTransaction;
}

export const MIN_AMOUNT = 10000;
export const MAX_AMOUNT = 5000000;

function WithDrawal(props: IProps) {
  const { open, onClose, type } = props;
  const elementRef = useRef(null);
  const confirm = useConfirm();
  const {
    transactionStore: {
      step,
      otp,
      account,
      amount,
      backStep,
      setType,
      setStep,
      clearTransaction,
      setAmount,
      setOtp,
    },
  } = useStores();

  const renderTitle = (step) => {
    const isRecharge = type === 'recharge';
    const title = {
      0: isRecharge ? 'Chọn phương thức thanh toán' : 'Chọn ngân hàng',
      1: isRecharge ? 'Nạp tiền' : 'Rút tiền',
      2: isRecharge ? 'Nạp tiền' : 'Rút tiền',
      3: isRecharge ? 'Nạp tiền' : 'Rút tiền',
      4: 'Chi tiết giao dịch',
    };
    return title[step] || 'Không xác định';
  };

  const renderContent = (step) => {
    const content = {
      0: <SelectPaymentMethod ref={elementRef} />,
      1: <Amount ref={elementRef} />,
      2: <OTP ref={elementRef} />,
      3: <Transfer ref={elementRef} />,
    };
    return content[step] || 'Không xác định';
  };

  const renderTitleBtn = (type: TBtn, step) => {
    if (type === 'next') {
      const title = {
        0: 'Tiếp tục',
        1: 'Xác nhận',
        2: 'Xác nhận',
        3: 'Xác nhận',
      };
      return title[step];
    }
    if (type === 'cancel') {
      const title = {
        4: 'Đóng',
      };
      return title[step] || 'Hủy';
    }
  };

  const isDisable = useMemo(() => {
    switch (step) {
      case 0:
        return !account;
      case 1:
        return (
          amount < MIN_AMOUNT || (type === 'withdrawal' && amount > MAX_AMOUNT)
        );
      case 2:
        return otp?.length < 6;
    }
  }, [account, step, amount, otp]);

  const handleBackStep = (step) => {
    if (step === 1) {
      setAmount(0);
    }
    if (step === 2) {
      setOtp('');
    }
    if (step === 3) {
      setStep(2);
      setOtp('');
    }
    backStep();
  };

  const handleClose = (step) => {
    if ([0, 4].includes(step)) {
      onClose();
      return;
    }
    confirm.warning({
      title: 'Xác nhận hủy giao dịch',
      content: 'Bạn có chắc muốn hủy giao dịch này không?',
      onOk: () => onClose(),
    });
  };

  useEffect(() => {
    setType(type as TTransaction);
    return () => clearTransaction();
  }, []);

  return (
    <Modal
      closeIcon={false}
      open={open}
      title={renderTitle(step)}
      width={step > 2 ? 447 : 630}
      bodyClassName="min-h-[50vh]"
      footerClassName="justify-center"
      footer={
        <>
          <Button
            type="alternative"
            className={classNames('w-full', {
              hidden: step <= 0 || step === 4,
            })}
            onClick={() => handleBackStep(step)}
          >
            Quay lại
          </Button>
          <Button
            type={step === 4 ? 'primary' : 'alternative'}
            className={step === 4 ? '' : 'w-full'}
            onClick={() => handleClose(step)}
          >
            {renderTitleBtn('cancel', step)}
          </Button>
          <Button
            className={classNames('w-full', {
              hidden: step >= 4,
            })}
            disabled={isDisable}
            onClick={() => elementRef.current?.nextStep()}
          >
            {renderTitleBtn('next', step)}
          </Button>
        </>
      }
      onCancel={() => handleClose(step)}
    >
      {renderContent(step)}
    </Modal>
  );
}

export default observer(WithDrawal);
