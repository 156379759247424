const UserIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={'1em'}
    height={'1em'}
    fill="none"
    {...props}
  >
    <path
      stroke="#374151"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.9 20.058v-2.092c0-1.11-.496-2.174-1.379-2.959-.883-.785-2.08-1.226-3.329-1.226H5.776c-1.248 0-2.446.441-3.329 1.226-.883.785-1.379 1.85-1.379 2.96v2.091M10.955 10.221c2.6 0 4.708-1.873 4.708-4.185 0-2.31-2.108-4.184-4.708-4.184S6.247 3.725 6.247 6.036c0 2.312 2.108 4.185 4.708 4.185Z"
    />
  </svg>
);
export default UserIcon;
