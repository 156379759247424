import { IRouteProps } from '_common/interface/router';
import Store from '.';
import { STORE_URL } from './constants/url';

export const StoreRouter: IRouteProps[] = [
  {
    key: STORE_URL.list.key,
    path: STORE_URL.list.shortenUrl,
    component: <Store />,
  },
  {
    key: STORE_URL.create.key,
    path: STORE_URL.create.shortenUrl,
    component: <Store />,
  },
  {
    key: STORE_URL.update.key,
    path: STORE_URL.update.shortenUrl,
    component: <Store />,
  },
  {
    key: STORE_URL.detail.key,
    path: STORE_URL.detail.shortenUrl,
    component: <Store />,
  },
];
