import { DATE_TIME_FORMAT } from '_common/constants/common';
import { copyText } from '_common/utils';
import {
  TransactionMethod,
  TransactionStatistic,
  TransactionStatus,
  TransactionStatusColor,
} from 'pages/Wallet/constants';
import bgInvoice from 'styles/images/wallet/bg-invoice.png';
import { ReactComponent as IconDollar } from 'styles/images/wallet/dollar.svg';
import { customTwMerge } from 'tailwind-merge.config';
import {
  DocumentDuplicateOutlined,
  formatCurrency,
  formatDate,
  LifebuoyOutlined,
  Tag,
  Tooltip,
} from 'tera-dls';

const textLight = 'text-gray-500 font-light';
const separate = 'pb-4 mb-4 border-b border-dashed';
export type TransactionContentType = 'recharge' | 'withdraw' | 'payment';

interface IProps {
  type: TransactionContentType;
  amount: number;
  transaction_date: string;
  transaction_code: string;
  payment_method: any;
  status?: any;
  account_number?: string;
  cardholder?: string;
  card_type?: any;
  card_name?: string;
  invoice_code?: string;
}
const TransactionContent = ({
  type,
  amount,
  transaction_date,
  transaction_code,
  payment_method,
  account_number,
  cardholder,
  card_type,
  card_name,
  invoice_code,
  status = 3,
}: IProps) => {
  return (
    <div className="rounded shadow-[0_4px_4px_0_rgba(0,0,0,0.1),0_-4px_4px_0_rgba(0,0,0,0.03)] w-full">
      <div
        className="p-4"
        style={{
          backgroundImage: `linear-gradient(rgba(255,255,255,0.95),rgba(255,255,255,0.95)),url(${bgInvoice})`,
          backgroundPosition: 'center',
        }}
      >
        <div className={customTwMerge('flex flex-col gap-y-3', separate)}>
          <div className="flex gap-x-2.5 items-center">
            <IconDollar />
            <div className="flex flex-col gap-y-[5px]">
              <span className={textLight}>{TransactionStatistic[type]}</span>
              <span className="text-green-500 font-bold">
                {formatCurrency(amount)}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-y-[7px]">
            <div className="flex justify-between items-center">
              <span className={textLight}>Trạng thái</span>
              <Tag color={TransactionStatusColor[status]}>
                {TransactionStatus[status]}
              </Tag>
            </div>
            <div className="flex justify-between items-center">
              <span className={textLight}>Thời gian</span>
              <span>{formatDate(transaction_date, DATE_TIME_FORMAT)}</span>
            </div>
          </div>
        </div>
        <div className={customTwMerge('flex flex-col gap-y-3', separate)}>
          <div className="flex flex-col gap-y-[7px]">
            <div className="flex justify-between items-center">
              <span className={textLight}>Mã giao dịch</span>
              <span className="flex gap-x-1 items-center">
                {transaction_code}
                <Tooltip placement="top" title="Sao chép">
                  <DocumentDuplicateOutlined
                    className="w-5 h-5 text-blue-600 shrink-0 cursor-pointer"
                    onClick={() => copyText(transaction_code)}
                  />
                </Tooltip>
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className={textLight}>Mã hóa đơn</span>
              <span className="flex gap-x-1 items-center">
                {invoice_code}
                <Tooltip placement="top" title="Sao chép">
                  <DocumentDuplicateOutlined
                    className="w-5 h-5 text-blue-600 shrink-0 cursor-pointer"
                    onClick={() => copyText(invoice_code)}
                  />
                </Tooltip>
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className={textLight}>Phương thức thanh toán</span>
              <span>{TransactionMethod[payment_method]}</span>
            </div>
          </div>
        </div>
        {type !== 'payment' && (
          <div className={customTwMerge('flex flex-col gap-y-3')}>
            <div className="flex flex-col gap-y-[7px]">
              {account_number && (
                <div className="flex justify-between items-center">
                  <span className={textLight}>Số tài khoản</span>
                  <span>{account_number}</span>
                </div>
              )}
              {cardholder && (
                <div className="flex justify-between items-center">
                  <span className={textLight}>Người thụ hưởng</span>
                  <span>{cardholder}</span>
                </div>
              )}
              {card_name && (
                <div className="flex justify-between items-center">
                  <span className={textLight}>
                    {card_type === 1 ? 'Ngân hàng' : 'Ví điện tử'}
                  </span>
                  <span>{card_name}</span>
                </div>
              )}
              <div className="flex justify-between items-center">
                <span className={textLight}>Nội dung</span>
                <span>
                  {type === 'recharge'
                    ? 'Nạp tiền vào ví Tera'
                    : `Rút tiền ví Tera`}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="py-2 flex gap-x-2.5 items-center bg-blue-100 justify-center">
        <span>
          <LifebuoyOutlined className="w-5 h-5 text-blue-500" />
        </span>
        <span className="text-blue-500 font-light hover:underline cursor-pointer">
          Liên hệ hỗ trợ
        </span>
      </div>
    </div>
  );
};

export default TransactionContent;
