export const STATUS_SERVICE_BUSINESS = {
  not_activated: 'Chưa kích hoạt',
  is_active: 'Hoạt động',
  expired: 'Hết hạn',
  finished: 'Kết thúc',
};

export const STATUS_SERVICE_BUSINESS_COLOR_TAG = {
  is_active: 'green03',
  expired: 'gray04',
  finished: 'red03',
};

export const STATUS_SERVICE_BUSINESS_COLOR = {
  is_active: 'text-green-400',
  expired: 'text-gray-300',
  finished: 'text-red-300',
};

export const PRODUCT_TIME = {
  1: '1 tháng',
  6: '6 tháng',
  12: '12 tháng',
  18: '18 tháng',
  24: '24 tháng',
};

export const PRODUCT_MEMORY_CAPACITY = {
  4: '4 GB',
  5: '5 GB',
  20: '20 GB',
  50: '50 GB',
  128: '128 GB',
  256: '256 GB',
  512: '512 GB',
  1024: '1 TB',
};
