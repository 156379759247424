import Search from '_common/dof/Control/Search';
import FormTera, { FormTeraItem, useFormTera } from '_common/dof/FormTera';
import _ from 'lodash';
import { useEffect } from 'react';
import { MagnifyingGlassOutlined } from 'tera-dls';

function Header({ onSearch, initialValue }) {
  const [formRef] = useFormTera();

  const handleSearch = (value) => {
    onSearch(value);
    formRef?.current?.reset({ ...value }, { keepValues: true });
  };

  useEffect(() => {
    if (initialValue) {
      const values = _.pick(initialValue, ['keyword']);

      formRef?.current?.reset({
        keyword: values?.keyword,
      });
    }
  }, [initialValue]);

  return (
    <FormTera ref={formRef} onSubmit={handleSearch}>
      <FormTeraItem className="!mb-0" name="keyword">
        <Search
          placeholder="Tìm kiếm theo mã giao dịch"
          icon={<MagnifyingGlassOutlined />}
        />
      </FormTeraItem>
    </FormTera>
  );
}

export default Header;
