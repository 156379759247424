import { PRODUCT_MEMORY_CAPACITY } from 'pages/ServicePackage/constants';
import { forwardRef } from 'react';
import { customTwMerge } from 'tailwind-merge.config';
import { formatCurrency } from 'tera-dls';

interface IProps {
  onSelect?: (id: number) => void;
  selected?: boolean;
  value: any;
  usedModules?: any;
  modules?: any;
}

const CheckedIcon = (props) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="8.5" cy="7" r="6" fill="#1C64F2" />
    <path
      d="M11.5 5L7.375 9L5.5 7.18182"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const UnCheckedIcon = (props) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="8.5" cy="7" r="6" fill="#F8B4D9" />
    <path
      d="M10.7415 9.5404L6.25781 5.10156"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M6.26412 9.54729L10.7422 5.10156"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);

const Card = (
  { onSelect, selected, value, usedModules = [], modules = [] }: IProps,
  ref,
) => {
  return (
    <div
      ref={ref}
      onClick={() => {
        if (!onSelect) return;
        !selected ? onSelect(value?.id) : onSelect(undefined);
      }}
      className={customTwMerge(
        'py-[16px] flex flex-col min-w-[239px] max-w-[239px] bg-white rounded-[8px] text-gray-700 cursor-pointer hover:bg-blue-200',
        selected ? 'bg-blue-200' : '',
      )}
    >
      <div className="flex flex-col px-[37px]">
        <div className="flex flex-col border-b-[1px] border-gray-200 pb-[16px] gap-1">
          <span className="font-bold text-[20px] leading-[24px] text-center">
            {value?.name}
          </span>
          <span className="font-semibold text-[25px] leading-[30px] text-center text-blue-600 break-word">
            {formatCurrency(value?.price ?? 0)}
          </span>
          <span className="font-normal text-[14px] leading-[17px] text-[#7C7C7C] text-center">
            / {value?.time} Tháng
          </span>
        </div>
        <div className="flex flex-col justify-center gap-2.5 pt-[16px] border-b-[1px] pb-[16px]">
          <div className="text-center">
            <span className="font-bold">{value?.quantity_user}</span> người sử
            dụng
          </div>
          <div className="text-center">
            <span className="font-bold">
              {PRODUCT_MEMORY_CAPACITY[value?.quantity_capacity]}
            </span>{' '}
            dung lượng
          </div>
          <div className="text-center">
            <span className="font-bold">{value?.quantity_order}</span> đơn hàng
          </div>
        </div>
      </div>
      <div className="pt-[16px] flex flex-col gap-[16px]">
        <div className="bg-[#E8E8E8] text-[12px] leading-[16px] text-center py-[3px]">
          Tất cả chức năng
        </div>
        <div className="h-[230px] overflow-auto w-full px-[37px] text-[11px] flex flex-col leading-[13px] gap-[16px]">
          {usedModules?.map((item) => (
            <span className="flex gap-[6px] items-center">
              <CheckedIcon className="w-5 text-white fill-blue-500" />
              {item?.title}
            </span>
          ))}
          {modules?.map((item) => (
            <span className="flex gap-[6px] items-center">
              <UnCheckedIcon className="w-5 text-white fill-pink-300" />
              {item?.title}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Card);
