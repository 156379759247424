import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const PortalEndpoint = `${endpoint}/portal`;

const PortalApi = {
  getListModule: async (param?) =>
    await api
      .get(`${PortalEndpoint}/role/list-module`, param)
      .then((result) => result?.data?.data),
  getPermissionGroupList: async ({ params }) =>
    await api
      .get(`${PortalEndpoint}/role/list`, params)
      .then((result) => result.data?.data),
};

export default PortalApi;
