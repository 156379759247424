import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import InvoiceApi from 'pages/Invoice/api';
import WalletApi from 'pages/Wallet/_api';
import { useEffect } from 'react';
import { formatCurrency, Modal, notification, Spin } from 'tera-dls';

interface IProps {
  open: boolean;
  onClose?: () => void;
  invoiceId: any;
  onSuccess?: (id: number) => void;
  onError?: () => void;
}

const PaymentWalletModal = (props: IProps) => {
  const { onClose, open, invoiceId, onSuccess, onError } = props;
  const queryClient = useQueryClient();

  const {
    data: response,
    refetch,
    isLoading: loadingDetail,
  } = useQuery(
    ['get-invoice-detail', invoiceId],
    () =>
      InvoiceApi.getDetail({
        id: invoiceId,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    invoiceId && refetch();
  }, [invoiceId]);

  useEffect(() => {
    return () => {
      onClose && onClose();
    };
  }, []);

  const {
    data: amountData,
    refetch: refetchAmount,
    isLoading: loadingAmount,
  } = useQuery(['get-wallet-amount'], () => WalletApi.getAmount(), {
    staleTime: 300000,
    cacheTime: 300000,
  });

  useEffect(() => {
    refetchAmount();
  }, []);

  const { mutate: mutatePay } = useMutation(
    (variables: any) => InvoiceApi.pay(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-invoice-detail']);
          queryClient.invalidateQueries(['get-invoice-list']);
          onSuccess && onSuccess(res?.data?.id);
          onClose && onClose();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        if (error?.data?.code == 500) {
          queryClient.invalidateQueries(['get-invoice-list']);
          queryClient.invalidateQueries(['get-invoice-detail']);
          queryClient.invalidateQueries(['get-wallet-amount']);
          onError && onError();
          onClose();
        }
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitPayment = () =>
    mutatePay({ params: { bill_id: invoiceId } });

  const data = [
    {
      key: 1,
      title: 'Mã hóa đơn:',
      value: response?.code,
    },
    {
      key: 2,
      title: 'Loại giao dịch:',
      value: 'Thanh toán',
    },
    {
      key: 3,
      title: 'Phương thức thanh toán:',
      value: (
        <div className="  flex flex-col gap-[5px] items-end">
          <div>Ví Tera</div>
          <div className="flex gap-[5px] items-center leading-[16px]">
            <span className="text-gray-500 font-light">Số dư ví</span>
            <span className="text-green-500 font-medium">
              {formatCurrency(amountData?.availability_amount ?? 0)}
            </span>
          </div>
        </div>
      ),
    },
    {
      key: 4,
      title: 'Số tiền:',
      value: formatCurrency(response?.total_amount ?? 0),
    },
  ];
  return (
    <>
      <Modal
        title={'Xác nhận thanh toán'}
        destroyOnClose
        closeIcon={false}
        onCancel={onClose}
        width={450}
        maskClosable={false}
        open={open}
        okText={'Xác nhận'}
        cancelText="Đóng"
        onOk={handleSubmitPayment}
        centered={true}
      >
        <Spin spinning={loadingAmount || loadingDetail}>
          <div className="!text-sm flex flex-col gap-[20px] py-2.5 h-[500px]">
            {data?.map((item) => (
              <div className="flex justify-between">
                <span className=" text-gray-500">{item.title}</span>
                <span className="text-gray-950">{item?.value}</span>
              </div>
            ))}
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default PaymentWalletModal;
