import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PaginationCustom from '_common/component/PaginationCustom';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { messageError } from '_common/constants/message';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { useState } from 'react';
import LocationApi from 'states/api/location';
import {
  Badge,
  Button,
  DropdownItem,
  PlusCircleOutlined,
  TabItemType,
  Tabs,
  Tag,
  notification,
} from 'tera-dls';
import { STATUS_LOCATION, StoreStatus, StoreStatusColor } from './constants';
import DetailStore from './containers/Detail';
import StoreFilter from './containers/Filter';
import FormStore from './containers/Form';
import SearchStore from './containers/Search';

function Store() {
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const [idLocation, setIdLocation] = useState<number>(null);
  const queryClient = useQueryClient();

  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
  });
  const [params, setParams] = useState({
    keyword: '',
    status: 'all',
  });
  const [filter, setFilter] = useState({
    status: null,
  });

  const confirm = useConfirm();

  const { data: listLocation, isLoading } = useQuery(
    ['get-list-location', params, pagination, filter],
    () => {
      const data = {
        ...params,
        ...pagination,
        ...filter,
        view_all: 1,
        status: params?.status === 'all' ? '' : params?.status,
      };
      return LocationApi.getList({ params: data });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const { mutate: deleteStore } = useMutation(
    (id: number) => LocationApi.delete({ id }),
    {
      onSuccess(res) {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-list-location']);
        }
      },
      onError(error: any) {
        const errorMessage = error?.data?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  const handleDelete = (item) => {
    confirm.warning({
      title: 'XÓA CẤU HÌNH CỬA HÀNG',
      content: (
        <>
          <p>Bạn có chắc muốn xóa cấu hình cửa hàng</p>
          <p>
            <strong>{item?.name}</strong> này không?
          </p>
        </>
      ),
      onOk: () => {
        deleteStore(item?.id);
      },
    });
  };

  const handleSearch = (values) => {
    setParams(values);
  };
  const handleFilter = (values) => {
    setFilter(values);
  };

  const handleChangePagination = (page, limit) => {
    setPagination({ ...pagination, limit, page });
  };

  const handleChangeTab = (key) => {
    setParams({ ...params, status: key });
  };

  const renderItemActions = (item: any): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];
    dropdownItems.push({
      key: 1,
      label: 'Xem',
      onClick: () => {
        setIdLocation(item?.id);
        setIsOpenDetail(true);
      },
    });
    dropdownItems.push({
      key: 2,
      label: 'Chỉnh sửa',
      onClick: () => {
        setIdLocation(item?.id);
        setIsOpenForm(true);
      },
    });
    dropdownItems.push({
      key: 3,
      label: <span className="text-red-500">Xóa</span>,
      onClick: () => handleDelete(item),
    });
    return dropdownItems;
  };
  const columns: any = [
    {
      title: 'STT',
      dataIndex: 'record_number',
      width: 60,
    },
    {
      title: 'Mã cửa hàng',
      dataIndex: 'location_id',
      width: 200,
    },

    {
      title: 'Tên cửa hàng',
      dataIndex: 'name',
      width: 200,
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'landmark',
      width: 200,
    },
    {
      title: 'Số điện thoại ',
      dataIndex: 'mobile',
      width: 200,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'is_active',
      width: 200,
      render: (is_active) => {
        return (
          <Tag color={StoreStatusColor[is_active]}>
            {StoreStatus[is_active]}
          </Tag>
        );
      },
    },
    {
      width: 60,
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  const itemsTab: TabItemType[] = STATUS_LOCATION.map((item) => {
    const count = listLocation?.summary?.find(
      (i) => String(i?.status) === String(item?.key),
    )?.total_count;

    return {
      key: item?.key?.toString(),
      label: (
        <h3 className="tab-table">
          <span>{item?.title}</span>
          <Badge showZero count={count || 0} />
        </h3>
      ),
    };
  });

  return (
    <div className="flex flex-col gap-y-4 p-5">
      <h1 className="portal-heading">Cửa hàng</h1>
      <div className="flex justify-between gap-x-2.5">
        <SearchStore onSearch={handleSearch} />

        <Button
          onClick={() => setIsOpenForm(true)}
          className="rounded-full shrink-0 bg-blue-400"
        >
          <div className="flex items-center gap-1 shrink-0">
            <PlusCircleOutlined className="w-5 h-5" />
            <span>Thêm mới</span>
          </div>
        </Button>
      </div>
      <div className="bg-white shadow-xsm rounded-[5px] overflow-hidden h-full">
        <Tabs
          items={itemsTab}
          className="mb-0"
          onChange={handleChangeTab}
          activeKey={params?.status}
        />
        <TableTera
          columns={columns}
          loading={isLoading}
          data={listLocation?.data?.data || []}
        />
        {listLocation?.data?.total > 0 && (
          <PaginationCustom
            from={listLocation?.data?.from}
            to={listLocation?.data?.to}
            current={listLocation?.data?.current_page}
            pageSize={listLocation?.data?.per_page}
            total={listLocation?.data?.total}
            onChange={handleChangePagination}
          />
        )}
      </div>

      {isOpenForm && (
        <FormStore
          open={isOpenForm}
          id={idLocation}
          onCancel={() => {
            setIsOpenForm(false);
            setIdLocation(null);
          }}
          onRefetch={() => {
            queryClient.invalidateQueries(['get-list-location']);
          }}
        />
      )}
      {isOpenDetail && (
        <DetailStore
          open={isOpenDetail}
          id={idLocation}
          onCancel={() => {
            setIdLocation(null);
            setIsOpenDetail(false);
          }}
        />
      )}
      {isOpenFilter && (
        <StoreFilter
          open={isOpenFilter}
          initialValue={filter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
        />
      )}
    </div>
  );
}

export default Store;
