import classNames from 'classnames';
import {
  containerClassName,
  titleClassName,
  viewMoreClassName,
} from 'pages/Dashboard/constants';
import { mockDataNews } from 'pages/Dashboard/mockData';
import { SERVICE_PACKAGE_URL } from 'pages/ServicePackage/constants/url';
import { useNavigate } from 'react-router-dom';
import { ChevronRightSolid, WalletSolid } from 'tera-dls';

function ServicePackage() {
  const data = mockDataNews.slice(0, 6);
  const navigate = useNavigate();
  return (
    <div className={classNames('bg-gray-100', containerClassName)}>
      <div className="flex justify-between items-center">
        <h2 className={titleClassName}>Giới thiệu gói dịch vụ</h2>
        <span
          className={viewMoreClassName}
          onClick={() => navigate(SERVICE_PACKAGE_URL.list.path)}
        >
          Xem thêm
        </span>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-[50px] mt-10">
        {data.map((item) => (
          <div className="rounded-[10px] bg-transparent relative group cursor-pointer hover:-translate-y-[5px] transition-all duration-400 hover:shadow-[0_4px_4px_0_rgba(0,0,0,0.1),0_-4px_4px_0_rgba(0,0,0,0.03)]">
            <div className="absolute bg-white top-0 left-1/2 -translate-y-1/2 -translate-x-1/2 flex w-[50px] h-[50px] rounded-[6px] shadow-[0_4px_4px_0_rgba(0,0,0,0.1),0_-4px_4px_0_rgba(0,0,0,0.03)]">
              <WalletSolid className="w-7 h-7 text-blue-500 m-auto" />
            </div>
            <div className="flex flex-col gap-5 justify-between items-center w-full h-full p-4 pt-[45px] bg-white rounded-[10px]">
              <div className="text-center">
                <p className="font-medium line-clamp-1 break-word group-hover:text-blue-500">
                  {item.title}
                </p>
                <p className="text-[10px] text-gray-500 line-clamp-3 break-word mt-2.5">
                  {item.description}
                </p>
              </div>
              <div className="flex w-[30px] h-[30px] rounded-full cursor-pointer shadow-[0_4px_4px_0_rgba(0,0,0,0.05),0_-4px_4px_0_rgba(0,0,0,0.05)]">
                <ChevronRightSolid className="text-gray-500 w-6 h-6 m-auto group-hover:text-blue-500" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServicePackage;
