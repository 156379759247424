const GoogleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#FFC107"
      d="M19.874 8.495h-.806v-.042h-9v4h5.652a5.998 5.998 0 0 1-11.652-2 6 6 0 0 1 6-6c1.53 0 2.921.577 3.98 1.52l2.83-2.829a9.954 9.954 0 0 0-6.81-2.69c-5.522 0-10 4.477-10 10 0 5.522 4.478 10 10 10 5.523 0 10-4.478 10-10 0-.671-.069-1.326-.194-1.96Z"
    />
    <path
      fill="#FF3D00"
      d="m1.222 5.799 3.285 2.41a5.997 5.997 0 0 1 5.562-3.756c1.53 0 2.92.577 3.98 1.52l2.829-2.829a9.954 9.954 0 0 0-6.81-2.69 9.994 9.994 0 0 0-8.846 5.345Z"
    />
    <path
      fill="#4CAF50"
      d="M10.069 20.45c2.582 0 4.93-.989 6.704-2.596l-3.095-2.62a5.955 5.955 0 0 1-3.61 1.216 5.997 5.997 0 0 1-5.641-3.973l-3.261 2.512c1.655 3.239 5.016 5.46 8.902 5.46Z"
    />
    <path
      fill="#1976D2"
      d="M19.874 8.495h-.806v-.042h-9v4h5.652a6.02 6.02 0 0 1-2.044 2.786l.002-.001 3.095 2.619c-.22.199 3.295-2.404 3.295-7.404 0-.67-.069-1.325-.194-1.958Z"
    />
  </svg>
);
export default GoogleIcon;
