import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import moment from 'moment';
import { INVOICE_STATUS, INVOICE_STATUS_COLOR } from 'pages/Invoice/constants';
import { TransactionType } from 'pages/Wallet/constants';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DropdownItem, formatCurrency, Tag } from 'tera-dls';

const InvoiceTable = ({ data, ...restProps }) => {
  const navigate = useNavigate();

  const renderItemActions = (item: any): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];

    dropdownItems.push({
      key: 1,
      label: 'Xem',
      onClick: () => {
        navigate(`/invoice/detail/${item?.id}`);
      },
    });
    dropdownItems.push({
      key: 2,
      label: 'In hóa đơn',
      onClick: () => {
        1;
      },
    });

    return dropdownItems;
  };

  const columns = [
    {
      title: 'Mã hóa đơn',
      dataIndex: 'code',
      width: 150,
      render: (val, record) => (
        <span
          className="line-clamp-1 text-blue-500 cursor-pointer inline-block"
          onClick={() => navigate(`/invoice/detail/${record?.id}`)}
        >
          {val}
        </span>
      ),
    },
    {
      dataIndex: 'transaction_type',
      title: 'Loại giao dịch',
      width: 150,
      render: (val) => TransactionType[val],
    },
    {
      dataIndex: 'method',
      title: 'Khách hàng',
      width: 150,
      render: (_, record) => (
        <HoverQuickView
          // avatarUrl={value?.avatar_url}
          email={record?.customer_email}
          name={record?.customer_name}
          phone={record?.customer_phone}
          className="line-clamp-2"
        >
          {record?.customer_name}
        </HoverQuickView>
      ),
    },
    {
      dataIndex: 'total_amount',
      title: 'Tổng giá trị',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      dataIndex: 'methods_text',
      title: 'Phương thức thanh toán',
      width: 150,
    },
    {
      dataIndex: 'status',
      title: 'Trạng thái',
      width: 150,
      render: (val) => (
        <Tag color={INVOICE_STATUS_COLOR[val]}>{INVOICE_STATUS[val]}</Tag>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 150,
      render: (val) => val && moment(val).format(DATE_TIME_FORMAT),
    },
    {
      dataIndex: 'action',
      title: '',
      width: 50,
      render: (_, record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];
  return <TableTera {...restProps} data={data} columns={columns} />;
};

export default InvoiceTable;
