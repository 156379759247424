import React from 'react';
import {
  EllipsisHorizontalOutlined,
  HomeModernOutlined,
  HomeOutlined,
} from 'tera-dls';

import { Menu, SubMenu, TypeMoreMenu } from './interface';
import { Link } from 'react-router-dom';

export const menus: Menu[] = [
  {
    icon: <HomeOutlined />,
    title: <Link to="/dashboard">Dashboard</Link>,
    key: 'home',
    isDisplay: true,
  },
  {
    icon: <HomeModernOutlined />,
    title: <Link to="/dashboard">Page</Link>,
    key: 'dashboard',
    isDisplay: true,
  },
];

export const subMenus: SubMenu = {
  home: [
    {
      icon: <HomeOutlined />,
      title: <Link to="/dashboard">Dashboard 1 </Link>,
      key: '1',
    },
    {
      icon: <HomeModernOutlined />,
      title: <Link to="/page2">Dashboard 2</Link>,
      key: '2',
    },
  ],
  dashboard: [
    {
      icon: <HomeOutlined />,
      title: <Link to="/dashboard">Page 1 </Link>,
      key: '1',
    },
    {
      icon: <HomeModernOutlined />,
      title: <Link to="/page2">Page 2</Link>,
      key: '2',
    },
  ],
};

export const moreMenu: TypeMoreMenu[] = [
  {
    icon: <HomeModernOutlined />,
    title: <a href="#">Dashboard</a>,
    key: 'dashboard1',
    img: { src: 'https://via.placeholder.com/1280x720' },
  },
  {
    icon: <EllipsisHorizontalOutlined />,
    title: 'More',
    key: 'home',
    img: { src: 'https://via.placeholder.com/1280x720' },
  },
];
