import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { BrowserRouter } from 'react-router-dom';
import { AuthApi } from 'states/api';

import { useStores } from '_common/hooks';

import { Routers } from './routers';
import { BusinessApi } from 'pages/BusinessInformation/api';

const basename = document.querySelector('base')?.getAttribute('href') ?? '/';

const Root = observer(() => {
  const { authStore } = useStores();

  useQuery(['get_device'], AuthApi.getDeviceCode, {
    staleTime: 300000,
    onSuccess: (data) => {
      authStore.setInitData(data);
    },
  });

  useQuery(['get_profile'], AuthApi.getProfile, {
    staleTime: 300000,
    enabled: !!authStore.token,
    onSuccess: (res) => {
      authStore.updateUser({ user: res?.data });
    },
  });

  useQuery(['get_business_detail'], BusinessApi.getProfile, {
    staleTime: 300000,
    enabled: !!authStore.token,
    onSuccess: (res) => {
      authStore.updateBusiness(res);
    },
  });

  useQuery(['get_epics'], () => AuthApi.getEpic({ module: 'admin' }), {
    staleTime: 300000,
    enabled: !!authStore.token,
    onSuccess: (res) => {
      authStore.updateEpic(res?.data);
    },
  });

  useQuery(['get_permissions'], AuthApi.getPermissions, {
    staleTime: 300000,
    enabled: !!authStore.token,
    onSuccess: (res) => {
      authStore.updatePermissions(res?.data);
    },
  });

  return (
    <BrowserRouter basename={basename}>
      <Routers />
    </BrowserRouter>
  );
});

export default Root;
