import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import CheckBox from '_common/dof/Control/CheckBox';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useStores } from '_common/hooks';
import _ from 'lodash';
import CartApi from 'pages/Cart/api';
import InvoiceApi from 'pages/Invoice/api';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, Col, Row } from 'tera-dls';
import PaymentInformation from './containers/PaymentInformation';
import PaymentMethod from './containers/PaymentMethod';
import useTriggerQuickPage from 'pages/Cart/Hooks/useTriggerQuickPage';
import { CART_URL } from 'pages/Cart/url';
import { PAYMENT_URL } from './url';

const Payment = () => {
  const form = useForm({ mode: 'onChange' });
  const {
    authStore: { user },
    cartStore: { cart_ids, type, cart, clear },
  } = useStores();
  const { business } = user;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useTriggerQuickPage((nextPathname) => {
    if (nextPathname.includes(PAYMENT_URL) || nextPathname.includes(CART_URL))
      return;
    clear();
  });
  const isActiveType = type === 'active';

  const { data: payment } = useQuery(
    ['get-cart-payment', cart_ids, cart, isActiveType],
    () =>
      CartApi.getPayment({
        params: {
          ...(isActiveType
            ? { item_selected: cart_ids }
            : {
                items: [
                  {
                    ...cart,
                  },
                ],
              }),
        },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateCreate } = useMutation(
    (variables: any) => InvoiceApi.create(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-cart-total']);
          if (res?.data?.id) {
            if (form.watch('methods') === '1') {
              navigate(`/invoice/detail/${res?.data?.id}`, {
                state: { type: 'created_invoice_transfer' },
              });
            } else {
              navigate(`/invoice/detail/${res?.data?.id}`, {
                state: { type: 'created_invoice_wallet' },
              });
            }
          }
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
        navigate(CART_URL);
        clear();
      },
    },
  );

  const handleSubmit = (values) => {
    const data = _.pick(values, [
      'export_bill',
      'number_tax',
      'company_name',
      'address',
      'email_receive',
    ]);
    if (isActiveType) {
      mutateCreate({
        ...data,
        export_bill: data?.export_bill ? 1 : 0,
        selected_items: cart_ids,
        type,
      });
      return;
    }
    mutateCreate({
      ...data,
      export_bill: data?.export_bill ? 1 : 0,
      type,
      items: [
        {
          ...cart,
        },
      ],
    });
  };

  useEffect(() => {
    if (!business) return;
    form.reset(
      {
        name: business?.owner_name,
        email: business?.owner_email,
        phone: business?.owner_phone,
        methods: '1',
        export_bill: false,
        rule: true,
      },
      { keepDirty: false },
    );
  }, [business]);

  const handleRedirect = () => {
    navigate(-1);
  };

  return (
    <div className="p-2.5 flex flex-col gap-[15px]">
      <div
        className="flex gap-2 items-center cursor-pointer"
        onClick={handleRedirect}
      >
        <ArrowLeftOutlined className="w-4" />
        <span className="italic text-xs space-x-1 text-[#76A9FA]">
          Quay lại
        </span>
      </div>
      <FormTera form={form} onSubmit={handleSubmit}>
        <div className="flex flex-col gap-[16px]">
          <div className="font-medium text-[22px] leading-[26px]">
            Thông tin thanh toán
          </div>
          <Row className="grid grid-cols-3 gap-[32px]">
            <Col className="col-span-2 ">
              <div className="border border-gray-100 rounded-[10px] flex flex-col gap-[16px] shadow-md p-[16px]">
                <div className="rounded-[6px] shadow-md">
                  <div className="p-2.5 font-semibold text-sm bg-[#FAFAF9]">
                    Thông tin người mua hàng
                  </div>
                  <div className="p-2.5">
                    <FormTeraItem
                      name="name"
                      label="Họ và tên"
                      layout="inline"
                      labelClassName="w-[150px]"
                    >
                      <Input disabled />
                    </FormTeraItem>
                    <FormTeraItem
                      name="email"
                      label="Email"
                      layout="inline"
                      labelClassName="w-[150px]"
                    >
                      <Input disabled />
                    </FormTeraItem>
                    <FormTeraItem
                      name="phone"
                      label="Số điện thoại"
                      layout="inline"
                      labelClassName="w-[150px]"
                    >
                      <Input disabled />
                    </FormTeraItem>
                    <FormTeraItem
                      name="export_bill"
                      label="Xuất hóa đơn"
                      layout="inline"
                      labelClassName="w-[150px]"
                    >
                      <CheckBox />
                    </FormTeraItem>
                  </div>
                </div>
                {form.watch('export_bill') && (
                  <div className="rounded-[6px] shadow-md">
                    <div className="p-2.5 font-semibold text-sm bg-[#FAFAF9]">
                      Thông tin nhận hoá đơn
                    </div>
                    <div className="p-2.5">
                      <FormTeraItem
                        name="number_tax"
                        label="Mã số thuế"
                        layout="inline"
                        labelClassName="w-[150px]"
                        rules={[{ required: messageValidate.emptyText }]}
                      >
                        <Input maxLength={100} />
                      </FormTeraItem>
                      <FormTeraItem
                        name="company_name"
                        label="Tên công ty"
                        layout="inline"
                        labelClassName="w-[150px]"
                        rules={[{ required: messageValidate.emptyText }]}
                      >
                        <Input maxLength={100} />
                      </FormTeraItem>
                      <FormTeraItem
                        name="address"
                        label="Địa chỉ đây đủ"
                        layout="inline"
                        labelClassName="w-[150px]"
                        rules={[{ required: messageValidate.emptyText }]}
                      >
                        <Input maxLength={255} />
                      </FormTeraItem>
                      <FormTeraItem
                        name="email_receive"
                        label="Email nhận hóa đơn"
                        layout="inline"
                        labelClassName="w-[150px] whitespace-break-spaces"
                        rules={[
                          {
                            pattern: {
                              value: REGEX.EMAIL,
                              message: messageValidate.email,
                            },
                          },
                          {
                            required: messageValidate.emptyText,
                          },
                        ]}
                        isRequired={false}
                      >
                        <Input maxLength={320} />
                      </FormTeraItem>
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col className="col-span-1 flex flex-col gap-[32px]">
              <PaymentInformation value={payment} key={payment} />
              <PaymentMethod form={form} totalAmount={payment?.total_amount} />
            </Col>
          </Row>
        </div>
      </FormTera>
    </div>
  );
};

export default Payment;
