import SuccessAffiliate from 'styles/images/dashboard/bg_success_affiliate.png';
import Hands from 'styles/images/dashboard/hands.png';

import { Button, Modal } from 'tera-dls';

interface IProps {
  open: boolean;
  onClose?: () => void;
}
const SuccessModal = (props: IProps) => {
  const { onClose, open } = props;

  return (
    <Modal
      title={''}
      destroyOnClose
      closeIcon={false}
      onCancel={onClose}
      width={630}
      maskClosable={false}
      open={open}
      centered={true}
      modalRender={() => (
        <div
          className="bg-none gap-[50px] justify-end text-white rounded-[32px] p-[30px] flex flex-col items-center h-[700px]"
          style={{
            backgroundImage: `url(${SuccessAffiliate})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'center',
            backgroundPosition: 'center top',
          }}
        >
          <div />
          <div className="flex flex-col gap-2.5 items-center">
            <img src={Hands} className="w-[209px] h-auto" />
            <div className=" text-gray-800 text-center text-[30px] leading-[42px] font-semibold">
              <p>Bạn đã đăng ký</p>
              <p>AFFILIATES thành công!</p>
            </div>
          </div>
          <div className="mb-[50px]">
            <Button
              onClick={onClose}
              className="rounded-full py-[8px] w-[100px] text-base"
            >
              Đóng
            </Button>
          </div>
        </div>
      )}
    />
  );
};

export default SuccessModal;
