import { Drawer, DrawerProps } from 'tera-dls';
import ActivityHistory from './ActivityHistory';
import HeaderRightBar from './HeaderRightBar';

function DrawerSide({ open, onClose }: DrawerProps) {
  return (
    <Drawer open={open} onClose={onClose} containerClassName="p-0">
      <div className="flex flex-col bg-[#F3F3F9] h-screen overflow-hidden">
        <HeaderRightBar open={open} />
        <ActivityHistory open={open} />
      </div>
    </Drawer>
  );
}

export default DrawerSide;
