import React from 'react';
import { observer } from 'mobx-react-lite';

import DesktopLayout from './DesktopLayout';

const BasicLayout = observer(() => {
  return (
    <>
      <DesktopLayout />
    </>
  );
});

export default BasicLayout;
