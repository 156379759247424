import { useQuery } from '@tanstack/react-query';
import { webClientUrl } from '_common/constants/common';
import classNames from 'classnames';
import moment from 'moment';
import ModuleApi from 'pages/Dashboard/_api';
import {
  containerClassName,
  titleClassName,
  viewMoreClassName,
} from 'pages/Dashboard/constants';
import { Button, Col, Row } from 'tera-dls';

function News() {
  const handleRedirectLink = (link) => {
    if (!link) return;
    window.open(`${webClientUrl}/${link}`);
  };

  const { data: response } = useQuery(
    ['get-dashboard-footer-list'],
    () =>
      ModuleApi.getNews({
        params: { page: 1, limit: 5 },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );
  const data = response?.data;
  const main = data?.[0];
  const rest = data?.filter((_, index) => index !== 0);

  return (
    <Row className="grid-cols-12 gap-[30px]">
      <Col className="col-span-12 xmd:col-span-6 xl:col-span-4 grid grid-cols-3 xmd:flex xmd:flex-col gap-4 xmd:gap-10">
        <div className="relative">
          <div className="w-full h-full  rounded-[16px] xmd:rounded-[30px] overflow-hidden">
            <img
              className="w-full h-full hover:scale-110 duration-300 object-cover"
              src={main?.files?.[0]?.file_url ?? ''}
            />
          </div>
          <span className="px-[32px] py-[9px] bg-[#2ECC71] text-white absolute top-[5%] left-[5%]">
            Mới
          </span>
        </div>
        <div className="col-span-2 flex flex-col gap-2.5">
          <h4 className="text-gray-500 text-[12px] line-clamp-1">
            {main?.created_by?.full_name} -{' '}
            {moment(main?.created_at, 'DD/MM/YYYY - HH:mm').format(
              'DD MMM YYYY',
            )}
          </h4>
          <h2
            className="font-medium text-[18px] leading-[27px] line-clamp-2 cursor-pointer hover:text-blue-600"
            onClick={() => handleRedirectLink(main?.url_client)}
          >
            {main?.title}
          </h2>
          <p className="text-[14px] leading-[16px] line-clamp-4">
            {main?.description}
          </p>
        </div>
      </Col>
      <Col
        className={classNames(
          'col-span-12 xmd:col-span-6 xl:col-span-8 bg-gray-100 footer flex flex-col gap-y-4',
          containerClassName,
        )}
      >
        <div className="flex justify-between items-center">
          <h2 className={titleClassName}>Tin mới</h2>
          <a
            href="https://terasolutions.vn/tin-tuc/giai-phap-crm"
            target="_blank"
            className={viewMoreClassName}
          >
            Xem thêm
          </a>
        </div>
        <div className="max-h-[460px] overflow-y-auto overflow-x-hidden flex flex-col gap-[17px]">
          {rest?.map((item) => (
            <div
              key={item?.id}
              className="card_new rounded-[6px] p-[16px] pr-[0px] flex items-center gap-[14px] bg-blue-50 duration-200 hover:bg-blue-300"
            >
              <img
                className="w-[90px] h-[70px] rounded-[6px] mr-[16px] object-cover"
                src={item.files?.[0]?.file_url ?? ''}
                alt={item.files?.[0]?.file_url ?? ''}
              />
              <div className="flex flex-col gap-[6px] break-word flex-1">
                <h4 className="text-xs line-clamp-1">
                  {item.created_by?.full_name} -{' '}
                  <span className="text-gray-500">
                    {moment(item?.created_at, 'DD/MM/YYYY - HH:mm').format(
                      'DD MMM YYYY',
                    )}
                  </span>
                </h4>
                <h2 className="text-[15px] leading-[18px] line-clamp-2">
                  {item.title}
                </h2>
              </div>
              <div className="w-[105px] h-full overflow-hidden shrink-0 flex items-center">
                <Button
                  onClick={() => handleRedirectLink(item?.url_client)}
                  className="button-read text-gray-600 bg-yellow-300 hover:bg-yellow-400 h-9 w-[105px] rounded-[9999px_0px_0px_9999px]"
                >
                  Đọc
                </Button>
              </div>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
}

export default News;
