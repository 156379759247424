import classNames from 'classnames';
import React, { useState } from 'react';
import { ChevronLeftSolid, ChevronRightSolid } from 'tera-dls';
import HeaderRightBar from './HeaderRightBar';
import ActivityHistory from './ActivityHistory';

function SideBar() {
  const [open, setOpen] = useState<boolean>(true);

  return (
    <div
      className={classNames(
        'h-full bg-white relative transition-all duration-500',
        {
          'w-[354px]': open,
          'w-[120px]': !open,
        },
      )}
    >
      <div
        onClick={() => setOpen(!open)}
        className="flex w-[30px] h-[30px] absolute left-0 top-1/2 bg-white -translate-y-1/2 -translate-x-1/2 rounded-full cursor-pointer shadow-[0_4px_4px_0_rgba(0,0,0,0.05),0_-4px_4px_0_rgba(0,0,0,0.03)]"
      >
        {open ? (
          <ChevronRightSolid className="w-6 h-6 m-auto" />
        ) : (
          <ChevronLeftSolid className="w-6 h-6 m-auto" />
        )}
      </div>
      <div className="flex flex-col bg-[#F3F3F9] h-screen overflow-hidden rounded-l-[30px] shadow-[-4px_0_10px_0_rgba(0,0,0,0.1)]">
        <HeaderRightBar open={open} onOpen={() => setOpen(true)} />
        <ActivityHistory open={open} />
      </div>
    </div>
  );
}

export default SideBar;
