export const PERMISSION_KEY = {
  WALLET_VIEW_LIST: 'portal_wallet_view_wallet_list',
  WALLET_RECHARGE: 'portal_wallet_recharge',
  WALLET_WITHDRAWAL: 'portal_wallet_withdrawal',
  WALLET_VIEW_CARD_BANK_LIST: 'portal_wallet_view_card_bank_list',
  WALLET_CREATE_CARD_BANK: 'portal_wallet_create_card_bank',
  WALLET_UPDATE_CARD_BANK: 'portal_wallet_update_card_bank',
  WALLET_VIEW_DETAIL_CARD_BANK: 'portal_wallet_view_detail_card_bank',
  WALLET_DELETE_CARD_BANK: 'portal_wallet_delete_card_bank',
  WALLET_SEARCH_CARD_BANK: 'portal_wallet_search_card_bank',
};
