import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageValidate, messageWarning } from '_common/constants/message';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectPermissionGroup from '_common/dof/Select/SelectPermissionGroup';
import useConfirm from '_common/hooks/useConfirm';
import { MemberApi } from 'pages/EmployeeInformation/api';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification } from 'tera-dls';

interface IProps {
  id: number;
  open: boolean;
  onClose?: () => void;
  onSuccess?: (value: any) => void;
}

const Permission = (props: IProps) => {
  const { open, id, onSuccess, onClose } = props;
  onSuccess;
  id;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const isDirty = form?.formState?.isDirty;

  const { mutate: mutateAction } = useMutation(
    (variables: any) => MemberApi.configRole(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onClose();
          queryClient.invalidateQueries(['get-member-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { data: detail, refetch } = useQuery(
    ['get-member-detail', id],
    () => MemberApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  useEffect(() => {
    refetch();
  }, [id]);

  useEffect(() => {
    if (!detail) return;
    form.setValue('role_id', detail?.role?.id);
  }, [detail]);

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (value) => {
    mutateAction({ params: { ...value, member_id: id } });
  };

  return (
    <Modal
      width={500}
      open={open}
      okText="Lưu"
      cancelText="Huỷ"
      title="Phân quyền thành viên"
      destroyOnClose
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={handleCloseConfirm}
      centered={true}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem
          name="role_id"
          label="Phân quyền"
          rules={[
            {
              required: messageValidate.emptySelect,
            },
          ]}
        >
          <SelectPermissionGroup paramsApi={{ include_id: detail?.role?.id }} />
        </FormTeraItem>
      </FormTera>
    </Modal>
  );
};

export default Permission;
