export const STATUS_LOCATION = [
  {
    key: 'all',
    title: 'Tất cả',
  },
  {
    key: 1,
    title: 'Hoạt động',
  },
  {
    key: 0,
    title: 'Ngừng hoạt động',
  },
];

export const StoreStatus = {
  0: 'Ngưng hoạt động',
  1: 'Hoạt động',
};

export const StoreStatusColor = {
  0: 'red03',
  1: 'green03',
};
