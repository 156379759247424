import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectModule from '_common/dof/Select/SelectModule';
import _, { difference, isEqual, uniqWith } from 'lodash';
import ConfigPermissionApi from 'pages/PermissionGroup/_api';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { ReactComponent as IconNodata } from 'styles/images/uiNew/NoData.svg';
import {
  Checkbox,
  Col,
  Modal,
  notification,
  Row,
  TabItemType,
  Tabs,
  updateToggleValueInArr,
} from 'tera-dls';
interface ConfigProps {
  id: string | number;
  open: boolean;
  onCancel: () => void;
  onRefetch: () => void;
}

function Config({ id, open, onCancel, onRefetch }: ConfigProps) {
  const queryClient = useQueryClient();
  const convertId = Number(id);

  const [module_id, setModuleId] = useState<string | number>(null);
  const [activeKey, setActiveKey] = useState<string>('');

  const [
    { data: listDefaultConfigControl, isFetching },
    { data: listRoleHasPermission },
  ] = useQueries({
    queries: [
      {
        queryKey: ['get-role-config-control', module_id],
        queryFn: () => ConfigPermissionApi.getListConfigControl({ module_id }),
        refetchOnWindowFocus: false,
        enabled: !!module_id,
      },
      {
        queryKey: ['get-role-has-permission', convertId],
        queryFn: () => ConfigPermissionApi.getListRoleHasPermission(convertId),
        refetchOnWindowFocus: false,
        enabled: !!module_id,
      },
    ],
  });

  const [listPermission, setListPermission] = useState([]);
  const [selectedControlId, setSelectedControlId] = useState<number[]>([]);

  const { mutate: mutateSettingPermission, isLoading } = useMutation(
    (variables: any) => {
      return ConfigPermissionApi.setting(variables);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onRefetch();
          onCancel();
          queryClient.invalidateQueries(['get-role-config-control']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.msg,
        });
      },
    },
  );

  const convertDataPermission = (listDataBefore) => {
    const newList = listDataBefore?.reduce((acc, item) => {
      const checkEmpty = item || [];
      if (acc.length === 0) return [checkEmpty];
      return [...acc, checkEmpty];
    }, []);
    return newList;
  };

  const setDefaultListPage = () => {
    if (listDefaultConfigControl) {
      const dataConvert = convertDataPermission(
        listDefaultConfigControl?.epics?.[0]?.controls,
      );
      setListPermission(dataConvert);
      // setListPage(listDefaultConfigControl?.[0]?.pages);
      setActiveKey(listDefaultConfigControl?.epics?.[0]?.id);
    }
  };

  const setDefaultSelectedControlId = () => {
    if (listRoleHasPermission)
      setSelectedControlId(
        listRoleHasPermission.map((item) => item.permission_id),
      );
  };

  useEffect(() => setDefaultSelectedControlId(), [listRoleHasPermission]);

  useEffect(() => {
    setDefaultListPage();
  }, [listDefaultConfigControl]);

  const listTab: TabItemType[] = useMemo(
    () =>
      listDefaultConfigControl?.epics?.map((item) => ({
        key: item.id,
        label: item.name,
      })) || [],
    [listDefaultConfigControl],
  );

  const handleChangeTab = (activeKey: string) => {
    const findDataTab = listDefaultConfigControl?.epics?.find(
      (item) => item.id === activeKey,
    );
    const dataConvert = convertDataPermission(findDataTab?.controls);
    setListPermission(dataConvert);
    setActiveKey(findDataTab?.id);
  };

  const listCheckboxNode = useMemo(
    () => (listControl: any[]) => {
      const listPermissionId = listControl
        ? listControl?.map((item) => item.id)
        : [];
      const checkedCurrentAll = listPermissionId.every((id) =>
        selectedControlId.includes(id),
      );

      const triggerSingleCheckbox = (item) => {
        const indexItem = selectedControlId.findIndex((id) => id === item.id);
        setSelectedControlId(
          updateToggleValueInArr(indexItem, item.id, selectedControlId),
        );
      };

      const onCheckAll = (
        e: ChangeEvent<HTMLInputElement>,
        listControl: number[],
      ) => {
        e.target.checked
          ? setSelectedControlId(
              uniqWith([...selectedControlId, ...listControl], isEqual),
            )
          : setSelectedControlId(
              [...selectedControlId].filter(
                (item) => !listControl.includes(item),
              ),
            );
      };

      if (!listControl?.length) return null;
      return (
        <Row className="grid-cols-12 gap-0 gap-y-5 overflow-auto flex-1">
          <Col className="col-span-2">
            <Checkbox
              labelClassName="truncate"
              checked={!!listControl?.length && checkedCurrentAll}
              onChange={(e) =>
                onCheckAll(
                  e,
                  listControl?.map((item) => item.id),
                )
              }
            >
              Tất cả
            </Checkbox>
          </Col>
          <Col className="col-span-10">
            <Row className="grid-cols-4 gap-5">
              {listControl?.map((item) => {
                if (item.status === 'inactive') return null;
                return (
                  <Col key={item.id}>
                    <Checkbox
                      checked={selectedControlId.includes(item.id)}
                      labelClassName="truncate"
                      onChange={() => triggerSingleCheckbox(item)}
                    >
                      {item.title}
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      );
    },
    [selectedControlId, listRoleHasPermission, activeKey],
  );

  const handleConfigPermission = () => {
    mutateSettingPermission({
      role_id: convertId,
      permission_id: selectedControlId,
    });
  };

  const listIdPermissionModule = useMemo(() => {
    if (!listDefaultConfigControl) return [];
    const listIdPermission = _.flatMapDeep(
      listDefaultConfigControl?.epics,
      (obj) => obj?.controls?.map((item) => item.id),
    );
    return listIdPermission;
  }, [listDefaultConfigControl]);

  const onCheckAllModule = (e, listIdPermissionModule) => {
    e.target.checked
      ? setSelectedControlId(
          uniqWith([...selectedControlId, ...listIdPermissionModule], isEqual),
        )
      : setSelectedControlId(
          difference(selectedControlId, listIdPermissionModule),
        );
  };

  function containsAll(array1, array2) {
    const set = new Set(array1);
    for (const item of array2) {
      if (!set.has(item)) {
        return false;
      }
    }
    return true;
  }

  return (
    <Modal
      title="Cấu hình nhóm quyền"
      open={open}
      width="80%"
      okText="Lưu"
      cancelText="Hủy"
      onCancel={onCancel}
      onOk={handleConfigPermission}
      destroyOnClose
      confirmLoading={isFetching || (isLoading && !!id)}
      bodyClassName="h-[80vh] flex flex-col"
    >
      <div className="flex items-center gap-x-2.5">
        <FormTera>
          <FormTeraItem name="module" className="mb-0">
            <SelectModule
              placeholder="Chọn Module"
              value={module_id}
              paramsApi={{ for_business: 1 }}
              onChange={(id) => setModuleId(id as number)}
              className="w-[200px]"
            />
          </FormTeraItem>
        </FormTera>
        {!!module_id && (
          <Checkbox
            checked={containsAll(selectedControlId, listIdPermissionModule)}
            onChange={(e) => onCheckAllModule(e, listIdPermissionModule)}
          >
            Cho phép tất cả quyền
          </Checkbox>
        )}
      </div>
      {listTab && listDefaultConfigControl?.epics?.length && (
        <Tabs
          activeKey={activeKey}
          items={listTab}
          onChange={handleChangeTab}
        />
      )}
      {!!listPermission?.length && module_id ? (
        listCheckboxNode(listPermission)
      ) : (
        <div className="flex justify-center">
          {module_id ? (
            <div className="flex items-center flex-col gap-2.5">
              <IconNodata />
              <span className="text-lg">Chưa có dữ liệu!</span>
            </div>
          ) : (
            <span className="font-medium h-10 flex items-center">
              Vui lòng chọn Module!
            </span>
          )}
        </div>
      )}
    </Modal>
  );
}

export default Config;
