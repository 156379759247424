import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ServiceModal from '_common/component/ServiceModal';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import CartApi from 'pages/Cart/api';
import ServicePackageApi from 'pages/ServicePackage/_api';
import useScroll from 'pages/ServicePackage/_hooks/useScroll';
import { useEffect, useRef, useState } from 'react';
import { customTwMerge } from 'tailwind-merge.config';
import {
  Button,
  ChevronLeftOutlined,
  ChevronRightOutlined,
  notification,
  Spin,
} from 'tera-dls';
import Card from './Card';
import { useStores } from '_common/hooks';
import { useNavigate } from 'react-router-dom';
import { CART_URL } from 'pages/Cart/url';

interface IProps {
  open: boolean;
  onClose?: () => void;
  value?: number;
  selectedId?: number;
  cartId?: number;
  mode?: 'registration' | 'change';
}

const Registration = (props: IProps) => {
  const {
    onClose,
    open,
    value: id,
    selectedId,
    mode = 'registration',
    cartId,
  } = props;
  const ref = useRef(null);
  const elementRef = useRef(null);
  const queryClient = useQueryClient();
  const {
    cartStore: { updateCartIds, updateType },
  } = useStores();
  const navigate = useNavigate();

  const [selectedPackageId, setSelectedPackageId] = useState<
    number | undefined
  >();

  const isChangeMode = mode === 'change';

  const {
    data: response,
    refetch,
    isLoading: loadingData,
  } = useQuery(
    ['get-service-package-registration', id],
    () =>
      ServicePackageApi.getRegistrationDetail({
        params: {
          service_id: id,
        },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );
  const { onScroll, canScroll, isScrollingLeft, isScrollingRight } = useScroll({
    targetRef: ref,
    speed: 270,
    dependencies: [response],
  });

  useEffect(() => {
    id && refetch();
  }, [id]);

  useEffect(() => {
    selectedId && setSelectedPackageId(selectedId);
  }, [selectedId]);

  useEffect(() => {
    let id;
    if (selectedId && elementRef.current) {
      console.log(elementRef.current);

      id = setTimeout(() => {
        elementRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 50);
    }
    return () => {
      clearTimeout(id);
    };
  }, [elementRef.current, selectedId]);

  const {
    mutate: mutateAddCart,
    mutateAsync: mutateAddCartAsync,
    isLoading: isAddCartLoading,
  } = useMutation((variables: any) => CartApi.create(variables), {
    onSuccess: (res) => {
      if (res?.code === 200) {
        queryClient.invalidateQueries(['get-cart-total']);
        notification.success({
          message: res?.msg,
        });
      }
    },
    onError(error: any) {
      ErrorToast({ errorProp: error?.data });
    },
  });

  const { mutate: mutateChange } = useMutation(
    (variables: any) => CartApi.changePackage(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onClose && onClose();
          queryClient.invalidateQueries(['get-cart-list']);
          queryClient.invalidateQueries(['get-cart-payment']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleAddCart = () => {
    mutateAddCart({ package_id: selectedPackageId });
  };

  const handleChangePackage = () => {
    mutateChange({ id: cartId, params: { package_id: selectedPackageId } });
  };

  const handlePayQuickly = async () => {
    try {
      const response = await mutateAddCartAsync({
        package_id: selectedPackageId,
      });
      if (response?.code === 200) {
        updateCartIds([response?.data?.id]);
        updateType('active');
        navigate(CART_URL);
      }
    } catch (e) {}
  };

  return (
    <ServiceModal
      destroyOnClose
      closeIcon={false}
      onCancel={onClose}
      width={1000}
      maskClosable={false}
      open={open}
      centered={true}
    >
      <Spin spinning={loadingData}>
        <div className="w-[865px] flex flex-col gap-[16px]">
          <div className="flex flex-col justify-center">
            <h3 className="font-light text-base text-center tracking-[4px] uppercase">
              Kích hoạt gói dịch vụ
            </h3>
            <h2 className="text-[30px] leading-[35px] font-bold text-center mt-[20px] tracking-[6px] uppercase">
              {response?.name}
            </h2>
            <div className="flex justify-center">
              <p className="font-light text-center mt-[16px] w-[65%]">
                {response?.description}
              </p>
            </div>
          </div>
          <div
            className={customTwMerge('flex', !canScroll && 'justify-center')}
          >
            <div
              className={customTwMerge(
                'flex items-center px-2',
                (!canScroll || !isScrollingLeft) && 'invisible',
              )}
            >
              <ChevronLeftOutlined
                className="w-8 cursor-pointer"
                onClick={() => {
                  onScroll('toLeft');
                }}
                onMouseUp={() => onScroll('none')}
              />
            </div>
            <div
              className={customTwMerge(
                'flex gap-[25px] rounded-[8px] overflow-x-auto snap-mandatory scroll-smooth justify-start pb-2.5',
              )}
              ref={ref}
            >
              {response?.packages?.map((item) => (
                <Card
                  {...(selectedId === item?.id &&
                    isChangeMode && {
                      ref: elementRef,
                    })}
                  value={item}
                  usedModules={response?.modules}
                  modules={response?.modules_no_using}
                  onSelect={setSelectedPackageId}
                  selected={selectedPackageId === item?.id}
                />
              ))}
            </div>
            <div
              className={customTwMerge(
                'flex items-center px-2',
                (!canScroll || !isScrollingRight) && 'invisible',
              )}
            >
              <ChevronRightOutlined
                className="w-8 cursor-pointer"
                onClick={() => onScroll('toRight')}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-5">
          <div
            className={customTwMerge(
              'grid grid-cols-3 w-[865px] gap-[16px] justify-center',
              isChangeMode && 'grid-cols-2 w-[500px]',
            )}
          >
            <Button
              onClick={onClose}
              type={'light'}
              className="rounded-[54px] col-span-1 text-base leading-[18px] text-blue-500"
            >
              Hủy
            </Button>
            {isChangeMode ? (
              <>
                <Button
                  className="rounded-[54px] text-base col-span-1 leading-[18px]"
                  disabled={
                    !selectedPackageId || selectedPackageId === selectedId
                  }
                  onClick={handleChangePackage}
                >
                  Xác nhận
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="rounded-[54px] col-span-1 text-base leading-[18px]"
                  disabled={!selectedPackageId}
                  loading={isAddCartLoading}
                  onClick={handleAddCart}
                >
                  Thêm vào giỏ hàng
                </Button>
                <Button
                  className="rounded-[54px] text-base col-span-1 leading-[18px]"
                  disabled={!selectedPackageId}
                  onClick={handlePayQuickly}
                >
                  Thanh toán
                </Button>
              </>
            )}
          </div>
        </div>
      </Spin>
    </ServiceModal>
  );
};

export default Registration;
