import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

type IStep = 'cart' | 'information';
type Type = 'active' | 'upgrade' | 'extend' | 'restart' | undefined;

export class CartStore {
  cart_ids = [];
  cart = {};
  voucher = '';
  information = {};
  type: Type;
  step: IStep = 'cart';

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'CartStore',
      properties: [
        'cart_ids',
        'voucher',
        'information',
        'step',
        'type',
        'cart',
      ],
      storage: window.sessionStorage,
    });
  }

  clear = () => {
    this.cart_ids = [];
    this.cart = {};
    this.type = undefined;
    this.voucher = '';
    this.information = {};
  };

  updateInformation = (value: any = {}) => {
    this.information = value;
  };

  updateCartIds = (cartIds: any = []) => {
    this.cart_ids = cartIds;
    this.type = 'active';
    this.cart = {};
  };

  updateCart = (cart: any = {}) => {
    this.cart = cart;
    this.cart_ids = [];
  };

  updateType = (type: Type) => {
    this.type = type;
  };

  updateVoucher = (voucher: string) => {
    this.voucher = voucher;
  };
}
