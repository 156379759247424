import { useNavigate } from 'react-router-dom';
import BackgroundLaptop from 'styles/images/uiNew/bg-laptop.png';
import NotPermissionIcon from 'styles/images/uiNew/not-permission-icon.png';
import { Button } from 'tera-dls';
const NotPermission = () => {
  const navigate = useNavigate();
  return (
    <div
      // style={{
      //   background: `url(${BackgroundNotPermission})`,
      //   backgroundSize: 'cover',
      // }}
      className="grid grid-cols-2 w-full "
    >
      <div className="col-span-1 grid place-items-center h-full">
        <div className="flex flex-col items-center gap-2.5  w-[366px]">
          <img src={NotPermissionIcon} width={264} height={200} />
          <div className="text-blue-500 text-sm font-light text-center">
            <div className="font-medium">Bạn không có quyền truy cập</div>
            <div>
              Vui lòng đăng ký tài khoản doanh nghiệp để sử dụng dịch vụ
            </div>
          </div>
          <Button
            onClick={() => navigate('/business/information')}
            className="w-full flex justify-center"
          >
            Đăng ký tài khoản doanh nghiệp
          </Button>
        </div>
      </div>
      <div className="col-span-1 h-full flex justify-end">
        <img src={BackgroundLaptop} className="w-[90%]" />
      </div>
    </div>
  );
};

export default NotPermission;
