export const PERMISSION_KEY = {
  APPLICATION_VIEW_LIST: 'portal_application_view_application_list',
  APPLICATION_CREATE: 'portal_application_create_application',
  APPLICATION_UPDATE: 'portal_application_update_application',
  APPLICATION_DETAIL: 'portal_application_view_application_detail',
  APPLICATION_DELETE: 'portal_application_delete_application',
  APPLICATION_SEARCH: 'portal_application_search_application',
  APPLICATION_IMPORT_EXCEL: 'portal_application_import_excel',
  APPLICATION_EXPORT_EXCEL: 'portal_application_export_excel',
};
