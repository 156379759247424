import { useMutation, useQueryClient } from '@tanstack/react-query';
import { REGEX } from '_common/constants/common';
import { messageError, messageWarning } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import InputPassword from '_common/dof/Control/InputPassword';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification } from 'tera-dls';
import { UserApi } from '../api';

interface IProps {
  open: boolean;
  onClose?: () => void;
  formKey?: any;
  value?: any;
}

const ChangingPassword = (props: IProps) => {
  const { onClose, open, value } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const isDirty = form?.formState?.isDirty;

  const handleCloseConfirm = () => {
    if (isDirty) {
      console.log(isDirty);

      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const { mutate: mutateUpdate } = useMutation(
    (variables: any) => UserApi.changePassword(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          onClose();
          queryClient.invalidateQueries(['get-user-profile']);
        }
      },
      onError(error: any) {
        const errorMessage = error?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  const handleSubmitForm = (value) => {
    mutateUpdate({
      old_password: value.password,
      new_password: value.new_password,
    });
  };

  useEffect(() => {
    if (!value) return;
    form.setValue('username', value.username);
  }, [value]);

  return (
    <Modal
      width={700}
      open={open}
      okText="Đồng ý"
      cancelText="Huỷ"
      title="SỬA MẬT KHẨU"
      destroyOnClose
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={handleCloseConfirm}
      centered={true}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem
          name="username"
          label="Tên đăng nhập"
          rules={[{ required: 'Vui lòng chọn thông tin' }]}
        >
          <Input placeholder="Vui lòng nhập" disabled className="py-[13px]" />
        </FormTeraItem>
        <FormTeraItem
          name="password"
          label="Mật khẩu hiện tại"
          rules={[
            {
              required: 'Vui lòng nhập mật khẩu',
              pattern: {
                value: REGEX.PASSWORD,
                message: 'Mật khẩu không được chứa khoảng cách',
              },
              minLength: {
                value: 8,
                message: 'Mật khẩu phải lớn hơn 8 ký tự',
              },
            },
          ]}
        >
          <InputPassword
            maxLength={16}
            placeholder="Vui lòng nhập"
            className="py-[13px]"
          />
        </FormTeraItem>
        <FormTeraItem
          name="new_password"
          label="Mật khẩu mới"
          rules={[
            {
              required: 'Vui lòng nhập mật khẩu',
              pattern: {
                value: REGEX.PASSWORD,
                message: 'Mật khẩu không được chứa khoảng cách',
              },
              minLength: {
                value: 8,
                message: 'Mật khẩu phải lớn hơn 8 ký tự',
              },
            },
          ]}
        >
          <InputPassword
            maxLength={16}
            placeholder="Vui lòng nhập"
            className="py-[13px]"
          />
        </FormTeraItem>
        <FormTeraItem
          name="re_new_password"
          label="Xác nhận mật khẩu mới"
          rules={[
            {
              required: 'Vui lòng nhập mật khẩu',
              pattern: {
                value: REGEX.PASSWORD,
                message: 'Mật khẩu không được chứa khoảng cách',
              },
              minLength: {
                value: 8,
                message: 'Mật khẩu phải lớn hơn 8 ký tự',
              },
              validate: {
                existed: (value, values) => {
                  const password = values?.new_password.trim();
                  if (value.trim() !== password) {
                    return 'Nhập lại mật khẩu không trùng với mật khẩu mới';
                  }
                },
              },
            },
          ]}
        >
          <InputPassword
            maxLength={16}
            placeholder="Vui lòng nhập"
            className="py-[13px]"
          />
        </FormTeraItem>
      </FormTera>
    </Modal>
  );
};

export default ChangingPassword;
