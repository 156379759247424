import Search from '_common/dof/Control/Search';
import FormTera, { FormTeraItem, useFormTera } from '_common/dof/FormTera';

function SearchApplication({ onSearch, placeholder = 'Tìm kiếm ứng dụng' }) {
  const [formRef] = useFormTera();

  const handleSearch = (value) => {
    if (formRef?.current?.isDirty) {
      onSearch(value);
      formRef?.current?.reset({ ...value }, { keepValues: true });
    }
  };

  return (
    <FormTera ref={formRef} onSubmit={handleSearch}>
      <FormTeraItem className="!mb-0" name="keyword">
        <Search placeholder={placeholder} className="w-[400px] rounded-full" />
      </FormTeraItem>
    </FormTera>
  );
}

export default SearchApplication;
