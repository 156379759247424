import { useStores } from '_common/hooks/useStores';
import { CryptoJSAesEncrypt } from '_common/utils/hashHelper';
import { Button, updateQueryParams } from 'tera-dls';

interface ModuleItemProps {
  data?: any;
  showButton?: boolean;
  onClickButton?: () => void;
}

function ModuleItem(props: ModuleItemProps) {
  const { showButton = false, onClickButton, data } = props;
  const { authStore } = useStores();

  const handleRedirectLink = (link) => {
    if (!link) return;
    const bodyParams = {
      access_id: authStore?.access_id,
    };
    const queryParams = updateQueryParams({
      client_id: 'tera',
      req: JSON.stringify(CryptoJSAesEncrypt(bodyParams)),
    });
    window.open(`${link}${queryParams}`, '_self');
  };

  return (
    <div className="border px-3 py-2 flex items-center justify-between">
      <div className="flex items-center gap-x-2.5">
        <div
          className={`uppercase text-white w-[50px] h-[50px] rounded flex items-center justify-center ${data?.color}`}
        >
          {data?.code}
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            handleRedirectLink(data?.url);
          }}
        >
          <a className="text-blue-600 font-medium uppercase">{data?.code}</a>
          <p className="italic text-blue-400">
            {data?.url} - {data?.title}
          </p>
        </div>
      </div>

      <div>
        {showButton && (
          <Button type="success" onClick={onClickButton}>
            Danh sách thành viên
          </Button>
        )}
      </div>
    </div>
  );
}

export default ModuleItem;
