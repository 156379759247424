import { IRouteProps } from '_common/interface/router';
import ServicePackage from '.';
import { SERVICE_PACKAGE_URL } from './constants/url';

export const ServicePackageRouter: IRouteProps[] = [
  {
    key: SERVICE_PACKAGE_URL.list.key,
    path: SERVICE_PACKAGE_URL.list.shortenUrl,
    component: <ServicePackage />,
  },
];
