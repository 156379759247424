import React from 'react';

interface EmptyProps {
  image?: React.ReactNode;
  sub?: string | React.ReactNode;
}

function Empty({ image, sub }: EmptyProps) {
  return (
    <div className="flex flex-col gap-y-2.5 items-center m-auto">
      {image}
      <p className="flex items-center text-base text-gray-700">{sub}</p>
    </div>
  );
}

export default Empty;
