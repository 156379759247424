import Filter from '_common/component/Filter';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem, useFormTera } from '_common/dof/FormTera';
import _ from 'lodash';
import { StoreStatus } from 'pages/Store/constants';

import { useEffect } from 'react';
import { OptionProps } from 'tera-dls';

interface StoreFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

const optionsAll: OptionProps[] = [
  {
    label: 'Tất cả',
    value: null,
  },
];

function StoreFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: StoreFilterProps) {
  const [formRef] = useFormTera();

  const handleSubmitForm = (values) => {
    onFilter(values);
    onClose();
  };

  const handleReset = () => {
    const values = {
      status: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = _.pick(initialValue, ['status']);

    formRef?.current?.reset({
      status: values?.status === 'all' ? null : values?.status,
    });
  }, [initialValue]);

  const optionsStatus = Object.entries(StoreStatus).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => formRef?.current?.handleSubmit(handleSubmitForm)()}
    >
      <FormTera ref={formRef} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Trạng thái" name="status">
          <Select
            allowClear
            placeholder="Vui lòng chọn"
            options={[...optionsAll, ...optionsStatus]}
          />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default StoreFilter;
