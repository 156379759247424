import { BoltSolid } from 'tera-dls';
import Item from './Item';
import BoxCheck from '_common/component/Icons/BoxCheck';
import { useNavigate } from 'react-router-dom';
import { SERVICE_PACKAGE_URL } from 'pages/ServicePackage/constants/url';

function SupportFunction() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-4">
      <h3 className="uppercase text-sm text-gray-700">Các chức năng hỗ trợ</h3>
      <div className="flex flex-col gap-4">
        <Item
          icon={<BoltSolid className="w-5 h-5 text-gray-300" />}
          sub="Bạn có thể kiếm nhiều nguồn thu khác nhau qua đường dẫn sản phẩm bạn chia sẻ."
          title="Tiếp thị liên kết"
          onClick={() => navigate('/')}
        />
        <Item
          icon={<BoxCheck />}
          sub="Các gói dịch vụ hỗ trợ cho việc kinh doanh, quản lý doanh nghiệp"
          title="Gói dịch vụ"
          onClick={() => navigate(SERVICE_PACKAGE_URL.list.path)}
        />
      </div>
    </div>
  );
}

export default SupportFunction;
