import { useQuery } from '@tanstack/react-query';
import DefaultImage from '_common/component/DefaultImage';
import HoverQuickView from '_common/component/HoverQuickView';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import { messageError } from '_common/constants/message';
import moment from 'moment';
import { MemberApi } from 'pages/EmployeeInformation/api';
import {
  statusTypeColor,
  USER_STATUS,
} from 'pages/EmployeeInformation/constants';
import { useEffect } from 'react';
import { Button, Col, Modal, notification, Tag } from 'tera-dls';

interface IProps {
  open: boolean;
  onClose?: () => void;
  formKey?: any;
  value?: any;
}
export const renderDescription = (data, width = 150) =>
  data?.map((item, index) => (
    <div className="flex gap-5" key={item?.key ?? index}>
      <h6
        className={` !text-gray-500 font-normal detail-key w-full`}
        style={{
          minWidth: width,
          maxWidth: width,
        }}
      >
        {item?.title}
      </h6>
      <span className=" w-full detail-value text-gray-700 font-normal">
        {item?.value}
      </span>
    </div>
  ));

const EmployeeDetail = (props: IProps) => {
  const { onClose, open, value: id } = props;

  const { data: detail, refetch } = useQuery(
    ['get-member-detail', id],
    () => MemberApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
      onError(error: any) {
        const errorMessage = error?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  useEffect(() => {
    id && refetch();
  }, [id]);

  const data = [
    {
      key: 'full_name',
      title: 'Họ và tên',
      value: detail?.full_name,
    },
    {
      key: 'email',
      title: 'Email',
      value: detail?.email,
    },
    {
      key: 'phone',
      title: 'Số điện thoại',
      value: detail?.phone,
    },
    {
      key: 'username',
      title: 'Tên đăng nhập',
      value: detail?.username,
    },
    {
      key: 'job_title',
      title: 'Chức danh',
      value: detail?.job_title_text?.title,
    },
    {
      key: 'department',
      title: 'Phòng ban/Bộ phận',
      value: detail?.department_text?.title,
    },
    {
      key: 'config-role',
      title: 'Phân quyền',
      value:
        detail?.role?.code === 'user_default' &&
        !detail?.role?.business_id &&
        detail?.role?.type === 'user'
          ? ''
          : detail?.role?.title,
    },
    {
      key: 'status-role',
      title: 'Trạng thái',
      value: USER_STATUS[detail?.is_active] && (
        <Tag color={statusTypeColor[detail?.is_active]}>
          {USER_STATUS[detail?.is_active]}
        </Tag>
      ),
    },
    {
      key: 'created_by',
      title: 'Người tạo',
      value: detail?.user_created && (
        <HoverQuickView
          avatarUrl={detail?.user_created?.avatar_url}
          email={detail?.user_created?.email}
          name={detail?.user_created?.full_name}
          phone={detail?.user_created?.phone}
          className="line-clamp-2"
        >
          <span> {detail?.user_created?.full_name}</span>
        </HoverQuickView>
      ),
    },
    {
      key: 'created_at',
      title: 'Ngày tạo',
      value: detail?.created_at
        ? moment(detail?.created_at).format(DATE_TIME_FORMAT)
        : '',
    },
    {
      key: 'user_updated',
      title: 'Người cập nhật',
      value: detail?.user_updated && (
        <HoverQuickView
          avatarUrl={detail?.user_updated?.avatar_url}
          email={detail?.user_updated?.email}
          name={detail?.user_updated?.full_name}
          phone={detail?.user_updated?.phone}
          className="line-clamp-2"
        >
          <span> {detail?.user_updated?.full_name}</span>
        </HoverQuickView>
      ),
    },
    {
      key: 'updated_at',
      title: 'Ngày cập nhật',
      value: detail?.user_updated
        ? moment(detail?.updated_at).format(DATE_TIME_FORMAT)
        : '',
    },
  ];

  return (
    <>
      <Modal
        width={450}
        open={open}
        okText="Đồng ý"
        cancelText="Huỷ"
        title={'Chi tiết thành viên'}
        destroyOnClose
        onCancel={onClose}
        centered={true}
        footer={
          <div className="flex gap-[16px] mt-2 mr-4">
            <Button onClick={() => onClose()}>Đóng</Button>
          </div>
        }
      >
        <Col className="flex flex-col gap-[16px]">
          <div className="flex justify-center w-full">
            <DefaultImage
              src={detail?.avatar_url}
              alt={detail?.avatar_url}
              className="w-[80px] h-[80px] rounded-full"
            />
          </div>
          <div className="flex flex-col gap-2">
            {renderDescription(data, 120)}
          </div>
        </Col>
      </Modal>
    </>
  );
};

export default EmployeeDetail;
