import { useNavigate } from 'react-router-dom';
import { LifebuoySolid, Tooltip } from 'tera-dls';
import { ReactComponent as IconBook } from 'styles/images/Icons/book.svg';

const itemClassName =
  'group cursor-pointer w-10 h-10 flex justify-center items-center rounded-md hover:bg-blue-50 hover:shadow-[0_4px_4px_0_rgba(0,0,0,0.05),0_4px_4px_0_rgba(0,0,0,0.03)]';

const iconClassName = 'w-6 h-6 text-[#00AFEF]';

export const menusHelp = [
  {
    title: 'Liên hệ hỗ trợ',
    icon: <LifebuoySolid className={iconClassName} />,
    url: '/dashboard',
  },
  {
    title: 'Hướng dẫn đăng ký doanh nghiệp',
    icon: <IconBook className={`${iconClassName} shake-animation`} />,
    url: '/service-package',
  },
];

function Help() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-y-2.5">
      {menusHelp.map((menu) => (
        <Tooltip title={menu.title} placement="right">
          <div onClick={() => navigate(menu.url)} className={itemClassName}>
            {menu.icon}
          </div>
        </Tooltip>
      ))}
    </div>
  );
}

export default Help;
