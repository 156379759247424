import CheckBox from '_common/dof/Control/CheckBox';

// ${
// isError
//   ? 'border-red-500 checked:border-red-500'
//   : 'border-white checked:border-white'
//}
const CheckedRules = ({ isError, ...restProps }) => {
  return (
    <CheckBox
      {...restProps}
      containerClassName="flex items-start"
      className={`bg-inherit border-[2px] border-gray-200 
      ${
        isError
          ? 'border-red-500 checked:border-red-500 focus:ring-red-500 focus:ring-offset-0'
          : ' '
      }
      `}
    >
      <div className="text-gray-500 break-word text-[13px]">
        Tôi đã xem và đồng ý <span className="">Điều khoản dịch vụ</span> &{' '}
        <span className="">Chính sách mua hàng </span> của Tera
      </div>
    </CheckBox>
  );
};

export default CheckedRules;
