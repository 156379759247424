import { useQuery } from '@tanstack/react-query';
import { DATE_BACKEND_FORMAT, DATE_FORMAT } from '_common/constants/common';
import RangePicker from '_common/dof/Control/RangePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import PaymentMethodApi from 'pages/Wallet/_api/admin';
import { TransactionStatistic } from 'pages/Wallet/constants';
import { useMemo, useState } from 'react';
// import { ReactComponent as EmptyDashboard } from 'styles/images/wallet/empty-dashboard.svg';
import moment from 'moment';
import EmptyDashboard from 'styles/images/wallet/empty_dashboard.png';
import { ChartDoughnut } from 'tera-dls';
import Empty from '../../Empty';

function General() {
  const defaultQueryParams = [
    moment().startOf('month'),
    moment().endOf('month'),
  ];
  const [queryParams, setQueryParams] = useState<any>(defaultQueryParams);

  const { data: statisticalData } = useQuery(
    ['get-statistical', queryParams],
    () => {
      const params = {
        get_at_portal: 1,
        fromDate:
          queryParams?.[0] &&
          moment(queryParams[0]).format(DATE_BACKEND_FORMAT),
        toDate:
          queryParams?.[1] &&
          moment(queryParams[1]).format(DATE_BACKEND_FORMAT),
      };
      return PaymentMethodApi.statistical({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const dataStatistical = useMemo(() => {
    if (!statisticalData) return;
    const labels = Object.keys(TransactionStatistic).map(
      (key) =>
        `${TransactionStatistic[key]}: ${
          statisticalData?.[key]?.percent || 0
        }%`,
    );
    const data =
      Object.keys(TransactionStatistic).map(
        (key) => statisticalData?.[key]?.value,
      ) || [];

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(14, 159, 110)',
          ],
          hoverOffset: 4,
        },
      ],
    };
  }, [statisticalData]);

  console.log('statisticalData', statisticalData);

  return (
    <div className="p-6 rounded-2xl bg-[#FAFAF9] flex flex-col rounded-2xl shadow-[0_1px_8px_0_rgba(0,0,0,0.1)]">
      <div className="flex justify-between items-center">
        <h3 className="uppercase">Tổng quan</h3>
        <FormTera>
          <FormTeraItem name="time" className="mb-0">
            <RangePicker
              value={queryParams}
              format={DATE_FORMAT}
              placeholder={['Từ ngày', 'Đến ngày']}
              onChange={(value) => {
                setQueryParams(value);
              }}
              className="bg-transparent"
              allowClear={false}
              style={{
                border: 'none',
                padding: '4px 8px',
                boxShadow: 'none',
                borderBottom: '1px solid #333',
              }}
            />
          </FormTeraItem>
        </FormTera>
      </div>
      <div className="flex items-center justify-center">
        {statisticalData ? (
          <div>
            <ChartDoughnut
              labelCenter={statisticalData.summary || 0}
              data={dataStatistical}
              options={{
                plugins: {
                  legend: {
                    display: true,
                    position: 'right',
                  },
                },
              }}
            />
          </div>
        ) : (
          <Empty sub="Chưa có dữ liệu" image={<img src={EmptyDashboard} />} />
        )}
      </div>
    </div>
  );
}

export default General;
