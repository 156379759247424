import { endpoint, adminEndpoint } from '_common/constants/common';
import api from 'states/drivers';

const ConfigPermissionEndpoint = `${endpoint}/portal/role`;
const AdminEndpoint = `${adminEndpoint}/administrator`;

const PermissionGroupApi = {
  getModules: async () =>
    await api
      .get(`${ConfigPermissionEndpoint}/list-module`)
      .then((result) => result.data?.data),
  getList: async ({ params }) =>
    await api
      .get(`${ConfigPermissionEndpoint}/list`, params)
      .then((result) => result.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${ConfigPermissionEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${ConfigPermissionEndpoint}/create`, params)
      .then((result) => result?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${ConfigPermissionEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${ConfigPermissionEndpoint}/delete/${id}`)
      .then((result) => result?.data),
  getListConfigControl: async (params?: any) =>
    await api
      .get(`${AdminEndpoint}/business/list-role`, params)
      .then((result) => result?.data?.data),
  getListRoleHasPermission: async (role_id: number) =>
    await api
      .get(`${ConfigPermissionEndpoint}/role-has-permission`, {
        role_id: role_id,
      })
      .then((result) => result?.data?.data),
  setting: async (params) =>
    await api
      .post(`${ConfigPermissionEndpoint}/config-permission`, params)
      .then((result) => result.data),
};

export default PermissionGroupApi;
