import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const MemberEndpoint = `${endpoint}/portal/member`;
export const MemberApi = {
  getList: async (params?: any) =>
    await api
      .get(`${MemberEndpoint}/list`, params)
      .then((result) => result.data?.data),
  getDetail: async (id: number) =>
    await api
      .get(`${MemberEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
  update: async ({ params, id }) =>
    await api
      .put(`${MemberEndpoint}/update/${id}`, params)
      .then((result) => result.data),
  updateStatus: async ({ params }) =>
    await api
      .put(`${MemberEndpoint}/update-status`, params)
      .then((result) => result.data),
  configRole: async ({ params }) =>
    await api
      .put(`${MemberEndpoint}/config-role`, params)
      .then((result) => result.data),
  create: async ({ params }) =>
    await api
      .post(`${MemberEndpoint}/create`, params)
      .then((result) => result.data),
  delete: async ({ id }) =>
    await api
      .delete(`${MemberEndpoint}/delete/${id}`)
      .then((result) => result.data),
  changePassword: async (params: any) =>
    await api
      .post(`${MemberEndpoint}/change-password`, params)
      .then((result) => result.data),
  author: async (params: any) =>
    await api
      .post(`${MemberEndpoint}/permission`, params)
      .then((result) => result.data),
};
