import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import useInfiniteScrollElement from '_common/hooks/useInfiniteScrollElement';
import _ from 'lodash';
import ServicePackageApi from 'pages/ServicePackage/_api';
import { useEffect, useMemo, useRef, useState } from 'react';
import { customTwMerge } from 'tailwind-merge.config';
import {
  ChevronDownOutlined,
  ChevronUpOutlined,
  getQueryParams,
  Spin,
} from 'tera-dls';
import Item from './Item';
import React from 'react';
import { useLocation } from 'react-router-dom';
import emptyItem from 'styles/images/servicePackage/people.png';

const RecommendedPackage = () => {
  const [open, setOpen] = useState<boolean>(true);

  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const queryClient = useQueryClient();
  const scrollRef = useRef(null);
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['get-recommended-service-package-list'],
    staleTime: 30000,
    cacheTime: 30000,
    queryFn: ({ pageParam }: any) =>
      ServicePackageApi.getRecommendedList({
        params: {
          page: pageParam ?? 1,
          limit: 10,
          keyword: queryParams?.keyword,
        },
      }),

    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.current_page < lastPage?.last_page
        ? allPages.length + 1
        : undefined;
    },
  });

  useInfiniteScrollElement({
    ref: scrollRef,
    callback: () => {
      if (hasNextPage && !isFetching && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
  });

  useEffect(() => {
    queryClient.setQueryData(
      ['get-recommended-service-package-list'],
      (oldData: any) => {
        return {
          pages: [oldData?.pages?.[0] ?? []],
          pageParams: [1],
        };
      },
    );
    refetch();
  }, [queryParams?.keyword]);

  const value = useMemo(() => {
    return data?.pages?.reduce((acc, page) => {
      return _.unionBy(acc, page?.data ?? [], 'id');
    }, []);
  }, [data?.pages]);

  const total = useMemo(() => {
    const value = data?.pages?.[data?.pages?.length - 1];
    return value?.total ?? 0;
  }, [data?.pages]);

  return (
    <div
      className={customTwMerge(
        'rounded-[16px] border-[1px] border-green-200 bg-green-50 py-[16px] overflow-hidden duration-150',
        open ? 'h-full' : 'h-[58px]',
      )}
      style={{ boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)' }}
    >
      <div className="flex px-[16px]">
        <div
          className="inline cursor-pointer"
          onClick={() => {
            setOpen((open) => !open);
          }}
        >
          <div className="flex gap-2.5">
            <h2 className="font-bold text-base text-green-500 select-none">
              Gói dịch vụ khả dụng ({total})
            </h2>
            {open ? (
              <ChevronDownOutlined className="w-4 text-green-500" />
            ) : (
              <ChevronUpOutlined className="w-4 text-green-500" />
            )}
          </div>
        </div>
      </div>
      <Spin spinning={isLoading}>
        {value && value?.length > 0 ? (
          <div
            ref={scrollRef}
            className="flex flex-col mt-[30px] overflow-auto max-h-[500px] px-[16px] gap-[16px]"
          >
            {value?.map((item, index) => (
              <React.Fragment key={item?.id}>
                <Item value={item} />
                {index === value?.length - 1 && isFetchingNextPage && (
                  <Spin
                    spinning
                    wrapperClassName="flex justify-center"
                    svgProps={{ className: 'w-5' }}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div className="flex justify-center">
            <div className="mt-3 flex flex-col gap-[5px] items-center">
              <img src={emptyItem} />
              <h3 className="font-light italic">Chưa có dữ liệu</h3>
            </div>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default RecommendedPackage;
