import { useQuery } from '@tanstack/react-query';
import ServiceModal from '_common/component/ServiceModal';
import { DATE_FORMAT } from '_common/constants/common';
import _ from 'lodash';
import moment from 'moment';
import ServicePackageApi from 'pages/ServicePackage/_api';
import {
  PRODUCT_MEMORY_CAPACITY,
  STATUS_SERVICE_BUSINESS,
  STATUS_SERVICE_BUSINESS_COLOR_TAG,
} from 'pages/ServicePackage/constants';
import { useEffect, useMemo, useState } from 'react';
import { Button, formatCurrency, formatNumber, Spin, Tag } from 'tera-dls';
import Upgrade from '../Upgrade';
import PaymentQuickly from '../Upgrade/PaymentQuickly';
import Renew from '../Renew';

interface IProps {
  open: boolean;
  onClose?: () => void;
  value: number;
}
const UsedPackageDetail = (props: IProps) => {
  const { onClose, open, value: id } = props;
  const [openUpgradeModal, setOpenUpgradeModal] = useState<boolean>(false);
  const [openRenewModal, setOpenRenewModal] = useState<boolean>(false);
  const [openPayQuicklyModal, setOpenPayQuicklyModal] = useState<any>({
    open: false,
  });

  const {
    data: response,
    refetch,
    isLoading: loadingData,
  } = useQuery(
    ['get-service-package-detail'],
    () => ServicePackageApi.getDetail({ id }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const isTrial = response?.service?.type === 'trial';
  const isActive = response?.status === 'is_active';
  const isRenew = Boolean(response?.package?.extension_allowed);
  const isUpgrade = Boolean(response?.is_upgrade);
  const isReActive = ['expired', 'finished'].includes(response?.status);

  const {
    data: responseRole,
    refetch: refetchRole,
    isLoading: loadingRole,
  } = useQuery(
    ['get-service-package-role'],
    () =>
      ServicePackageApi.getListRoleHasPermission(response?.service?.role_id),
    {
      enabled: !!response?.service?.role_id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const value = useMemo(() => {
    if (!responseRole) return [];
    const data = responseRole.map((item) => item?.group_control?.module);
    const groupData = _.groupBy(data, 'id');
    return Object.keys(groupData)?.map((key) => ({
      id: groupData[key]?.[0]?.id,
      title: groupData[key]?.[0]?.title,
    }));
  }, [responseRole]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    !!response?.service?.role_id && refetchRole();
  }, [response?.service?.role_id]);

  return (
    <>
      <ServiceModal
        destroyOnClose
        closeIcon={false}
        onCancel={onClose}
        width={1000}
        maskClosable={false}
        open={open}
        centered={true}
      >
        <div className="w-[700px] flex flex-col">
          <div className="flex flex-col justify-center ">
            <h3 className="font-light text-base text-center">
              Chi tiết gói dịch vụ
            </h3>
            <h2 className="text-[30px] leading-[35px] font-bold text-center mt-[20px]">
              {response?.service?.name}
            </h2>
            <p className="font-light text-center mt-[16px]">
              {response?.service?.description}
            </p>
          </div>
          <Spin spinning={loadingData || loadingRole}>
            <div className="grid grid-cols-2 gap-[16px] mt-[16px]">
              <div className="col-span-1 rounded-[6px] overflow-hidden flex flex-col">
                <h2 className="text-base px-[16px] pt-[16px] pb-2.5 bg-[#FFFFFF40] font-medium">
                  Thông tin đăng ký
                </h2>
                <div className="divide-y-[1px] px-[16px] pb-[16px] bg-[#FFFFFF40] divide-gray-400 flex flex-col max-h-[550px] overflow-auto rounded-b-[6px]">
                  <div className="flex gap-1 py-[16px] items-start">
                    <span className="font-normal shrink-0 text-gray-300 ">
                      Tên gói sản phẩm:
                    </span>
                    <span className="break-word">
                      {response?.package?.name}
                    </span>
                  </div>
                  <div className="flex gap-2.5 py-[14px] items-center">
                    <span className="font-normal shrink-0 text-gray-300">
                      Trạng thái:
                    </span>
                    <span className="break-word">
                      <Tag
                        color={
                          STATUS_SERVICE_BUSINESS_COLOR_TAG[response?.status]
                        }
                      >
                        {STATUS_SERVICE_BUSINESS[response?.status]}
                      </Tag>
                    </span>
                  </div>
                  <div className="flex gap-1 py-[16px] items-center">
                    <span className="font-normal shrink-0 text-gray-300">
                      Giá:
                    </span>
                    <span className="break-word">
                      {formatCurrency(response?.package?.price ?? 0)}
                    </span>
                  </div>
                  <div className="flex gap-1 py-[16px] items-center">
                    <span className="font-normal shrink-0 text-gray-300">
                      SL người sử dụng:
                    </span>
                    <span className="break-word">
                      {response?.package?.quantity_user}
                    </span>
                  </div>
                  <div className="flex gap-1 py-[16px] items-center">
                    <span className="font-normal shrink-0 text-gray-300">
                      Dung lượng:
                    </span>
                    <span className="break-word">
                      {
                        PRODUCT_MEMORY_CAPACITY[
                          response?.package?.quantity_capacity
                        ]
                      }
                    </span>
                  </div>
                  <div className="flex gap-1 py-[16px] items-center">
                    <span className="font-normal shrink-0 text-gray-300">
                      SL đơn:
                    </span>
                    <span className="break-word">
                      {formatNumber(response?.package?.quantity_order ?? 0)}
                    </span>
                  </div>
                  <div className="flex gap-1 py-[16px] items-center">
                    <span className="font-normal shrink-0 text-gray-300">
                      Ngày kích hoạt:
                    </span>
                    <span className="break-word">
                      {moment(response?.date_active).format(DATE_FORMAT)}
                    </span>
                  </div>
                  <div className="flex gap-1 py-[16px] items-center">
                    <span className="font-normal shrink-0 text-gray-300">
                      Thời gian:
                    </span>
                    <span className="break-word">{response?.time} tháng</span>
                  </div>
                  <div className="flex gap-1 py-[16px] items-center">
                    <span className="font-normal shrink-0 text-gray-300">
                      Ngày hết hạn:
                    </span>
                    <span className="break-word">
                      {moment(response?.date_expired).format(DATE_FORMAT)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-span-1 rounded-[6px] flex flex-col overflow-hidden">
                <h2 className="text-base bg-[#FFFFFF40] font-medium px-[16px] pt-[16px] pb-2.5">
                  Chức năng
                </h2>
                <div className="divide-y-[1px] bg-[#FFFFFF40] divide-gray-400 flex flex-col px-[16px] rounded-b-[6px] pb-[16px] max-h-[550px] overflow-auto">
                  {value?.map((item) => (
                    <div className={` ${'py-[16px] '} items-center break-word`}>
                      {item?.title}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Spin>
        </div>

        <div className="flex justify-center mt-10">
          <div className="flex w-[700px] gap-[16px] justify-center">
            <Button
              onClick={onClose}
              className="w-[200px] rounded-[54px] col-span-1 text-base leading-[18px]"
            >
              Đóng
            </Button>
            {isActive && !isTrial && (
              <>
                {isRenew && (
                  <Button
                    onClick={() => setOpenRenewModal(true)}
                    className=" w-[200px] rounded-[54px] col-span-1 text-base bg-green-500 hover:bg-green-600 leading-[18px]"
                  >
                    Gia hạn
                  </Button>
                )}

                {isUpgrade && (
                  <Button
                    onClick={() => setOpenUpgradeModal(true)}
                    className="w-[200px] rounded-[54px] text-base col-span-1 bg-yellow-500 hover:bg-yellow-600 leading-[18px]"
                  >
                    Nâng cấp
                  </Button>
                )}
              </>
            )}
            {isReActive && !isTrial && (
              <Button
                onClick={() =>
                  setOpenPayQuicklyModal({
                    open: true,
                    currentId: response?.package?.id,
                    mode: 'restart',
                  })
                }
                className="w-[200px] rounded-[54px] text-base col-span-1 bg-yellow-500 hover:bg-yellow-600 leading-[18px]"
              >
                Kích hoạt lại
              </Button>
            )}
          </div>
        </div>
      </ServiceModal>
      {openUpgradeModal && (
        <Upgrade
          serviceId={response?.service?.id}
          packageId={response?.package?.id}
          open={openUpgradeModal}
          onClose={() => setOpenUpgradeModal(false)}
          onSubmit={(currentId, upgradeId) =>
            setOpenPayQuicklyModal({
              open: true,
              currentId,
              upgradeId,
              mode: 'upgrade',
            })
          }
        />
      )}
      {openPayQuicklyModal?.open && (
        <PaymentQuickly
          currentId={openPayQuicklyModal?.currentId}
          upgradeId={openPayQuicklyModal?.upgradeId}
          open={openPayQuicklyModal?.open}
          onClose={() => setOpenPayQuicklyModal({ open: false })}
          mode={openPayQuicklyModal?.mode}
        />
      )}

      {openRenewModal && (
        <Renew
          serviceId={response?.service?.id}
          packageId={response?.package?.id}
          open={openRenewModal}
          onClose={() => setOpenRenewModal(false)}
          onSubmit={(currentId) =>
            setOpenPayQuicklyModal({ open: true, currentId, mode: 'extend' })
          }
        />
      )}
    </>
  );
};

export default UsedPackageDetail;
