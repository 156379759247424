export const PERMISSION_KEY = {
  PERMISSION_GROUP_VIEW_LIST: 'portal_permission_group_view_list',
  PERMISSION_GROUP_CREATE: 'portal_permission_group_create_permission',
  PERMISSION_GROUP_UPDATE: 'portal_permission_group_update_permission',
  PERMISSION_GROUP_DELETE: 'portal_permission_group_delete_permission',
  PERMISSION_GROUP_SEARCH: 'portal_permission_group_search_permission',
  PERMISSION_GROUP_DETAIL: 'portal_permission_group_detail_permission',
  PERMISSION_GROUP_CONFIG: 'portal_permission_group_config_permission',
  PERMISSION_GROUP_VIEW_LIST_MEMBER: 'portal_permission_group_view_list_member',
};
