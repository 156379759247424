import { useQuery } from '@tanstack/react-query';
import PaymentMethodApi from 'pages/Wallet/_api/admin';

import { useEffect } from 'react';
import { Button, Modal, Spin } from 'tera-dls';
import TransactionContent, {
  TransactionContentType,
} from '../TransactionDetail/TransactionContent';

interface IProps {
  open: boolean;
  onClose?: () => void;
  value: number;
  onSuccess?: () => void;
  invoiceCode?: string;
}

const PaymentDetailModal = (props: IProps) => {
  const { onClose, open, value: id, invoiceCode } = props;

  const { data, refetch, isLoading } = useQuery(
    ['get-transaction-detail', id],
    () => PaymentMethodApi.transactionDetail({ id }),
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    id && refetch();
  }, [id]);

  return (
    <>
      <Modal
        title={'Chi tiết giao dịch'}
        destroyOnClose
        closeIcon={false}
        onCancel={onClose}
        width={600}
        maskClosable={false}
        open={open}
        centered={true}
        footer={<Button onClick={onClose}>Đóng</Button>}
      >
        <Spin spinning={isLoading}>
          <TransactionContent
            {...{
              type: 'payment' as TransactionContentType,
              amount: data?.amount,
              transaction_date: data?.transaction_date,
              transaction_code: data?.transaction_code,
              payment_method: data?.methods,
              status: data?.status,
              invoice_code: invoiceCode,
            }}
          />
        </Spin>
      </Modal>
    </>
  );
};

export default PaymentDetailModal;
