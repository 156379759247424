import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { ArrowPathOutlined } from 'tera-dls';

interface TagInfoProps {
  name?: string;
  number?: string;
  cardName?: string;
}

function TagInfo(props: TagInfoProps) {
  const {
    transactionStore: { account, setStep },
  } = useStores();
  const { name, cardName, number } = props;
  return (
    <div className="p-4 flex items-center border rounded gap-x-4">
      <div className="flex justify-center items-center w-[60px] h-[60px] rounded-full bg-gray-200">
        <img src={account?.image_bank || ''} className="w-8 h-8" />
      </div>
      <div className="flex flex-col gap-y-[5px] flex-1">
        <p className="uppercase font-semibold text-xl line-clamp-1 break-word">
          {name}
        </p>
        <p className="line-clamp-1 break-word">
          <span className="text-gray-500">{number} -</span>{' '}
          <span className="text-gray-800">{cardName}</span>
        </p>
      </div>
      <ArrowPathOutlined
        className="w-8 h-8 text-blue-500 cursor-pointer"
        onClick={() => setStep(0)}
      />
    </div>
  );
}

export default observer(TagInfo);
