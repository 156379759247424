import { adminEndpoint, endpoint } from '_common/constants/common';
import api from 'states/drivers';

const ServicePackageEndpoint = `${endpoint}/portal/service`;
const ConfigPermissionEndpoint = `${adminEndpoint}/administrator/role`;

const ServicePackageApi = {
  getUsedList: async ({ params }) =>
    await api
      .get(`${ServicePackageEndpoint}/list`, params)
      .then((result) => result.data?.data),
  getRecommendedList: async ({ params }) =>
    await api
      .get(`${ServicePackageEndpoint}/list-availability`, params)
      .then((result) => result.data?.data),
  calculateOldPackage: async ({ params }) =>
    await api
      .post(`${ServicePackageEndpoint}/calculate-old-package`, params)
      .then((result) => result.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${ServicePackageEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
  getRegistrationDetail: async ({ params }) =>
    await api
      .get(`${ServicePackageEndpoint}/list-package`, params)
      .then((result) => result.data?.data),
  getListRoleHasPermission: async (role_id: number) =>
    await api
      .get(`${ConfigPermissionEndpoint}/role-has-permission-detail`, {
        role_id: role_id,
      })
      .then((result) => result?.data?.data),
};

export default ServicePackageApi;
