import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const LocationEndpoint = `${endpoint}/portal/business-location`;

const LocationApi = {
  getList: async ({ params }) =>
    await api
      .get(`${LocationEndpoint}/list`, params)
      .then((res) => res?.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${LocationEndpoint}/detail/${id}`)
      .then((res) => res?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${LocationEndpoint}/create`, params)
      .then((res) => res?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${LocationEndpoint}/update/${id}`, params)
      .then((res) => res?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${LocationEndpoint}/delete/${id}`)
      .then((res) => res?.data),
};

export default LocationApi;
