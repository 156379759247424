export const INVOICE_STATUS = {
  unpaid: 'Chưa thanh toán',
  paid: 'Đã thanh toán',
  in_process: 'Đang tiến hành',
  complete: 'Hoàn thành',
  fail: 'Thất bại',
};

export const INVOICE_TYPE = {
  active: 'Kích hoạt',
  upgrade: 'Nâng cấp',
  extend: 'Gia hạn',
  restart: 'Kích hoạt',
  recharge: 'Nạp tiền',
  withdrawal: 'Rút tiền',
};

export const INVOICE_STATUS_COLOR = {
  unpaid: 'yellow03',
  paid: 'blue03',
  in_process: 'indigo03',
  complete: 'green03',
  fail: 'red03',
};

export const TRANSACTION_TYPE = {
  1: 'Nạp tiền',
  2: 'Rút tiền',
  3: 'Thanh toán',
};
