import classNames from 'classnames';
import React from 'react';
import { ChevronRightOutlined } from 'tera-dls';

interface ItemProps extends React.HTMLAttributes<HTMLElement> {
  icon: JSX.Element;
  title: string;
  sub: string;
}

function Item(props: ItemProps) {
  const { icon, title, sub, className, ...rest } = props;
  return (
    <div
      className={classNames(
        'group cursor-pointer flex gap-x-2.5 items-center py-2.5 px-4 bg-gray-50 rounded-[6px] shadow-[0_2px_2px_0_rgba(0,0,0,0.1),0_-2px_2px_0_rgba(0,0,0,0.03)]',
        className,
      )}
      {...rest}
    >
      <span className="shrink-0">{icon}</span>
      <div>
        <h3 className="line-clamp-1 text-base text-gray-700">{title}</h3>
        <p className="line-clamp-2 text-base text-gray-500">{sub}</p>
      </div>
      <ChevronRightOutlined className="w-5 h-5 text-gray-300 shrink-0 group-hover:translate-x-1 transition-all" />
    </div>
  );
}

export default Item;
