import PaginationCustom from '_common/component/PaginationCustom';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PaginationProps, useDetectDevice } from 'tera-dls';
import TeraEditableCell from './TeraEditableCell';
import TeraEditableRow from './TeraEditableRow';
import TeraNormalTable from './TeraNormalTable';
import TeraTableContext from './TeraTableContext';
import {
  getHiddenColumnDataIndexes as getHiddenColumnDataKeys,
  mapDefaultIndexNumber,
} from './Util';
import { ITeraTableProps } from './_interfaces';
import { INDEX_NUMBER_KEY } from './constants';
import Summary from './containers/Summary';
import { mergeArrayObjectForSettingColumns } from '_common/utils';
import { customTwMerge } from 'tailwind-merge.config';
const convertColumns = (columns) => {
  return columns.map((column) => {
    const { width, unit, sorter, ...restColumn } = column;
    return {
      ...restColumn,
      width,
      sorter: Boolean(sorter),
      ...(width &&
        unit && { width: `${width}${unit === 'percent' ? '%' : unit}` }),
    };
  });
};
convertColumns;
const calculateDataSource = (page: number, pageSize: number, data: any[]) => {
  const cloned = _.cloneDeep(data);
  return cloned?.slice((page - 1) * pageSize, page * pageSize);
};
const calculateCurrentPage = ({ total, page, pageSize }) =>
  Math.ceil(total / pageSize) < page
    ? Math.ceil(total / pageSize) === 0
      ? 1
      : Math.ceil(total / pageSize)
    : page;
const TableTera = (props: ITeraTableProps) => {
  const {
    objectType,
    columns,
    mode = 'table',
    editable,
    rowKey = 'id',
    pagination,
    data,
    middleChildren,
    summary,
    loadingIndicator,
    hiddenColumns = [],
    disableShadow = false,
    // wrapperProps = {},
    ...restProps
  } = props;

  const mergePagination =
    typeof pagination === 'boolean' || typeof pagination === 'undefined'
      ? {}
      : pagination;

  const {
    onChange,
    defaultPageSize = 10,
    total: propTotal,
    to = 1,
    from = 10,
    current,
    pageSize,
    ...paginationProps
  } = mergePagination || {};
  const { isDesktop, isMobile, isTablet } = useDetectDevice();
  // const {
  //   columnSettingStore: { add, clear },
  // } = useStores();

  const [params, setParams] = useState({
    page: 1,
    pageSize: defaultPageSize,
  });

  const [dataSource, setDataSource] = useState([]);

  const dataProp = useMemo(() => {
    if (!data || !Array.isArray(data)) return [];
    return data?.filter((value) => !!value);
  }, [data]);

  useEffect(() => {
    if (dataProp?.length === 0 && current > 1) {
      onChange && onChange(current - 1, pageSize);
    }
  }, [dataProp, current]);

  const total = propTotal ?? dataProp?.length;
  const getDataSource = (data) =>
    data?.length <= params?.pageSize
      ? data
      : calculateDataSource(params.page, params.pageSize, data);

  useEffect(() => {
    const data = dataProp;
    setDataSource(pagination ? getDataSource(data) : data);
  }, [params, dataProp]);

  useEffect(() => {
    current && setParams((prevPrams) => ({ ...prevPrams, page: current }));
  }, [current]);

  useEffect(() => {
    pageSize && setParams((prevPrams) => ({ ...prevPrams, pageSize }));
  }, [pageSize]);

  // const { data: response } = useQuery(
  //   ['page-table-column', objectType],
  //   () => SystemTableConfigApi.getConfig({ object_type: objectType }),
  //   {
  //     enabled: !!objectType,
  //     staleTime: 300000,
  //     cacheTime: 300000,
  //     onSuccess: (data) => {
  //       if (!data) {
  //         clear(objectType);
  //         return;
  //       }
  //       if (data?.column_configs) {
  //         add(data.column_configs, objectType);
  //       }
  //     },
  //   },
  // );

  useEffect(() => {
    mode &&
      editable?.onEditableKeyChange &&
      editable.onEditableKeyChange(undefined);
  }, [mode]);

  // const defaultColumnConfig = response?.column_configs.map((item) => {
  //   delete item['dataIndex'];
  //   return { ...item };
  // });

  const columnConfig = useMemo(() => {
    const newColumns = mergeArrayObjectForSettingColumns(
      [],
      columns.map((item) => ({
        ...item,
        key: item?.key ? item.key : item?.dataIndex,
      })),
      'key',
    );
    return convertColumns(newColumns);
  }, [columns]);

  const hiddenColumnDataKeys = useMemo(
    (): Array<string> =>
      getHiddenColumnDataKeys({
        columns,
        isDesktop,
        isMobile,
        isTablet,
      }),
    [isDesktop, isMobile, isTablet],
  );
  const indexNumberObject = {
    key: INDEX_NUMBER_KEY,
    dataIndex: INDEX_NUMBER_KEY,
    order: Number.MIN_VALUE,
    width: 80,
    render: (_, __, index) => (params?.page - 1) * params?.pageSize + index + 1,
  };

  const finalColumns = useMemo(() => {
    const data = mapDefaultIndexNumber(columnConfig, indexNumberObject);
    return data.sort((a, b) =>
      (a.order ?? Number.MAX_VALUE) - (b.order ?? Number.MAX_VALUE) < 0
        ? -1
        : 1,
    );
  }, [columnConfig, params]);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const currentPage = calculateCurrentPage({ page, total, pageSize });
    const dataSource = calculateDataSource(currentPage, pageSize, dataProp);
    setDataSource(dataSource);
    setParams((prev) => ({
      ...prev,
      page: currentPage,
      pageSize,
    }));
    onChange && onChange(currentPage, pageSize);
  };
  const defaultFrom = (params?.page - 1) * params?.pageSize + 1;
  const defaultTo = params?.page * params?.pageSize;
  const paginationConfig = {
    ...(!!propTotal
      ? { to }
      : {
          to: total < params?.page * params?.pageSize ? total : defaultTo,
        }),
    ...(!!propTotal ? { from } : { from: defaultFrom }),
  };
  useEffect(() => {
    if (current || pageSize) return;
    const currentPage = calculateCurrentPage({
      page: params.page,
      total,
      pageSize: params.pageSize,
    });
    setParams((prev) => ({
      ...prev,
      page: currentPage,
      pageSize: params.pageSize,
    }));
  }, [total, params?.pageSize, current, pageSize]);

  const handleValuesChange = useCallback(
    _.debounce((value, values) => {
      if (!pagination) return editable?.onValuesChange(value, values);
      if (editable?.onValuesChange) {
        dataProp.splice(
          (params?.page - 1) * params?.pageSize,
          params?.pageSize,
        );
        dataProp.splice((params?.page - 1) * params?.pageSize, 0, ...values);
        editable?.onValuesChange(value, dataProp);
      }
    }, 0),
    [editable?.onValuesChange, params, dataProp],
  );

  return (
    <TeraTableContext
      {...restProps}
      columns={finalColumns}
      hiddenColumns={[...hiddenColumnDataKeys, ...hiddenColumns]}
      editable={{ ...editable, onValuesChange: handleValuesChange }}
      data={dataSource}
      rowKey={rowKey}
      id={objectType}
      summary={(records) => (
        <Summary
          records={records}
          summary={summary}
          loadingIndicator={loadingIndicator}
        />
      )}
      // bodyComponents={{
      //   wrapper: (props) => <Wrapper {...props} {...wrapperProps} />,
      // }}
    >
      <div className={customTwMerge(!disableShadow && 'shadow-md')}>
        {mode === 'editable-row' && <TeraEditableRow />}
        {mode == 'editable-cell' && <TeraEditableCell />}
        {mode == 'table' && <TeraNormalTable />}
        {middleChildren}
        {pagination && total >= defaultPageSize && (
          <PaginationCustom
            {...paginationConfig}
            total={Number(total) ?? 0}
            {...paginationProps}
            onChange={handleChangePage}
            defaultPageSize={defaultPageSize}
            current={params?.page}
            pageSize={params?.pageSize}
          />
        )}
      </div>
      {/* {pagination && <div className="pt-4" />} */}
    </TeraTableContext>
  );
};
export default TableTera;
