import { useEffect, useState } from 'react';
import { Tooltip } from 'tera-dls';
import ModalRemain from './ModalRemain';

function ModuleList({ modules, count = 3 }) {
  const [isRemain, setIsRemain] = useState<boolean>(false);
  const [remain, setRemain] = useState<number>(0);
  const [moduleList, setModuleList] = useState([]);

  useEffect(() => {
    const length = modules?.length;
    if (length > count) {
      setRemain(length - count);
    } else {
      setRemain(0);
    }
    const modulesCopy = [...(modules ?? [])];
    const moduleSliced = modulesCopy.slice(0, 3);
    setModuleList(moduleSliced);
  }, [modules, count]);

  return (
    <div className="flex gap-x-1 items-center">
      {moduleList?.map((module) => (
        <Tooltip title={module?.module?.title} placement="bottom">
          <div
            className={`w-[15px] h-[15px] rounded bg-gray-500 uppercase text-white text-[10px] flex items-center justify-center ${module?.module?.color}`}
          >
            {module?.module?.code?.slice(0, 1)}
          </div>
        </Tooltip>
      ))}

      {!!remain && (
        <span
          className="text-blue-600 cursor-pointer"
          onClick={() => setIsRemain(true)}
        >
          +{remain}
        </span>
      )}

      {isRemain && (
        <ModalRemain
          open={isRemain}
          onCancel={() => setIsRemain(false)}
          modules={modules}
        />
      )}
    </div>
  );
}

export default ModuleList;
