const CheckedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={'1em'}
    height={'1em'}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M2.71 16.378a.34.34 0 0 1-.243-.101l-1.6-1.602a.343.343 0 0 1 .485-.485l1.6 1.602a.343.343 0 0 1-.242.586Z"
    />
    <path
      fill="#fff"
      d="M1.109 16.378a.343.343 0 0 1-.243-.586l1.6-1.602a.343.343 0 0 1 .486.485L1.35 16.277a.343.343 0 0 1-.242.1ZM48.536 31.686l-.457 1.048 2.812 1.227.457-1.049-2.812-1.226ZM49.423 3.57l-2.645 1.555.58.986 2.644-1.555-.58-.986ZM5.357 6.233 5.003 7.32l2.916.951.354-1.088-2.916-.95ZM8.77 24.476 6.26 26.234l.655.937 2.512-1.758-.655-.937ZM54.845 20.447l-.105 1.139 2.988.276.105-1.14-2.988-.275ZM57.888 12.253a.34.34 0 0 1-.242-.1l-1.6-1.602a.343.343 0 1 1 .484-.485l1.6 1.601a.343.343 0 0 1-.242.586Z"
    />
    <path
      fill="#fff"
      d="M56.288 12.253a.343.343 0 0 1-.242-.586l1.6-1.601a.342.342 0 0 1 .564.108.344.344 0 0 1-.079.377l-1.6 1.602a.343.343 0 0 1-.243.1Z"
    />
    <path
      fill="#fff"
      d="M29.712 37c10.073 0 18.238-8.17 18.238-18.25S39.785.5 29.712.5C19.64.5 11.476 8.67 11.476 18.75S19.64 37 29.712 37Z"
      opacity={0.4}
    />
    <path
      fill="#fff"
      d="M29.713 31.51c7.044 0 12.754-5.713 12.754-12.762 0-7.05-5.71-12.764-12.754-12.764S16.958 11.7 16.958 18.748c0 7.049 5.71 12.763 12.755 12.763Z"
    />
    <path
      fill="#0E9F6E"
      d="M27.106 24.223a.742.742 0 0 1-.536-.229l-4.06-4.228a.742.742 0 1 1 1.071-1.03l3.563 3.71 9.313-8.36a.744.744 0 1 1 .992 1.106l-9.847 8.84a.74.74 0 0 1-.496.191Z"
    />
  </svg>
);
export default CheckedIcon;
