import React from 'react';
import { Modal } from 'tera-dls';
import ModuleItem from '../ModuleItem';

interface ModalRemainProps {
  open: boolean;
  onCancel: () => void;
  modules: any;
}

function ModalRemain({ open, onCancel, modules }: ModalRemainProps) {
  return (
    <Modal
      title="Danh sách ứng dụng"
      centered={true}
      open={open}
      width={700}
      closeIcon={false}
      onCancel={onCancel}
      cancelText="Đóng"
      okButtonProps={{ className: 'hidden' }}
    >
      {modules?.map((module) => (
        <ModuleItem data={module?.module} />
      ))}
    </Modal>
  );
}

export default ModalRemain;
