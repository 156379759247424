import { IRouteProps } from '_common/interface/router';
import Application from '.';
import { APPLICATION_URL } from './constants/url';

export const ApplicationRouter: IRouteProps[] = [
  {
    key: APPLICATION_URL.list.key,
    path: APPLICATION_URL.list.shortenUrl,
    component: <Application />,
  },
];
