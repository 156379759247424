export const PERMISSION_KEY = {
  MEMBER_INFORMATION_VIEW_LIST: 'crm_member_information_view_member_list',
  MEMBER_INFORMATION_VIEW_NOT_PERMISSION:
    'crm_member_information_view_not_permission',
  MEMBER_INFORMATION_VIEW_DETAIL: 'crm_member_information_view_member_detail',
  MEMBER_INFORMATION_CHANGING_PASSWORD:
    'crm_member_information_view_member_detail',
  MEMBER_INFORMATION_AUTHOR: 'crm_member_information_author_member',
  MEMBER_INFORMATION_UPDATE: 'crm_member_information_update_member',
  MEMBER_INFORMATION_DELETE: 'crm_member_information_delete_member',
  MEMBER_INFORMATION_ADD_PERMISSION: 'crm_member_information_add_permission',
};
