import { IRouteProps } from '_common/interface/router';
import Wallet from '.';
import { WALLET_URL } from './constants/url';

export const WalletRouter: IRouteProps[] = [
  {
    key: WALLET_URL.list.key,
    path: WALLET_URL.list.shortenUrl,
    component: <Wallet />,
  },
];
