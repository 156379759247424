import RangePicker from '_common/dof/Control/RangePicker';

function PrimaryRangePicker(props) {
  return (
    <RangePicker
      className="border-[#76A9FA] rounded-full bg-white min-w-[200px]"
      {...props}
    />
  );
}

export default PrimaryRangePicker;
