import { useMutation } from '@tanstack/react-query';
import DefaultImage from '_common/component/DefaultImage';
import { useStores } from '_common/hooks';
import { AuthApi } from 'states/api';
import { EnvelopeOutlined, notification } from 'tera-dls';
import { ReactComponent as IconExit } from 'styles/images/Icons/IconExit.svg';
import { observer } from 'mobx-react-lite';

const Content = () => {
  const classItem =
    'p-[6px] [&:not(:last-child)]:border-b hover:bg-gray-50 cursor-pointer';

  const {
    authStore: { user, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: () => {
      clear();
    },
    onError: (error: any) => {
      clear();
      const errorMessage = error?.msg;
      notification.error({
        message: errorMessage,
      });
    },
  });

  return (
    <div className="p-2.5 rounded-2.5 w-[200px] flex flex-col gap-y-5">
      <div className="flex flex-col gap-y-[14px]">
        <h3 className="text-blue-800">Tài khoản</h3>
        <div className="flex flex-col gap-y-[7px]">
          <div className="flex items-center gap-x-[7px]">
            <DefaultImage
              src={user?.avatar_url}
              alt={user?.full_name}
              className="w-10 h-10 rounded-full border"
            />
            <div className="flex flex-col justify-center gap-x-[5px]">
              <span className="text-blue-500 line-clamp-1 break-word">
                {user?.full_name}
              </span>
              <span className="text-[10px] text-gray-500 line-clamp-1 break-word">
                {user?.role_name}
              </span>
            </div>
          </div>
          <p className="flex items-center gap-x-[5px]">
            <EnvelopeOutlined className="w-4 h-4" />
            <span className="line-clamp-1 break-word">{user?.email}</span>
          </p>
        </div>
      </div>
      <div>
        <h3 className="text-blue-800 mb-[5px]">Khác</h3>
        <ul>
          <li className={classItem}>Chỉnh thông tin</li>
          <li className={classItem}>Đổi mật khẩu</li>
        </ul>
      </div>
      <div>
        <p
          className="flex gap-x-[5px] text-red-500 itemns-center p-[6px] hover:bg-gray-50 cursor-pointer"
          onClick={() => onLogout()}
        >
          <IconExit />
          <span>Thoát</span>
        </p>
      </div>
    </div>
  );
};

export default observer(Content);
