import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ImageBackground from '_common/component/ImageBackground';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX } from '_common/constants/common';
import {
  messageError,
  messageValidate,
  messageWarning,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import InputPassword from '_common/dof/Control/InputPassword';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectJobTitle from '_common/dof/Select/SelectJobTitle';
import SelectPermissionGroup from '_common/dof/Select/SelectPermissionGroup';
import SelectPosition from '_common/dof/Select/SelectPosition';
import useConfirm from '_common/hooks/useConfirm';
import { MemberApi } from 'pages/EmployeeInformation/api';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Modal, Row, notification } from 'tera-dls';

interface IProps {
  open: boolean;
  onClose?: () => void;
  formKey?: any;
  value?: any;
  onSuccess?: (value: any) => void;
}

const EmployeeForm = (props: IProps) => {
  const { onClose, open, value: id, onSuccess } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const [avatar, setAvatar] = useState<any>();
  const isDirty = form?.formState?.isDirty;

  const { mutate: mutateAction } = useMutation(
    (variables: any) =>
      variables?.id ? MemberApi.update(variables) : MemberApi.create(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onClose();
          queryClient.invalidateQueries(['get-member-list']);
          notification.success({
            message: res?.msg,
          });
          !id &&
            onSuccess &&
            onSuccess({ id: res?.data?.id, name: res?.data?.full_name });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error.data });
      },
    },
  );

  const { data: detail, refetch } = useQuery(
    ['get-member-detail', id],
    () => MemberApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
      onError(error: any) {
        const errorMessage = error?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  useEffect(() => {
    id && refetch();
  }, [id]);

  useEffect(() => {
    if (!detail) return;
    setAvatar({ url: detail.avatar_url });
    form.reset({
      username: detail.username,
      full_name: detail.full_name,
      email: detail.email,
      job_title: detail.job_title,
      department: detail.department,
      phone: detail.phone,
      ...(detail?.role?.code === 'user_default' &&
      !detail?.role?.business_id &&
      detail?.role?.type === 'user'
        ? {}
        : {
            role_id: detail?.role?.id,
          }),
    });
  }, [detail]);

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (value) => {
    mutateAction({
      ...(id && { id }),
      params: {
        username: value.username,
        department: value.department,
        email: value.email,
        full_name: value.full_name,
        job_title: value.job_title,
        password: value.password,
        phone: value.phone,
        role_id: value?.role_id,
        ...(avatar?.url && {
          file_upload: {
            url: avatar?.url,
          },
        }),
        type: 'member',
      },
    });
  };

  return (
    <Modal
      width={1200}
      open={open}
      okText="Đồng ý"
      cancelText="Huỷ"
      title={id ? 'Sửa thành viên' : 'Thêm thành viên'}
      destroyOnClose
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={handleCloseConfirm}
      centered={true}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <Row className="grid grid-cols-2 gap-[16px]">
          <Col className="flex flex-col gap-[16px]">
            <div className="grid place-items-center mb-[66px] mt-2.5">
              <ImageBackground
                onChange={setAvatar}
                value={avatar}
                isShowBtnDelete
                object_key={'portal_employee_information'}
                folder={'portal'}
              />
            </div>
            <FormTeraItem
              name="username"
              label="Tên đăng nhập"
              rules={[
                {
                  required: messageValidate.emptyText,
                  pattern: {
                    value: new RegExp(REGEX.USERNAME),
                    message: messageValidate.username,
                  },
                  minLength: {
                    value: 1,
                    message: 'Tên đăng nhập tối thiểu 1 ký tự',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Tên đăng nhập không vượt quá 50 ký tự',
                  },
                },
              ]}
            >
              <Input
                placeholder="Vui lòng nhập"
                className="py-[13px]"
                disabled={!!id}
              />
            </FormTeraItem>
            {!id ? (
              <>
                <FormTeraItem
                  name="password"
                  label="Mật khẩu"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                      pattern: {
                        value: new RegExp(REGEX.PASSWORD),
                        message: messageValidate.password,
                      },
                      minLength: {
                        value: 8,
                        message: 'Mật khẩu tối thiểu 8 ký tự',
                      },
                      maxLength: {
                        value: 16,
                        message: 'Mật khẩu không vượt quá 16 ký tự',
                      },
                    },
                  ]}
                >
                  <InputPassword
                    placeholder="Vui lòng nhập"
                    className="py-[13px]"
                  />
                </FormTeraItem>
                <FormTeraItem
                  name="re_password"
                  label="Xác nhận mật khẩu"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                      pattern: {
                        value: new RegExp(REGEX.PASSWORD),
                        message: messageValidate.password,
                      },
                      minLength: {
                        value: 8,
                        message: 'Mật khẩu tối thiểu 8 ký tự',
                      },
                      maxLength: {
                        value: 16,
                        message: 'Mật khẩu không vượt quá 16 ký tự',
                      },
                      validate: {
                        existed: (value, values) => {
                          const password = values?.password.trim();
                          if (value.trim() !== password) {
                            return 'Nhập lại mật khẩu không trùng với mật khẩu mới';
                          }
                        },
                      },
                    },
                  ]}
                >
                  <InputPassword
                    placeholder="Vui lòng nhập"
                    className="py-[13px]"
                  />
                </FormTeraItem>
              </>
            ) : (
              <>
                <FormTeraItem
                  label="Họ và tên"
                  rules={[{ required: 'Vui lòng nhập dữ liệu' }]}
                  name="full_name"
                >
                  <Input
                    placeholder="Vui lòng nhập"
                    maxLength={100}
                    className="py-[13px]"
                  />
                </FormTeraItem>
              </>
            )}
            <FormTeraItem
              name="role_id"
              label="Phân quyền"
              rules={[
                {
                  required: messageValidate.emptySelect,
                },
              ]}
            >
              <SelectPermissionGroup
                paramsApi={{ include_id: detail?.role?.id }}
              />
            </FormTeraItem>
          </Col>
          <Col className="flex flex-col gap-[16px]">
            {!id && (
              <FormTeraItem
                label="Họ và tên"
                rules={[{ required: 'Vui lòng nhập dữ liệu' }]}
                name="full_name"
              >
                <Input
                  placeholder="Vui lòng nhập"
                  maxLength={100}
                  className="py-[13px]"
                />
              </FormTeraItem>
            )}
            <FormTeraItem
              label="Email"
              name="email"
              rules={[
                {
                  required: 'Vui lòng nhập dữ liệu',
                  pattern: {
                    value: REGEX.EMAIL,
                    message: 'Sai định dạng email',
                  },
                },
              ]}
            >
              <Input placeholder="Vui lòng nhập" className="py-[13px]" />
            </FormTeraItem>
            <FormTeraItem
              label="Số điện thoại"
              name="phone"
              rules={[
                {
                  pattern: {
                    value: REGEX.PHONE_NUMBER,
                    message: 'Số điện thoại không hợp lệ',
                  },
                },
                {
                  minLength: {
                    value: 8,
                    message: 'Nhập tối thiểu 8 kí tự',
                  },
                },
                { required: 'Vui lòng nhập dữ liệu' },
              ]}
            >
              <Input maxLength={20} className="py-[13px]" />
            </FormTeraItem>
            <FormTeraItem name={'job_title'} label="Chức danh">
              <SelectJobTitle
                placeholder="Vui lòng chọn"
                className="py-[4px]"
              />
            </FormTeraItem>
            <FormTeraItem name={'department'} label="Phòng ban/ Bộ phận">
              <SelectPosition
                placeholder="Vui lòng chọn"
                className="py-[4px]"
              />
            </FormTeraItem>
          </Col>
        </Row>
      </FormTera>
    </Modal>
  );
};

export default EmployeeForm;
