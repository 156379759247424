import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX } from '_common/constants/common';
import { messageValidate, messageWarning } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectBank from '_common/dof/Select/SelectBank';
import useConfirm from '_common/hooks/useConfirm';
import PaymentMethodApi from 'pages/Wallet/_api/admin';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification, Spin } from 'tera-dls';

interface ModalCreateProps {
  open: boolean;
  id: number;
  onClose: () => void;
  onRefetch: () => void;
}

function ModalCreate(props: ModalCreateProps) {
  const { open, id, onClose, onRefetch } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const {
    formState: { isDirty },
  } = form;

  const detailCardBank = useQuery(
    ['get-card-bank-detail', id],
    () => PaymentMethodApi.detailCardBank({ id }),
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const mutationBank = useMutation(
    (params: any) => {
      if (id) return PaymentMethodApi.updateCardBank({ id, params });
      return PaymentMethodApi.createCardBank({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onClose();
          onRefetch();
          queryClient.invalidateQueries(['get-card-bank-list']);
          queryClient.invalidateQueries(['get-payment-method-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (mutationBank.isLoading) return;
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => onClose(),
      });
    } else onClose();
  };

  const handleSubmitForm = (values) => {
    const data = {
      ...values,
      is_card_system: 1,
      object_type: 1,
    };

    mutationBank.mutate(data);
  };

  useEffect(() => {
    if (detailCardBank.data?.[0]) {
      const data = {
        card_type_id: detailCardBank.data?.[0]?.card_type_id,
        account_number: detailCardBank.data?.[0]?.account_number,
        cardholder: detailCardBank.data?.[0]?.cardholder,
      };

      form.reset(data);
    }
  }, [id, detailCardBank.data]);

  useEffect(() => {
    detailCardBank.refetch();
  }, [id]);

  return (
    <Modal
      closeIcon={false}
      open={open}
      okText="Đồng ý"
      cancelText="Hủy"
      title={id ? 'Sửa thông tin thẻ' : 'Thêm thông tin thẻ'}
      width={700}
      confirmLoading={mutationBank.isLoading}
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
    >
      <Spin spinning={detailCardBank.isLoading && !!id}>
        <FormTera form={form} onSubmit={handleSubmitForm}>
          <FormTeraItem
            label="Tên ngân hàng"
            name="card_type_id"
            rules={[
              {
                required: messageValidate.emptySelect,
              },
            ]}
          >
            <SelectBank />
          </FormTeraItem>
          <FormTeraItem
            label="Người thụ hưởng"
            name="cardholder"
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Input maxLength={100} />
          </FormTeraItem>
          <FormTeraItem
            label="Số tài khoản"
            name="account_number"
            rules={[
              {
                required: messageValidate.emptyText,
              },
              {
                pattern: {
                  value: new RegExp(REGEX.CARD_NUMBER),
                  message: messageValidate.format,
                },
              },
            ]}
          >
            <Input maxLength={20} />
          </FormTeraItem>
        </FormTera>
      </Spin>
    </Modal>
  );
}

export default ModalCreate;
