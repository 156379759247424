import Balance from './Balance';
import General from './General';
import History from './History';
import Revenue from './Revenue';

function Statistic() {
  return (
    <div className="flex flex-col gap-y-8">
      <Balance />
      <div className="pt-[23px] grid grid-cols-9 gap-x-[30px] items-start">
        <div className="col-span-4">
          <General />
        </div>
        <Revenue />
      </div>
      <History />
    </div>
  );
}

export default Statistic;
