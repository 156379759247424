import { useQuery } from '@tanstack/react-query';
import { TParamsApiDof } from '_common/dof/interfaces';
import { useDebounce } from '_common/hooks/useDebounce';
import PermissionGroupApi from 'pages/PermissionGroup/_api';
import { forwardRef, useState } from 'react';
import { OptionProps, SelectProps } from 'tera-dls';
import SelectEntity from '../SelectEntity';

interface SelectPermissionGroupProps extends SelectProps {
  placeholder?: string;
  paramsApi?: TParamsApiDof;
}

const SelectPermissionGroup = forwardRef(
  (
    {
      placeholder = 'Vui lòng chọn',
      paramsApi,
      ...props
    }: SelectPermissionGroupProps,
    ref,
  ) => {
    const [searchPermissionGroup, setSearchPermissionGroup] = useState('');
    const searchPermissionGroupDebounce = useDebounce(
      searchPermissionGroup,
      300,
    );
    const paramsQuery = {
      limit: 10,
      page: 1,
      name: searchPermissionGroupDebounce,
      ...paramsApi,
    };
    const { data: listPermissionGroup } = useQuery(
      ['get-permission-group-list', paramsQuery],
      () => {
        const params = {
          ...paramsQuery,
        };
        return PermissionGroupApi.getList({ params });
      },
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const optionsPermissionGroup: OptionProps[] =
      listPermissionGroup?.data?.map((permission) => ({
        label: `${permission?.code} - ${permission?.title}`,
        value: permission?.id,
      })) ?? [];

    return (
      <SelectEntity
        allowClear
        showSearch
        labelInValue
        onSearch={setSearchPermissionGroup}
        placeholder={placeholder}
        options={optionsPermissionGroup}
        {...props}
        ref={ref}
      />
    );
  },
);

export default SelectPermissionGroup;
