import { PERMISSION_KEY } from './permission';

export const EMPLOYEE_INFORMATION_URL = {
  list: {
    key: PERMISSION_KEY.MEMBER_INFORMATION_VIEW_LIST,
    path: '/employee/information',
    shortenUrl: 'information',
  },
  notPermission: {
    key: PERMISSION_KEY.MEMBER_INFORMATION_VIEW_NOT_PERMISSION,
    path: '/employee/not-permission',
    shortenUrl: 'not-permission',
  },
};
