import React from 'react';

import 'react-notifications/lib/notifications.css';

import Root from './Root';
function App() {
  return <Root />;
}

export default App;
