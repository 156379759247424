import Select from '_common/dof/Control/Select';

function PrimarySelect(props) {
  return (
    <Select
      placeholder="So sánh với"
      className="border-[#76A9FA] rounded-full bg-white min-w-[200px]"
      {...props}
    />
  );
}

export default PrimarySelect;
