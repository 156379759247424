import { useQuery } from '@tanstack/react-query';
import { copyText } from '_common/utils';
import { renderDescription } from 'pages/EmployeeInformation/containers/Detail';
import WalletApi from 'pages/Wallet/_api';
import PaymentMethodApi from 'pages/Wallet/_api/admin';
import { useEffect, useState } from 'react';
import {
  Button,
  DocumentDuplicateOutlined,
  ExclamationTriangleOutlined,
  formatCurrency,
  Modal,
  QrCodeOutlined,
  Spin,
  Tooltip,
} from 'tera-dls';

interface IProps {
  open: boolean;
  onClose?: () => void;
  amount: number;
}
const PaymentTransferModal = (props: IProps) => {
  const { onClose, open, amount } = props;
  const [openQR, setOpenQR] = useState<any>({ open: false });

  const {
    data: response,
    refetch,
    isLoading: loadingData,
  } = useQuery(
    ['get-payment-method'],
    () => PaymentMethodApi.getListPaymentMethod({ params: {} }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const {
    data: qr,
    refetch: refetchQR,
    isLoading,
  } = useQuery(
    ['get-payment-method-QR', openQR?.value?.id, amount],
    () =>
      WalletApi.getQr({
        params: { card_id: openQR?.value?.id, amount },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!openQR?.value,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    openQR?.open && refetchQR();
  }, [openQR?.value, openQR?.open]);

  return (
    <>
      <Modal
        title={'Thanh toán'}
        destroyOnClose
        closeIcon={false}
        onCancel={onClose}
        width={1000}
        maskClosable={false}
        open={open}
        centered={true}
        footer={<Button onClick={onClose}>Đóng</Button>}
      >
        <Spin spinning={loadingData}>
          <div className="flex flex-col gap-[16px]">
            <div className="text-blue-700 text-[14px] leading-[20px] text-center mt-2.5">
              Vui lòng thanh toán qua số tài khoản hoặc quét mã QR bằng ứng dụng
              ngân hàng hoặc ví điện tử
            </div>
            <div className="grid grid-cols-2 gap-[16px] h-[575px] overflow-auto">
              {response?.data?.map((item) => (
                <div
                  key={item?.id}
                  className="rounded-[10px] border-[1px] border-gray-400 p-[16px] flex flex-col gap-2.5"
                >
                  <div className="flex gap-2.5">
                    <img
                      src={item?.card_type?.image_bank}
                      className="w-[45px] h-[45px]"
                    />
                    <div className="flex flex-col justify-between break-word">
                      <span className="font-medium text-sm line-clamp-1">
                        {item?.card_type?.card_name}
                      </span>
                      <span className="text-gray-500 line-clamp-1">
                        {item?.card_type?.description}
                      </span>
                    </div>
                  </div>
                  <div className="grid gap-2.5">
                    {renderDescription(
                      [
                        {
                          title: 'Số tài khoản',
                          value: item?.account_number,
                        },
                        {
                          title: 'Người thụ hưởng',
                          value: item?.cardholder,
                        },
                      ],
                      120,
                    )}
                  </div>
                  <div className="flex justify-end">
                    <Button
                      onClick={() => setOpenQR({ open: true, value: item })}
                      className="px-[24px] py-[8px]"
                    >
                      <QrCodeOutlined className="w-5" />
                      <span className="text-[13px] leading-[15px] font-medium">
                        QR code
                      </span>
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Spin>
      </Modal>
      <Modal
        title={'Quét mã QR'}
        destroyOnClose
        closeIcon={false}
        width={800}
        maskClosable={false}
        open={openQR?.open}
        centered={true}
        footer={
          <Button onClick={() => setOpenQR({ open: false })}>Đóng</Button>
        }
      >
        <div className="flex flex-col items-center gap-2.5">
          <div className="flex justify-center">
            {isLoading ? (
              <Spin />
            ) : qr?.code === '00' ? (
              <img
                src={qr?.data?.qrDataURL}
                alt="qr-recharge"
                className="w-[600px]"
              />
            ) : (
              <div className="flex items-center gap-x-1">
                <ExclamationTriangleOutlined className="w-10 h-10 text-red-500" />
                <span>Lỗi hiển thị mã QR</span>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1 w-[600px]">
            <div className="flex justify-between gap-10">
              <span className="text-base text-gray-500 shrink-0">
                Số tài khoản
              </span>
              <span className="font-semibold text-base flex gap-1 items-center">
                {openQR?.value?.account_number}
                <Tooltip placement="top" title="Sao chép">
                  <DocumentDuplicateOutlined
                    className="w-5 h-5 text-blue-600 shrink-0 cursor-pointer"
                    onClick={() => copyText(openQR?.value?.account_number)}
                  />
                </Tooltip>
              </span>
            </div>
            <div className="flex justify-between gap-10">
              <span className="text-base text-gray-500 shrink-0">
                Người thụ hưởng
              </span>
              <span className="font-semibold text-base flex gap-1 items-center">
                {openQR?.value?.cardholder}
                <Tooltip placement="top" title="Sao chép">
                  <DocumentDuplicateOutlined
                    className="w-5 h-5 text-blue-600 shrink-0 cursor-pointer"
                    onClick={() => copyText(openQR?.value?.cardholder)}
                  />
                </Tooltip>
              </span>
            </div>
            <div className="flex justify-between gap-10">
              <span className="text-base text-gray-500 shrink-0">
                Số tiền phải nộp
              </span>
              <span className="text-base text-green-500 font-semibold">
                {formatCurrency(amount ?? 0)}
              </span>
            </div>
            <div className="flex justify-between gap-10">
              <span className="text-base text-gray-500  shrink-0">
                Nội dung
              </span>
              <span className="font-semibold text-base">
                Thanh toán hoá đơn cho công ty Tera Solutions
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaymentTransferModal;
