import { useQuery } from '@tanstack/react-query';
import InvoiceApi from 'pages/Invoice/api';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import InvoiceDetailContent from './InvoiceDetailContent';

const InvoiceDetail = () => {
  const { id } = useParams();

  const {
    data: response,
    refetch,
    isLoading,
  } = useQuery(
    ['get-invoice-detail', id],
    () =>
      InvoiceApi.getDetail({
        id,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    id && refetch();
  }, [id]);

  return <InvoiceDetailContent value={response} loading={isLoading} />;
};

export default InvoiceDetail;
