import React from 'react';
import Statistic from './containers/Statistic';
import Bank from './containers/Bank';

function Wallet() {
  return (
    <div className="flex flex-col gap-y-4 p-5">
      <div className="grid grid-cols-3 gap-x-[30px]">
        <div className="col-span-2">
          <Statistic />
        </div>
        <div className="col-span-1">
          <Bank />
        </div>
      </div>
    </div>
  );
}

export default Wallet;
