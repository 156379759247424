import React, { forwardRef } from 'react';
import { SearchProps } from 'tera-dls';
import { Controller } from 'react-hook-form';
import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import { customTwMerge } from 'tailwind-merge.config';
import SearchTera from '_common/component/UIV2/Search';

const Search = forwardRef(({ ...props }: SearchProps) => {
  const { form } = useTeraForm();
  const { item, config, rules } = useTeraFormItem();
  const { control } = form;

  return (
    <Controller
      control={control}
      defaultValue=""
      {...item}
      rules={rules}
      render={({ field }) => (
        <SearchTera
          data-object_type={item?.object_type}
          data-object_id={item?.object_id}
          placeholder="Vui lòng nhập"
          {...field}
          {...props}
          {...config?.field}
          className={customTwMerge(
            'w-full',
            props.className,
            config.class_name,
          )}
        />
      )}
    />
  );
});

export default Search;
