export const TransactionStatus = {
  1: 'Hoàn thành',
  2: 'Thất bại',
  3: 'Đang xử lý',
  4: 'Báo lỗi',
};

export const TransactionStatusColor = {
  1: 'green03',
  2: 'gray01',
  3: 'yellow03',
  4: 'red03',
};

export const TransactionMethod = {
  1: 'Chuyển khoản',
  2: 'Ví điện tử',
  3: 'Ví Tera',
};

export const TransactionType = {
  1: 'Nạp tiền',
  2: 'Rút tiền',
  3: 'Thanh toán',
};

export const TransactionTypeStatistic = {
  1: 'recharge',
  2: 'withdraw',
  3: 'payment',
};

export const TransactionStatistic = {
  recharge: 'Nạp tiền',
  withdraw: 'Rút tiền',
  payment: 'Thanh toán',
};
