import { DATE_TIME_FORMAT } from '_common/constants/common';
import FormTera from '_common/dof/FormTera';
import _ from 'lodash';
import moment from 'moment';
import CartTable from 'pages/Cart/containers/CartTable';
import { renderDescription } from 'pages/EmployeeInformation/containers/Detail';
import {
  INVOICE_STATUS,
  INVOICE_STATUS_COLOR,
  INVOICE_TYPE,
  TRANSACTION_TYPE,
} from 'pages/Invoice/constants';
import { INVOICE_URL } from 'pages/Invoice/url';
import PaymentDetailModal from 'pages/Payment/containers/PaymentDetailModal';
import PaymentInformation from 'pages/Payment/containers/PaymentInformation';
import PaymentMethod from 'pages/Payment/containers/PaymentMethod';
import PaymentTransferModal from 'pages/Payment/containers/PaymentTransferModal';
import PaymentWalletModal from 'pages/Payment/containers/PaymentWalletModal';
import TransactionModal from 'pages/Payment/containers/TransactionDetail/TransactionModal';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowLeftOutlined,
  Col,
  formatCurrency,
  Row,
  Spin,
  Tag,
} from 'tera-dls';

interface IProps {
  value: any;
  loading: boolean;
}
const InvoiceDetailContent = (props: IProps) => {
  const { value, loading } = props;

  const navigate = useNavigate();

  const form = useForm({ mode: 'onChange' });
  const location = useLocation();
  const { type } = location?.state ?? {};

  const [openTransferModal, setOpenTransferModal] = useState<boolean>(false);
  const [openTransaction, setOpenTransaction] = useState<any>(false);
  const [openWalletModal, setOpenWalletModal] = useState<boolean>(false);
  const [openTransactionModal, setOpenTransactionModal] = useState<any>({
    open: false,
  });
  openTransaction;
  const disabledAllowPay =
    !Boolean(value?.allow_pay) || value?.status === 'fail';

  useEffect(() => {
    if (value) {
      form.setValue('methods', String(value?.methods ?? 1));
    } else {
      form.setValue('methods', '1');
    }
    form.setValue('rule', true);
  }, [value]);

  const handlePaymentWithTransfer = () => {
    form.setValue('methods', '1');
    setOpenTransferModal(true);
  };

  const handlePaymentWithWallet = () => {
    form.setValue('methods', '3');
    !!form.watch('rule_wallet') && setOpenWalletModal(true);
  };

  const removeLocationState = () => {
    navigate(location.pathname, { state: { type: '1' }, replace: true });
  };
  const handleOpenTransaction = () => {
    setOpenTransaction(true);
    removeLocationState();
  };

  useEffect(() => {
    if (!type) return;
    form.setValue('rule', true);
    if (type === 'created_invoice_transfer') {
      handlePaymentWithTransfer();
      removeLocationState();
    } else if (type === 'created_invoice_wallet') {
      handlePaymentWithWallet();
    } else if (type === 'created_invoice_withDrawal') {
      handleOpenTransaction();
    }
  }, [type, form.watch('rule_wallet')]);

  const invoice = [
    {
      key: 'code',
      title: 'Mã hoá đơn',
      value: value?.code,
    },
    {
      key: 'amount',
      title: 'Tổng giá trị',
      value: formatCurrency(value?.total_amount ?? 0),
    },
    {
      key: 'transaction_type',
      title: 'Loại giao dịch',
      value: TRANSACTION_TYPE[value?.transaction_type],
    },
    {
      key: 'created_date',
      title: 'Ngày tạo đơn',
      value: moment(value?.created_at).format(DATE_TIME_FORMAT),
    },
    {
      key: 'type',
      title: 'Loại hóa đơn',
      value: INVOICE_TYPE[value?.type],
    },
    {
      key: 'note',
      title: 'Ghi chú',
      value: value?.note,
    },

    {
      key: 'status',
      title: 'Trạng thái',
      value: (
        <Tag color={INVOICE_STATUS_COLOR[value?.status]}>
          {INVOICE_STATUS[value?.status]}
        </Tag>
      ),
    },
  ];

  const information = [
    {
      key: 'name',
      title: 'Họ tên',
      value: value?.customer_name,
    },
    {
      key: 'email',
      title: 'Email',
      value: value?.customer_email,
    },
    {
      key: 'phone',
      title: 'Số điện thoại',
      value: value?.customer_phone,
    },
    {
      key: 'temp',
      title: '',
      value: <div className="h-[20px]" />,
    },
  ];

  const address = [
    {
      key: 'tax_number',
      title: 'Mã số thuế',
      value: value?.number_tax,
    },
    {
      key: 'company_name',
      title: 'Tên công ty',
      value: value?.company_name,
    },
    {
      key: 'address',
      title: 'Địa chỉ đầy đủ',
      value: value?.address,
    },
    {
      key: 'email_receive',
      title: 'Email nhận hoá đơn',
      value: value?.email_receive,
    },
  ];

  const handleSubmit = (values) => {
    const funcObject = {
      1: () => setOpenTransferModal(true),
      3: () => setOpenWalletModal(true),
    };
    funcObject[values.methods]?.();
  };

  const handleBack = () => {
    navigate(INVOICE_URL);
  };

  return (
    <Spin spinning={loading}>
      <FormTera form={form} onSubmit={handleSubmit}>
        <div className="p-[16px] flex flex-col gap-[15px]">
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={handleBack}
          >
            <ArrowLeftOutlined className="w-4" />
            <span className="italic text-xs space-x-1 text-[#76A9FA]">
              Quay lại
            </span>
          </div>
          <Row className="grid grid-cols-3 gap-[30px]">
            <Col className="col-span-2">
              <div className="p-[32px] shadow-md bg-gray-50 flex flex-col gap-[16px]">
                <div className="flex flex-col gap-[32px]">
                  <div className="font-bold text-[22px] leading-[30px]">
                    Hoá đơn
                  </div>
                  <div className="grid grid-cols-2 gap-1">
                    {renderDescription(invoice)}
                  </div>
                  <div className="grid grid-cols-2 text-[13px] leading-[17px]">
                    <div className="grid col-span-1 gap-1">
                      <div className="col-span-1 text-[#3E63DD]">
                        Thông tin khách hàng
                      </div>
                      {renderDescription(information)}
                    </div>
                    {value?.export_bill === 1 && (
                      <div className="grid col-span-1 gap-1">
                        <div className="col-span-1 text-[#3E63DD]">
                          Thông tin nhận hóa đơn
                        </div>
                        {renderDescription(address)}
                      </div>
                    )}
                  </div>
                </div>
                {value?.items && value?.transaction_type === '3' && (
                  <CartTable
                    value={value?.items}
                    isView
                    isCart={value?.type !== 'upgrade'}
                    tableProps={{
                      scroll: { y: 700 },
                      disableShadow: true,
                      hiddenColumns:
                        value?.type === 'upgrade' ? [] : ['old_package'],
                    }}
                  />
                )}
              </div>
            </Col>
            <Col className="col-span-1 flex flex-col gap-[30px]">
              <PaymentInformation
                value={_.pick(value, [
                  'total_amount',
                  'total_amount_product',
                  'discount',
                  'vat_tax',
                  'start_up_fee',
                ])}
                type={Number(value?.transaction_type) as any}
              />
              <PaymentMethod
                isView={['paid', 'in_process', 'complete'].includes(
                  value?.status,
                )}
                form={form}
                totalAmount={value?.total_amount}
                disabledAllowPay={disabledAllowPay}
                type={Number(value?.transaction_type) as any}
              />
            </Col>
          </Row>
        </div>
      </FormTera>
      {openTransferModal && (
        <PaymentTransferModal
          open={openTransferModal}
          onClose={() => setOpenTransferModal(false)}
          amount={value?.total_amount}
        />
      )}
      {openWalletModal && (
        <PaymentWalletModal
          open={openWalletModal}
          onClose={() => {
            setOpenWalletModal(false);
            removeLocationState();
          }}
          invoiceId={value?.id}
          onSuccess={(id) => setOpenTransactionModal({ open: true, value: id })}
          onError={() => {
            setOpenWalletModal(false);
            setTimeout(() => {
              navigate(INVOICE_URL, { replace: true });
            }, 100);
          }}
        />
      )}
      {openTransactionModal?.open && (
        <PaymentDetailModal
          open={openTransactionModal?.open}
          onClose={() => setOpenTransactionModal({ open: false })}
          value={openTransactionModal?.value}
          invoiceCode={value?.code}
        />
      )}
      {openTransaction && (
        <TransactionModal
          type={value?.type}
          id={value?.transactions?.[0]?.transaction_id}
          open={openTransaction}
          onClose={() => setOpenTransaction(false)}
        />
      )}
    </Spin>
  );
};

export default InvoiceDetailContent;
