import { createContext, useState } from 'react';
import Content from './content';
export const LineChartContext = createContext(null);

const LineChartCustom = () => {
  const [value, setValue] = useState<any>();
  return (
    <LineChartContext.Provider value={{ value, setValue }}>
      <Content />
    </LineChartContext.Provider>
  );
};

export default LineChartCustom;
