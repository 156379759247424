import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const BusinessEndpoint = `${endpoint}/portal/business`;
export const BusinessApi = {
  getProfile: async () =>
    await api
      .get(`${BusinessEndpoint}/get-info`)
      .then((result) => result.data?.data),
  update: async (params: any) =>
    await api
      .put(`${BusinessEndpoint}/save`, params)
      .then((result) => result.data),
  create: async (params: any) =>
    await api
      .post(`${BusinessEndpoint}/register`, params)
      .then((result) => result.data),
};
