import { Navigate, Route, Routes } from 'react-router-dom';

import CheckAuth from 'routers/CheckAuth';
import MiddlewareRouter from 'routers/MiddlewareRouter';

import BasicLayout from '_common/component/Layout/BasicLayout';
import UnAuthLayout from '_common/component/Layout/UnAuthLayout';
import PageNotfound from '_common/component/PageNotfound';
// auth
import CheckAuthPage from 'pages/Auth/CheckAuth';
// page
import DashboardLayout from '_common/component/Layout/DashboardLayout';
import PageLayout from '_common/component/Layout/PageLayout';
import { IRouteProps } from '_common/interface/router';
import { ApplicationRouter } from 'pages/Application/router';
import Activation from 'pages/Auth/Activation';
import EnableBusiness from 'pages/Auth/Enable';
import BusinessUser from 'pages/Auth/Enable/BusinessUser';
import OtpFail from 'pages/Auth/Fail/OtpFail';
import RegistrationFail from 'pages/Auth/Fail/RegistrationFail';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import ResetPassword from 'pages/Auth/ForgotPassword/ResetPassword';
import Login from 'pages/Auth/Login';
import Otp from 'pages/Auth/OTP';
import Register from 'pages/Auth/Register';
import RegistrationBusinessSuccess from 'pages/Auth/Success/RegistrationBusinessSuccess';
import SendForgotPasswordSuccess from 'pages/Auth/Success/SendForgotPasswordSuccess';
import WelcomeRegistration from 'pages/Auth/Success/WelcomeRegistration';
import { BusinessInformationRouter } from 'pages/BusinessInformation/router';
import Cart from 'pages/Cart';
import { CART_URL } from 'pages/Cart/url';
import Dashboard from 'pages/Dashboard';
import { EmployeeInformationRouter } from 'pages/EmployeeInformation/router';
import { InvoiceRouter } from 'pages/Invoice/router';
import Payment from 'pages/Payment';
import { PAYMENT_URL } from 'pages/Payment/url';
import { PermissionGroupRouter } from 'pages/PermissionGroup/router';
import { ServicePackageRouter } from 'pages/ServicePackage/router';
import { StoreRouter } from 'pages/Store/router';
import { UserInformationRouter } from 'pages/UserInformation/router';
import { WalletRouter } from 'pages/Wallet/router';
import CheckBusiness from './CheckBusiness';
import { INVOICE_URL } from 'pages/Invoice/url';
import PageUnauthorized from '_common/component/PageUnauthorized';
import AffiliateDetail from 'pages/Dashboard/containers/Affiliate/AffiliateDetail';

const renderRouter = (objectRouter: IRouteProps[]) => {
  return objectRouter.map((route: IRouteProps) => {
    const { key, component, path, index } = route;
    return (
      <Route
        key={key}
        path={path}
        index={index}
        element={<PageLayout page_key={key}>{component}</PageLayout>}
      />
    );
  });
};

export const Routers = () => {
  return (
    <Routes>
      <Route
        path="dashboard"
        element={
          <MiddlewareRouter>
            <DashboardLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<Dashboard />} />
        <Route path="affiliate" element={<AffiliateDetail />} />
      </Route>

      <Route
        path="/"
        element={
          <MiddlewareRouter>
            <BasicLayout />
          </MiddlewareRouter>
        }
      >
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path={CART_URL} element={<Cart />} />
        <Route path={PAYMENT_URL} element={<Payment />} />
        <Route path={INVOICE_URL}>
          <Route>{renderRouter(InvoiceRouter)}</Route>
        </Route>
        <Route path="user">
          <Route>{renderRouter(UserInformationRouter)}</Route>
        </Route>
        <Route path="business">
          <Route>{renderRouter(BusinessInformationRouter)}</Route>
        </Route>
        <Route path="employee">
          <Route>{renderRouter(EmployeeInformationRouter)}</Route>
        </Route>
        <Route path="/store" element={<CheckBusiness />}>
          <Route>{renderRouter(StoreRouter)}</Route>
        </Route>
        <Route path="/application">
          <Route>{renderRouter(ApplicationRouter)}</Route>
        </Route>
        <Route path="/wallet">
          <Route>{renderRouter(WalletRouter)}</Route>
        </Route>
        <Route path="/permission-group">
          <Route>{renderRouter(PermissionGroupRouter)}</Route>
        </Route>
        <Route path="/service-package">
          <Route>{renderRouter(ServicePackageRouter)}</Route>
        </Route>
      </Route>
      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route path="check-auth" element={<CheckAuthPage />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="register-user/success" element={<WelcomeRegistration />} />
        <Route
          path="register-business/success"
          element={<RegistrationBusinessSuccess />}
        />
        <Route
          path="forgot-password/success"
          element={<SendForgotPasswordSuccess />}
        />
        <Route path="otp/:id" element={<Otp />} />
        <Route path="business/enable" element={<EnableBusiness />} />
        <Route path="business-user/enable" element={<BusinessUser />} />
        <Route path="business/enable/fail" element={<RegistrationFail />} />
        <Route path="opt/fail" element={<OtpFail />} />
        <Route path="account-activation" element={<Activation />} />
      </Route>
      <Route path="*" element={<PageNotfound />} />
      <Route path="/401" element={<PageUnauthorized />} />
    </Routes>
  );
};
