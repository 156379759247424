export const mockDataNews = [
  {
    id: 1,
    img_url:
      'https://web-api.terasolutions.vn/assets/upload/article/1711167178_tera-solutions-khang-dinh-vi-the-tren-duong-dua-cong-nghe-khang-dainh-2-1711072213-879-width1800heig.webp',
    title: 'Bán hàng',
    description:
      'Tính năng Bán hàng hỗ trợ quản lý báo giá và đơn hàng bán đa kênh trên cùng một nền tảng. Ngoài ra, tính năng bán hàng giúp doanh nghiệp quản lý toàn bộ dữ liệu khách hàng, và tự động cập nhật dữ liệu khi có sự thay đổi.',
    total_user: 0,
    price: 0,
  },
  {
    id: 2,
    img_url:
      'https://web-api.terasolutions.vn/assets/upload/article/1711167178_tera-solutions-khang-dinh-vi-the-tren-duong-dua-cong-nghe-khang-dainh-2-1711072213-879-width1800heig.webp',
    title: 'Marketing',
    description:
      'Tính năng Marketing của giải pháp Tera CRM quản lý tất cả các chiến dịch chăm sóc khách hàng và marketing của doanh nghiệp. Tính năng này hỗ trợ doanh nghiệp đánh giá các cơ hội tiếp cận khách hàng tiềm năng, giúp tối ưu chi phí và nâng cao hiệu suất làm việc của doanh nghiệp.',
    total_user: 0,
    price: 0,
  },
  {
    id: 3,
    img_url:
      'https://web-api.terasolutions.vn/assets/upload/article/1711167178_tera-solutions-khang-dinh-vi-the-tren-duong-dua-cong-nghe-khang-dainh-2-1711072213-879-width1800heig.webp',
    title: 'Chăm sóc khách hàng',
    description:
      'Tính năng Chăm sóc khách hàng tích hợp hệ thống chat đa kênh, giúp doanh nghiệp có thể tương tác với khách hàng nhanh chóng trên một nền tảng duy nhất. Ngoài ra, tính năng này lưu trữ các thông tin về thẻ tư vấn, bảo hành, nhiệm vụ, lịch hẹn giúp doanh nghiệp có thể đánh giá hiệu suất làm việc của nhân viên và tối ưu quy trình chăm sóc khách hàng.',
    total_user: 0,
    price: 0,
  },
  {
    id: 4,
    img_url:
      'https://web-api.terasolutions.vn/assets/upload/article/1711167178_tera-solutions-khang-dinh-vi-the-tren-duong-dua-cong-nghe-khang-dainh-2-1711072213-879-width1800heig.webp',
    title: 'Kho',
    description:
      'Tính năng Kho hỗ trợ doanh nghiệp theo dõi quy trình xuất - nhập kho và quản lý tất cả dữ liệu về thông tin sản phẩm, danh mục, thương hiệu, đơn vị, v.v. Tính năng này hỗ trợ doanh nghiệp đưa ra các dự đoán tồn kho, từ đó đưa ra những phương án kinh doanh kịp thời.',
    total_user: 0,
    price: 0,
  },
  {
    id: 5,
    img_url:
      'https://web-api.terasolutions.vn/assets/upload/article/1711167178_tera-solutions-khang-dinh-vi-the-tren-duong-dua-cong-nghe-khang-dainh-2-1711072213-879-width1800heig.webp',
    title: 'Mua hàng',
    description:
      'Tính năng Mua hàng giúp doanh nghiệp lưu trữ thông tin nhà cung cấp, từ đó đưa ra các đánh giá về nhà cung cấp và sản phẩm tiềm năng, giúp doanh nghiệp tiết kiệm chi phí nhập hàng. Bên cạnh đó, tính năng này hỗ trợ doanh nghiệp theo dõi tình trạng mua hàng một cách liền mạch với các tác vụ như tạo yêu cầu mua hàng, tạo đơn mua, thống kê và phân tích chi tiết trạng thái đơn mua hàng.',
    total_user: 0,
    price: 0,
  },
  {
    id: 6,
    img_url:
      'https://web-api.terasolutions.vn/assets/upload/article/1711167178_tera-solutions-khang-dinh-vi-the-tren-duong-dua-cong-nghe-khang-dainh-2-1711072213-879-width1800heig.webp',
    title: 'Vận chuyển',
    description:
      'Tính năng Vận chuyển lưu trữ chi tiết thông tin giao hàng xuất - nhập kho như phương tiện, đơn vị vận chuyển. Bên cạnh đó, tính năng này hỗ trợ doanh nghiệp theo dõi lộ trình hàng hoá, giúp tối ưu chi phí giao hàng và tăng năng suất làm việc.',
    total_user: 0,
    price: 0,
  },
  {
    id: 7,
    img_url:
      'https://web-api.terasolutions.vn/assets/upload/article/1711167178_tera-solutions-khang-dinh-vi-the-tren-duong-dua-cong-nghe-khang-dainh-2-1711072213-879-width1800heig.webp',
    title: 'Công việc',
    description:
      'Tính năng Công việc quản lý tiến độ tất cả các dự án của doanh nghiệp. Tính năng này hỗ trợ doanh nghiệp quản lý công việc và lưu trữ thông tin về dự án, nhân sự, từ đó đưa ra các báo cáo giúp doanh nghiệp đánh giá hiệu suất làm việc của nhân viên.',
    total_user: 0,
    price: 0,
  },
  {
    id: 8,
    img_url:
      'https://web-api.terasolutions.vn/assets/upload/article/1711167178_tera-solutions-khang-dinh-vi-the-tren-duong-dua-cong-nghe-khang-dainh-2-1711072213-879-width1800heig.webp',
    title: 'Quy trình',
    description:
      'Tính năng Quy trình hỗ trợ doanh nghiệp quản lý và thiết lập công việc theo một luồng tự động hoá. Doanh nghiệp có thể dễ dàng quản lý tiến độ làm việc của nhân viên, giúp tiết kiệm thời gian và chi phí hoạt động kinh doanh.',
    total_user: 0,
    price: 0,
  },
  {
    id: 9,
    img_url:
      'https://web-api.terasolutions.vn/assets/upload/article/1711167178_tera-solutions-khang-dinh-vi-the-tren-duong-dua-cong-nghe-khang-dainh-2-1711072213-879-width1800heig.webp',
    title: 'Nhân sự',
    description:
      'Tính năng Nhân sự quản lý tất cả thông tin nhân viên của doanh nghiệp một cách chi tiết. Tại đây, doanh nghiệp có thể lưu trữ thông tin về hồ sơ nhân viên, văn bản, hợp đồng mua bán và đánh giá hiệu suất KPI của từng thành viên.',
    total_user: 0,
    price: 0,
  },
  {
    id: 10,
    img_url:
      'https://web-api.terasolutions.vn/assets/upload/article/1711167178_tera-solutions-khang-dinh-vi-the-tren-duong-dua-cong-nghe-khang-dainh-2-1711072213-879-width1800heig.webp',
    title: 'Tài chính',
    description:
      'Tính năng Tài chính quản lý các hoá đơn mua bán và khoản thu chi của doanh nghiệp. Tính năng này hỗ trợ doanh nghiệp thống kê cụ thể thông tin thu chi, từ đó đưa ra đánh giá và giải pháp, giúp doanh nghiệp tối ưu chi phí hoạt động kinh doanh.',
    total_user: 0,
    price: 0,
  },
];

export const mockDataHistoryActivity = [
  {
    id: 3512,
    object_id: 183,
    user_id: 774,
    username: 'demo1',
    status: 1,
    type: 'crm_customer',
    object_type: null,
    action_type: 'edited',
    title: null,
    url: null,
    param: null,
    source: null,
    content: 'Xoá',
    note: null,
    created_at: '2024-03-26T03:28:45.000000Z',
    updated_at: '2024-03-26T03:28:45.000000Z',
    deleted_at: null,
    type_sub: null,
    type_text: null,
    action_type_text: 'Chỉnh sửa',
    object_type_text: 'Khách hàng',
    object_sub_type_text: null,
    created_by: {
      id: 774,
      department_id: null,
      position_id: null,
      status: 1,
      full_name: 'Demo 1',
      username: 'demo1',
      email: 'phuong12.thh@terasolutions.vn',
      is_admin: 1,
      allow_chat: 1,
      avatar: null,
      code: 'NV1',
      role_id: 47,
      status_work: 1,
      business_id: 1,
      created_by: null,
      created_at: '2020-08-18T03:54:27.000000Z',
      updated_at: '2024-03-28T09:20:13.000000Z',
      deleted_at: null,
      ip: '183.80.94.205',
      is_login: 1,
      phone: '09851538121',
      is_active: 1,
      business_name: null,
      verify_auth: 0,
      time_block: null,
      type: 'owner',
      register_time: null,
      expiration_time: null,
      trial_time: null,
      status_account: 'is_active',
      department: 'crm_position_deputy',
      job_title: 'crm_job-title_manager',
      is_first: 0,
      _url: '/api/portal/user/update-profile',
      reps_login: 992,
      updated_by: null,
      is_publisher: 1,
      avatar_url: 'https://portal-api.teravn.com/assets/user_default.jpg',
      status_text: 'Đang hoạt động',
      type_text: 'Chủ sở hữu',
      status_account_text: 'Hoạt động',
      department_text: {
        title: 'Phó phòng',
        concatenated_key: 'crm_position_deputy',
      },
      job_title_text: {
        title: 'Giám đốc',
        concatenated_key: 'crm_job-title_manager',
      },
    },
  },
  {
    id: 3511,
    object_id: 62,
    user_id: 774,
    username: 'demo1',
    status: 1,
    type: 'crm_customer',
    object_type: null,
    action_type: 'deleted',
    title: null,
    url: null,
    param: null,
    source: null,
    content: 'Tên khách hàng',
    note: null,
    created_at: '2024-03-26T03:28:23.000000Z',
    updated_at: '2024-03-26T03:28:23.000000Z',
    deleted_at: null,
    type_sub: null,
    type_text: null,
    action_type_text: 'Xóa',
    object_type_text: 'Khách hàng',
    object_sub_type_text: null,
    created_by: {
      id: 774,
      department_id: null,
      position_id: null,
      status: 1,
      full_name: 'Demo 1',
      username: 'demo1',
      email: 'phuong12.thh@terasolutions.vn',
      is_admin: 1,
      allow_chat: 1,
      avatar: null,
      code: 'NV1',
      role_id: 47,
      status_work: 1,
      business_id: 1,
      created_by: null,
      created_at: '2020-08-18T03:54:27.000000Z',
      updated_at: '2024-03-28T09:20:13.000000Z',
      deleted_at: null,
      ip: '183.80.94.205',
      is_login: 1,
      phone: '09851538121',
      is_active: 1,
      business_name: null,
      verify_auth: 0,
      time_block: null,
      type: 'owner',
      register_time: null,
      expiration_time: null,
      trial_time: null,
      status_account: 'is_active',
      department: 'crm_position_deputy',
      job_title: 'crm_job-title_manager',
      is_first: 0,
      _url: '/api/portal/user/update-profile',
      reps_login: 992,
      updated_by: null,
      is_publisher: 1,
      avatar_url: 'https://portal-api.teravn.com/assets/user_default.jpg',
      status_text: 'Đang hoạt động',
      type_text: 'Chủ sở hữu',
      status_account_text: 'Hoạt động',
      department_text: {
        title: 'Phó phòng',
        concatenated_key: 'crm_position_deputy',
      },
      job_title_text: {
        title: 'Giám đốc',
        concatenated_key: 'crm_job-title_manager',
      },
    },
  },
  {
    id: 3510,
    object_id: 75,
    user_id: 774,
    username: 'demo1',
    status: 1,
    type: 'crm_customer',
    object_type: null,
    action_type: 'deleted',
    title: null,
    url: null,
    param: null,
    source: null,
    content: 'Bao Thanh Thiên',
    note: null,
    created_at: '2024-03-26T03:28:19.000000Z',
    updated_at: '2024-03-26T03:28:19.000000Z',
    deleted_at: null,
    type_sub: null,
    type_text: null,
    action_type_text: 'Xóa',
    object_type_text: 'Khách hàng',
    object_sub_type_text: null,
    created_by: {
      id: 774,
      department_id: null,
      position_id: null,
      status: 1,
      full_name: 'Demo 1',
      username: 'demo1',
      email: 'phuong12.thh@terasolutions.vn',
      is_admin: 1,
      allow_chat: 1,
      avatar: null,
      code: 'NV1',
      role_id: 47,
      status_work: 1,
      business_id: 1,
      created_by: null,
      created_at: '2020-08-18T03:54:27.000000Z',
      updated_at: '2024-03-28T09:20:13.000000Z',
      deleted_at: null,
      ip: '183.80.94.205',
      is_login: 1,
      phone: '09851538121',
      is_active: 1,
      business_name: null,
      verify_auth: 0,
      time_block: null,
      type: 'owner',
      register_time: null,
      expiration_time: null,
      trial_time: null,
      status_account: 'is_active',
      department: 'crm_position_deputy',
      job_title: 'crm_job-title_manager',
      is_first: 0,
      _url: '/api/portal/user/update-profile',
      reps_login: 992,
      updated_by: null,
      is_publisher: 1,
      avatar_url: 'https://portal-api.teravn.com/assets/user_default.jpg',
      status_text: 'Đang hoạt động',
      type_text: 'Chủ sở hữu',
      status_account_text: 'Hoạt động',
      department_text: {
        title: 'Phó phòng',
        concatenated_key: 'crm_position_deputy',
      },
      job_title_text: {
        title: 'Giám đốc',
        concatenated_key: 'crm_job-title_manager',
      },
    },
  },
  {
    id: 3509,
    object_id: 76,
    user_id: 774,
    username: 'demo1',
    status: 1,
    type: 'crm_customer',
    object_type: null,
    action_type: 'deleted',
    title: null,
    url: null,
    param: null,
    source: null,
    content: 'Bao Thanh Thiên',
    note: null,
    created_at: '2024-03-26T03:28:14.000000Z',
    updated_at: '2024-03-26T03:28:14.000000Z',
    deleted_at: null,
    type_sub: null,
    type_text: null,
    action_type_text: 'Xóa',
    object_type_text: 'Khách hàng',
    object_sub_type_text: null,
    created_by: {
      id: 774,
      department_id: null,
      position_id: null,
      status: 1,
      full_name: 'Demo 1',
      username: 'demo1',
      email: 'phuong12.thh@terasolutions.vn',
      is_admin: 1,
      allow_chat: 1,
      avatar: null,
      code: 'NV1',
      role_id: 47,
      status_work: 1,
      business_id: 1,
      created_by: null,
      created_at: '2020-08-18T03:54:27.000000Z',
      updated_at: '2024-03-28T09:20:13.000000Z',
      deleted_at: null,
      ip: '183.80.94.205',
      is_login: 1,
      phone: '09851538121',
      is_active: 1,
      business_name: null,
      verify_auth: 0,
      time_block: null,
      type: 'owner',
      register_time: null,
      expiration_time: null,
      trial_time: null,
      status_account: 'is_active',
      department: 'crm_position_deputy',
      job_title: 'crm_job-title_manager',
      is_first: 0,
      _url: '/api/portal/user/update-profile',
      reps_login: 992,
      updated_by: null,
      is_publisher: 1,
      avatar_url: 'https://portal-api.teravn.com/assets/user_default.jpg',
      status_text: 'Đang hoạt động',
      type_text: 'Chủ sở hữu',
      status_account_text: 'Hoạt động',
      department_text: {
        title: 'Phó phòng',
        concatenated_key: 'crm_position_deputy',
      },
      job_title_text: {
        title: 'Giám đốc',
        concatenated_key: 'crm_job-title_manager',
      },
    },
  },
  {
    id: 3508,
    object_id: 36,
    user_id: 774,
    username: 'demo1',
    status: 1,
    type: 'crm_customer',
    object_type: null,
    action_type: 'deleted',
    title: null,
    url: null,
    param: null,
    source: null,
    content: '4',
    note: null,
    created_at: '2024-03-26T03:28:10.000000Z',
    updated_at: '2024-03-26T03:28:10.000000Z',
    deleted_at: null,
    type_sub: null,
    type_text: null,
    action_type_text: 'Xóa',
    object_type_text: 'Khách hàng',
    object_sub_type_text: null,
    created_by: {
      id: 774,
      department_id: null,
      position_id: null,
      status: 1,
      full_name: 'Demo 1',
      username: 'demo1',
      email: 'phuong12.thh@terasolutions.vn',
      is_admin: 1,
      allow_chat: 1,
      avatar: null,
      code: 'NV1',
      role_id: 47,
      status_work: 1,
      business_id: 1,
      created_by: null,
      created_at: '2020-08-18T03:54:27.000000Z',
      updated_at: '2024-03-28T09:20:13.000000Z',
      deleted_at: null,
      ip: '183.80.94.205',
      is_login: 1,
      phone: '09851538121',
      is_active: 1,
      business_name: null,
      verify_auth: 0,
      time_block: null,
      type: 'owner',
      register_time: null,
      expiration_time: null,
      trial_time: null,
      status_account: 'is_active',
      department: 'crm_position_deputy',
      job_title: 'crm_job-title_manager',
      is_first: 0,
      _url: '/api/portal/user/update-profile',
      reps_login: 992,
      updated_by: null,
      is_publisher: 1,
      avatar_url: 'https://portal-api.teravn.com/assets/user_default.jpg',
      status_text: 'Đang hoạt động',
      type_text: 'Chủ sở hữu',
      status_account_text: 'Hoạt động',
      department_text: {
        title: 'Phó phòng',
        concatenated_key: 'crm_position_deputy',
      },
      job_title_text: {
        title: 'Giám đốc',
        concatenated_key: 'crm_job-title_manager',
      },
    },
  },
  {
    id: 35081,
    object_id: 36,
    user_id: 774,
    username: 'demo1',
    status: 1,
    type: 'crm_customer',
    object_type: null,
    action_type: 'deleted',
    title: null,
    url: null,
    param: null,
    source: null,
    content: '4',
    note: null,
    created_at: '2024-03-26T03:28:10.000000Z',
    updated_at: '2024-03-26T03:28:10.000000Z',
    deleted_at: null,
    type_sub: null,
    type_text: null,
    action_type_text: 'Xóa',
    object_type_text: 'Khách hàng',
    object_sub_type_text: null,
    created_by: {
      id: 774,
      department_id: null,
      position_id: null,
      status: 1,
      full_name: 'Demo 1',
      username: 'demo1',
      email: 'phuong12.thh@terasolutions.vn',
      is_admin: 1,
      allow_chat: 1,
      avatar: null,
      code: 'NV1',
      role_id: 47,
      status_work: 1,
      business_id: 1,
      created_by: null,
      created_at: '2020-08-18T03:54:27.000000Z',
      updated_at: '2024-03-28T09:20:13.000000Z',
      deleted_at: null,
      ip: '183.80.94.205',
      is_login: 1,
      phone: '09851538121',
      is_active: 1,
      business_name: null,
      verify_auth: 0,
      time_block: null,
      type: 'owner',
      register_time: null,
      expiration_time: null,
      trial_time: null,
      status_account: 'is_active',
      department: 'crm_position_deputy',
      job_title: 'crm_job-title_manager',
      is_first: 0,
      _url: '/api/portal/user/update-profile',
      reps_login: 992,
      updated_by: null,
      is_publisher: 1,
      avatar_url: 'https://portal-api.teravn.com/assets/user_default.jpg',
      status_text: 'Đang hoạt động',
      type_text: 'Chủ sở hữu',
      status_account_text: 'Hoạt động',
      department_text: {
        title: 'Phó phòng',
        concatenated_key: 'crm_position_deputy',
      },
      job_title_text: {
        title: 'Giám đốc',
        concatenated_key: 'crm_job-title_manager',
      },
    },
  },
];
