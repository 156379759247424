import { IRouteProps } from '_common/interface/router';
import EmployeeInformation from '.';
import NotPermission from './containers/NotPermission';
import { EMPLOYEE_INFORMATION_URL } from './url';

export const EmployeeInformationRouter: IRouteProps[] = [
  {
    key: EMPLOYEE_INFORMATION_URL.list.key,
    path: EMPLOYEE_INFORMATION_URL.list.shortenUrl,
    component: <EmployeeInformation />,
  },
  {
    key: EMPLOYEE_INFORMATION_URL.notPermission.key,
    path: EMPLOYEE_INFORMATION_URL.notPermission.shortenUrl,
    component: <NotPermission />,
  },
];
