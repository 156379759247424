import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT, DATE_FORMAT } from '_common/constants/common';
import RangePicker from '_common/dof/Control/RangePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import moment from 'moment';
import { INVOICE_STATUS } from 'pages/Invoice/constants';
import { TransactionMethod, TransactionType } from 'pages/Wallet/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function InvoiceFilter({ open, onClose, onFilter, initialValue }: IProps) {
  const form = useForm();

  useEffect(() => {
    if (initialValue) {
      const split = initialValue?.transaction_date?.split(',');
      form.reset({
        ...initialValue,
        ...(initialValue?.transaction_date?.[0] && {
          transaction_date: [moment(split?.[0]), moment(split?.[1])],
        }),
      });
    }
  }, [initialValue]);

  const handleSubmitForm = (values) => {
    onFilter({
      ...values,
      ...(values?.transaction_date?.[0] && {
        transaction_date: [
          values?.transaction_date[0].format(DATE_BACKEND_FORMAT),
          values?.transaction_date[1].format(DATE_BACKEND_FORMAT),
        ].join(','),
      }),
    });
    onClose();
  };

  const handleReset = () => {
    const values = {
      transaction_type: null,
      status: null,
      methods: null,
      transaction_date: null,
    };
    onFilter(values);
    onClose();
  };

  const statusOptions = Object.entries(INVOICE_STATUS).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const transactionOptions = Object.entries(TransactionType).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const methodOptions = Object.entries(TransactionMethod).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Trạng thái" name="status">
          <Select placeholder="Vui lòng chọn" options={statusOptions} />
        </FormTeraItem>
        <FormTeraItem label="Loại giao dịch" name="transaction_type">
          <Select placeholder="Vui lòng chọn" options={transactionOptions} />
        </FormTeraItem>
        <FormTeraItem label="Phương thức thanh toán" name="methods">
          <Select placeholder="Vui lòng chọn" options={methodOptions} />
        </FormTeraItem>
        <FormTeraItem label="Ngày tạo" name="transaction_date">
          <RangePicker
            format={DATE_FORMAT}
            placeholder={['Bắt đầu', 'Kết thúc']}
            className="w-full"
          />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default InvoiceFilter;
