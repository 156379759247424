import { useQuery } from '@tanstack/react-query';
import Radio from '_common/dof/Control/Radio';
import { FormTeraItem } from '_common/dof/FormTera';
import { TRANSACTION_TYPE } from 'pages/Invoice/constants';
import WalletApi from 'pages/Wallet/_api';
import { TransactionMethod } from 'pages/Wallet/constants';
import { useEffect } from 'react';
import { Button, formatCurrency, Spin } from 'tera-dls';
import CheckedRules from './CheckedRules';

type type = keyof typeof TRANSACTION_TYPE;

interface IProps {
  form: any;
  totalAmount?: number;
  disabledAllowPay?: boolean;
  isView?: boolean;
  type?: type;
}
const PaymentMethod = ({
  form,
  totalAmount,
  disabledAllowPay = false,
  isView = false,
  type = 3,
}: IProps) => {
  const {
    data: response,
    refetch,
    isLoading,
  } = useQuery(['get-wallet-amount'], () => WalletApi.getAmount(), {
    staleTime: 300000,
    cacheTime: 300000,
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (Number(response?.availability_amount ?? 0) > Number(totalAmount)) {
      form.setValue('rule_wallet', true);
    } else {
      form.setValue('rule_wallet', false);
    }
  }, [response?.availability_amount, totalAmount]);

  const disable =
    !form?.watch('rule') ||
    (Number(response?.availability_amount ?? 0) < Number(totalAmount) &&
      form?.watch('methods') === '3') ||
    disabledAllowPay;

  if (type !== 3) {
    return (
      <Spin spinning={isLoading}>
        <div className="p-[16px] flex flex-col gap-[32px] shadow-md bg-gray-50 rounded-[6px]">
          <div className="font-semibold text-base">Thông tin thanh toán</div>
          <div className="flex text-sm justify-between items-center">
            <span className=" font-medium">
              {TransactionMethod[form?.watch('methods')]}
            </span>
          </div>
        </div>
      </Spin>
    );
  }
  return (
    <Spin spinning={isLoading}>
      <div className="p-[16px] flex flex-col gap-[32px] shadow-md bg-gray-50 rounded-[6px]">
        <div className="font-semibold text-base">Thông tin thanh toán</div>
        {isView ? (
          <div className="flex text-sm justify-between items-center">
            <span className=" text-gray-500">Phương thức thanh toán</span>
            <span className=" font-medium">
              {TransactionMethod[form?.watch('methods')]}
            </span>
          </div>
        ) : (
          <div className="flex flex-col gap-2.5 px-[16px]">
            <FormTeraItem name="methods" className="mb-0">
              <Radio
                className="gap-2.5"
                inline={false}
                disabled={disabledAllowPay}
                listOption={[
                  {
                    key: '1',
                    value: '1',
                    label: (
                      <span className="font-medium text-base">
                        Chuyển khoản
                      </span>
                    ),
                  },
                  {
                    key: '3',
                    value: '3',
                    label: (
                      <span className="font-medium text-base"> Ví Tera</span>
                    ),
                  },
                ]}
              />
            </FormTeraItem>

            <div className="flex gap-[5px] p-2.5 items-center pl-[35px]">
              <span className="text-gray-500 font-light text-[13px] leading-[15px]">
                Số dư ví
              </span>
              <span className="text-green-500 font-medium text-base">
                {formatCurrency(response?.availability_amount ?? 0)}
              </span>
            </div>
          </div>
        )}
        {!isView && (
          <>
            <div className="border-t-[1px] pt-[17px]">
              <FormTeraItem
                name="rule"
                className="mb-0"
                // rules={[
                //   {
                //     required: {
                //       value: true,
                //       message: '',
                //     },
                //   },
                // ]}
              >
                <CheckedRules isError={false} disabled={disabledAllowPay} />
              </FormTeraItem>
            </div>
            <Button
              htmlType="submit"
              disabled={disable}
              className="px-2.5 rounded-full bg-blue-400 hover:bg-blue-500 text-[13px] leading-[15px]"
            >
              Thanh toán
            </Button>
            {disabledAllowPay && (
              <p className="text-base">
                <span className="text-red-500">*</span> Hóa đơn không thể thanh
                toán vì số tiền sản phẩm đã được cập nhật, dẫn đến sự không khớp
                số tiền trong hóa đơn và số tiền hiện tại.
              </p>
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default PaymentMethod;
