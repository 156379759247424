import React from 'react';

function BoxCheck(props) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9474 12.8659L13.6316 14.5501L17 11.1817M16.1579 7.81324V6.12903C16.1576 5.83368 16.0796 5.54361 15.9318 5.2879C15.784 5.0322 15.5716 4.81986 15.3158 4.67219L9.42105 1.30377C9.16502 1.15595 8.87459 1.07812 8.57895 1.07812C8.28331 1.07812 7.99287 1.15595 7.73684 1.30377L1.84211 4.67219C1.58633 4.81986 1.37388 5.0322 1.22607 5.2879C1.07827 5.54361 1.0003 5.83368 1 6.12903V12.8659C1.0003 13.1612 1.07827 13.4513 1.22607 13.707C1.37388 13.9627 1.58633 14.175 1.84211 14.3227L7.73684 17.6911C7.99287 17.839 8.28331 17.9168 8.57895 17.9168C8.87459 17.9168 9.16502 17.839 9.42105 17.6911L11.1053 16.7311M4.78947 2.98798L12.3684 7.32482M1.24421 5.28692L8.57895 9.49745M8.57895 9.49745L15.9137 5.28692M8.57895 9.49745V17.9185"
        stroke="#D1D5DB"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default BoxCheck;
