import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const ModuleEndpoint = `${endpoint}/portal`;

const ModuleApi = {
  getList: async (param?) =>
    await api
      .get(`${ModuleEndpoint}/module/list`, param)
      .then((result) => result.data?.data)
      .catch((result) => result.data),
};

export default ModuleApi;
