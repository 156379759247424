import { useQuery } from '@tanstack/react-query';
import ServicePackageApi from 'pages/ServicePackage/_api';
import { useEffect, useState } from 'react';
import { customTwMerge } from 'tailwind-merge.config';
import {
  ChevronDownOutlined,
  ChevronUpOutlined,
  getQueryParams,
  Spin,
} from 'tera-dls';
import UsedPackageItem from './UsedPackageItem';
import emptyItem from 'styles/images/servicePackage/people.png';
import { useLocation } from 'react-router-dom';

const UsedPackage = () => {
  const [open, setOpen] = useState<boolean>(true);
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const {
    data: response,
    refetch,
    isLoading,
  } = useQuery(
    ['get-service-package-list', queryParams],
    () =>
      ServicePackageApi.getUsedList({
        params: { page: 1, limit: 1000, ...queryParams },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const list = response?.data ?? [];

  return (
    <div
      className={customTwMerge(
        'rounded-[16px] border-[1px] border-blue-200 bg-blue-50 p-[16px] overflow-hidden duration-150',
        open ? 'h-full' : 'h-[58px]',
      )}
      style={{ boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)' }}
    >
      <div className="flex ">
        <div
          className="inline cursor-pointer"
          onClick={() => {
            setOpen((open) => !open);
          }}
        >
          <div className="flex gap-2.5">
            <h2 className="font-bold text-base text-[#0095D9] select-none">
              Gói dịch vụ đã đăng ký ({response?.total ?? 0})
            </h2>
            {open ? (
              <ChevronDownOutlined className="w-4 text-[#0095D9]" />
            ) : (
              <ChevronUpOutlined className="w-4 text-[#0095D9]" />
            )}
          </div>
        </div>
      </div>
      <Spin spinning={isLoading}>
        {list?.length > 0 ? (
          <div className="gap-5 mt-[30px] grid grid-cols-4">
            {list?.map((item) => (
              <UsedPackageItem key={item?.id} value={item} />
            ))}
          </div>
        ) : (
          <div className="flex justify-center">
            <div className="mt-3 flex flex-col gap-[5px]">
              <img src={emptyItem} />
              <h3 className="font-light italic">
                Chưa đăng ký gói dịch vụ nào
              </h3>
            </div>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default UsedPackage;
