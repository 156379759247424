import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { useStores } from '_common/hooks';
import CartApi from 'pages/Cart/api';
import CartTable from 'pages/Cart/containers/CartTable';
import PaymentInformation from 'pages/Payment/containers/PaymentInformation';
import { PAYMENT_URL } from 'pages/Payment/url';
import ServicePackageApi from 'pages/ServicePackage/_api';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Modal, Row, Spin } from 'tera-dls';

interface IProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  currentId: number;
  upgradeId: number;
  mode: 'upgrade' | 'extend ' | 'restart';
}

const title = {
  upgrade: 'Xác nhận nâng cấp',
  extend: 'Xác nhận gia hạn',
  restart: 'Xác nhận kích hoạt lại',
};

const PaymentQuickly = (props: IProps) => {
  const { onClose, open, currentId, upgradeId, mode } = props;
  const {
    cartStore: { updateCart, updateType },
  } = useStores();
  const queryClient = useQueryClient();
  const [dataSource, setDataSource] = useState<any>();

  const navigate = useNavigate();

  const isUpgradeMode = mode === 'upgrade';

  const { data, refetch } = useQuery(
    ['get-calculate-old-package', currentId, upgradeId],
    () =>
      ServicePackageApi.calculateOldPackage({
        params: {
          current_id: currentId,
          ...(isUpgradeMode && { upgrade_id: upgradeId }),
          mode,
        },
      }),
    {
      enabled: !!currentId && isUpgradeMode,
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        setDataSource(data?.data);
      },
    },
  );

  const { mutate: mutateQuantity } = useMutation(
    (variables: any) => ServicePackageApi.calculateOldPackage(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          setDataSource(res?.data);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
        onClose && onClose();
        queryClient.invalidateQueries(['get-service-package-list']);
      },
    },
  );

  useEffect(() => {
    currentId && refetch();
  }, [currentId, upgradeId]);

  const paymentObject = {
    upgrade: {
      package_id: upgradeId,
      quantity: dataSource?.quantity,
      old_id: currentId,
    },
    restart: {
      package_id: currentId,
      quantity: dataSource?.quantity,
    },
    extend: {
      package_id: currentId,
      quantity: dataSource?.quantity,
    },
  };
  const { data: payment, refetch: refetchPayment } = useQuery(
    ['get-cart-payment'],
    () =>
      CartApi.getPayment({
        params: {
          items: [paymentObject[mode]],
        },
      }),
    {
      staleTime: 300000,
      enabled: !!dataSource,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    dataSource && refetchPayment();
  }, [dataSource]);

  const handleSubmit = () => {
    updateCart(paymentObject[mode]);
    updateType(mode as any);
    navigate(PAYMENT_URL);
  };

  return (
    <>
      <Modal
        title={title[mode]}
        destroyOnClose
        closeIcon={false}
        onCancel={onClose}
        width={1200}
        maskClosable={false}
        open={open}
        onOk={handleSubmit}
        okText="Mua hàng"
        cancelText="Đóng"
        centered={true}
      >
        <Spin spinning={false}>
          <div className="grid grid-cols-2 gap-[16px]">
            <div className="col-span-2">
              <CartTable
                tableProps={{
                  hiddenColumns: [
                    'action',
                    ...(isUpgradeMode ? [] : ['old_package']),
                  ],
                }}
                minNumber={data?.data?.quantity_suggest}
                value={[{ id: 1, ...(dataSource ?? {}) }]}
                onChangeQuantity={(_, number) =>
                  mutateQuantity({
                    params: {
                      current_id: currentId,
                      ...(isUpgradeMode && { upgrade_id: upgradeId }),
                      quantity: number,
                      mode,
                    },
                  })
                }
              />
            </div>
            <Row className="grid grid-cols-2 col-span-2 co gap-[16px] text-gray-700">
              <div className="col-span-1">
                <div className=" p-[16px] flex flex-col gap-[32px] shadow-md bg-gray-50 rounded-[6px]">
                  <div className="font-semibold text-base">Mã khuyến mãi</div>
                  <div className="flex gap-2.5">
                    <Input placeholder="Vui lòng nhập" />
                    <Button className="shrink-0 h-full bg-blue-400 hover:bg-blue-500 p-2.5 tracking-[1px]">
                      Áp dụng
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <PaymentInformation value={payment} />
              </div>
            </Row>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default PaymentQuickly;
