import { IRouteProps } from '_common/interface/router';
import PermissionGroup from '.';
import { PERMISSION_GROUP_URL } from './constants/url';

export const PermissionGroupRouter: IRouteProps[] = [
  {
    key: PERMISSION_GROUP_URL.list.key,
    path: PERMISSION_GROUP_URL.list.shortenUrl,
    component: <PermissionGroup />,
  },
];
