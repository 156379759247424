import { REGEX } from '_common/constants/common';
import Input from '_common/dof/Control/Input';
import { FormTeraItem } from '_common/dof/FormTera';
import SelectDepartment from '_common/dof/Select/SelectDepartment';
import SelectJobTitle from '_common/dof/Select/SelectJobTitle';
import SelectPaymentMethod from '_common/dof/Select/SelectPaymentMethod';
import SelectScalesType from '_common/dof/Select/SelectScalesType';

const BusinessForm = () => {
  return (
    <>
      <div className="flex flex-col gap-[16px] w-full">
        <div className="shadow-lg p-4 rounded-[10px] border-[1px] border-gray-200 flex flex-col gap-[31px]">
          <div className="text-[22px] leading-[22px] text-[#1F2937] font-medium">
            Thông tin doanh nghiệp
          </div>
          <div className="w-full">
            <FormTeraItem
              label="Tên công ty"
              layout="inline"
              labelClassName="w-[200px]"
              className="flex items-start"
              rules={[{ required: 'Vui lòng nhập dữ liệu' }]}
              name="name"
            >
              <Input
                placeholder="Vui lòng nhập"
                maxLength={255}
                className="py-[13px]"
              />
            </FormTeraItem>
            <FormTeraItem
              label="Tên đăng ký giấy phép kinh doanh của doanh nghệp"
              name="name_registration"
              layout="inline"
              className="flex items-start "
              labelClassName="w-[200px] break-words whitespace-normal leading-5"
            >
              <Input
                maxLength={255}
                placeholder="Vui lòng nhập"
                className="py-[13px]"
              />
            </FormTeraItem>
            <FormTeraItem
              label="Email công ty"
              name="email"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[200px]"
              isRequired={false}
              rules={[
                {
                  required: 'Vui lòng nhập dữ liệu',
                  pattern: {
                    value: REGEX.EMAIL,
                    message: 'Sai định dạng email',
                  },
                },
              ]}
            >
              <Input
                placeholder="Vui lòng nhập"
                maxLength={320}
                className="py-[13px]"
              />
            </FormTeraItem>
            <FormTeraItem
              label="Quy mô nhân sự"
              name="employee_size"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[200px]"
              rules={[{ required: 'Vui lòng nhập dữ liệu' }]}
            >
              <SelectScalesType
                placeholder="Vui lòng chọn"
                className="py-[4px]"
              />
            </FormTeraItem>
            <FormTeraItem
              name={'payment_methods'}
              label="Phương thức thanh toán"
              className="flex items-start"
              layout="inline"
              labelClassName="w-[200px]"
              rules={[{ required: 'Vui lòng chọn dữ liệu' }]}
            >
              <SelectPaymentMethod
                placeholder="Vui lòng chọn"
                className="py-[4px]"
              />
            </FormTeraItem>
            <FormTeraItem
              name="address"
              label="Địa chỉ công ty"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[200px]"
            >
              <Input
                placeholder="Vui lòng nhập"
                maxLength={255}
                className="py-[13px]"
              />
            </FormTeraItem>
            <FormTeraItem
              name="intro"
              label="Giới thiệu doanh nghiệp"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[200px]"
            >
              <Input
                placeholder="Vui lòng nhập"
                maxLength={255}
                className="py-[13px]"
              />
            </FormTeraItem>
          </div>
        </div>
        <div
          id="e2"
          className="shadow-lg p-4 rounded-[10px] border-[1px] border-gray-200 flex flex-col gap-[31px]"
        >
          <div className="text-[22px] leading-[22px] text-[#1F2937] font-medium">
            Thông tin đại diện
          </div>
          <div className="flex gap-[16px] flex-col">
            <FormTeraItem
              label="Họ và tên"
              name="owner_name"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[200px]"
              rules={[{ required: 'Vui lòng nhập dữ liệu' }]}
            >
              <Input
                placeholder="Vui lòng nhập"
                maxLength={100}
                className="py-[13px]"
              />
            </FormTeraItem>
            <FormTeraItem
              label="Email"
              name="owner_email"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[200px]"
              rules={[
                {
                  pattern: {
                    value: REGEX.EMAIL,
                    message: 'Sai định dạng email',
                  },
                },
              ]}
            >
              <Input
                placeholder="Vui lòng nhập"
                maxLength={100}
                disabled
                className="py-[13px]"
              />
            </FormTeraItem>
            <FormTeraItem
              label="Số điện thoại"
              name="owner_phone"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[200px]"
              rules={[
                {
                  pattern: {
                    value: REGEX.PHONE_NUMBER,
                    message: 'Số điện thoại không hợp lệ',
                  },
                },
                {
                  minLength: {
                    value: 8,
                    message: 'Nhập tối thiểu 8 kí tự',
                  },
                },
                { required: 'Vui lòng nhập dữ liệu' },
              ]}
            >
              <Input
                placeholder="Vui lòng nhập"
                maxLength={20}
                className="py-[13px]"
              />
            </FormTeraItem>
            <FormTeraItem
              name={'owner_job_title'}
              label="Chức danh"
              className="flex items-start"
              layout="inline"
              labelClassName="w-[200px]"
            >
              <SelectJobTitle
                placeholder="Vui lòng chọn"
                className="py-[4px]"
              />
            </FormTeraItem>
            <FormTeraItem
              name={'owner_department'}
              label="Phòng ban/ Bộ phận"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[200px]"
            >
              <SelectDepartment
                placeholder="Vui lòng chọn"
                className="py-[4px]"
              />
            </FormTeraItem>
          </div>
        </div>
        <div
          id="e3"
          className="shadow-lg p-4 rounded-[10px] border-[1px] border-gray-200 flex flex-col gap-[31px]"
        >
          <div className="text-[22px] leading-[22px] text-[#1F2937] font-medium">
            Thông tin khác
          </div>
          <div className="flex gap-[16px] flex-col">
            <FormTeraItem
              label="Website"
              name="website"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[200px]"
            >
              <Input
                placeholder="Vui lòng nhập"
                maxLength={255}
                className="py-[13px]"
              />
            </FormTeraItem>
            <FormTeraItem
              label="Facebook"
              name="facebook"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[200px]"
            >
              <Input
                placeholder="Vui lòng nhập"
                maxLength={255}
                className="py-[13px]"
              />
            </FormTeraItem>
            <FormTeraItem
              label="Instagram"
              name="instagram"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[200px]"
            >
              <Input
                placeholder="Vui lòng nhập"
                maxLength={255}
                className="py-[13px]"
              />
            </FormTeraItem>
            <FormTeraItem
              label="Tiktok"
              name="tiktok"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[200px]"
            >
              <Input
                placeholder="Vui lòng nhập"
                maxLength={255}
                className="py-[13px]"
              />
            </FormTeraItem>
            <FormTeraItem
              label="linkedin"
              name="linkedin"
              layout="inline"
              className="flex items-start"
              labelClassName="w-[200px]"
            >
              <Input
                placeholder="Vui lòng nhập"
                maxLength={255}
                className="py-[13px]"
              />
            </FormTeraItem>
          </div>
        </div>
        {/* {!noActiveStatus.includes(status) && (
          <div
            id="e4"
            className="shadow-lg p-4 rounded-[10px] border-[1px] border-gray-200 flex flex-col gap-[31px]"
          >
            <div className="text-[22px] leading-[22px] text-[#1F2937] font-medium">
              Thông tin đăng ký
            </div>
            <div className="flex gap-[16px] flex-col">
              <FormTeraItem
                label="Ngày đăng ký"
                name="register_time"
                layout="inline"
                className="flex items-start"
                labelClassName="w-[200px]"
              >
                <DatePicker
                  placeholder="dd/mm/yyyy"
                  disabled
                  format={DATE_FORMAT}
                  inputClassName="py-[15px]"
                />
              </FormTeraItem>
              <FormTeraItem
                label="Ngày hết hạn"
                name="expiration_time"
                layout="inline"
                className="flex items-start"
                labelClassName="w-[200px]"
              >
                <DatePicker
                  placeholder="dd/mm/yyyy"
                  disabled
                  format={DATE_FORMAT}
                  inputClassName="py-[13px]"
                />
              </FormTeraItem>
              <FormTeraItem
                label="Thời gian dùng thử"
                name="trial_time"
                layout="inline"
                className="flex items-start"
                labelClassName="w-[200px]"
              >
                <Input placeholder="Vui lòng nhập" disabled />
              </FormTeraItem>
            </div>
          </div>
        )} */}
      </div>
    </>
  );
};

export default BusinessForm;
