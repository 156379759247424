import { createContext } from 'react';
import { AuthStore } from './authStore';
import { IAuthStore } from './interface';
import { CommonStore } from './commonStore';
import { ConfirmStore } from './confirmStore';
import { TransactionStore } from './transaction';
import { CartStore } from './CartStore';

export class RootStore {
  authStore: IAuthStore;
  commonStore: CommonStore;
  confirmStore: ConfirmStore;
  transactionStore: TransactionStore;
  cartStore: CartStore;
  constructor() {
    this.authStore = new AuthStore();
    this.commonStore = new CommonStore();
    this.confirmStore = new ConfirmStore();
    this.transactionStore = new TransactionStore();
    this.cartStore = new CartStore();
  }
}
export const rootStore = new RootStore();
export const rootStoreContext = createContext(rootStore);
