import TableTera from '_common/dof/TableTera';
import { ArrowPathOutlined, formatNumber, TrashOutlined } from 'tera-dls';
import EditableCell from './EditableCell';
import EditableRow from './EditableRow';
import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { customTwMerge } from 'tailwind-merge.config';

interface IReplaceValue {
  id: number;
  serviceId: number;
  packageId: number;
}
interface IProps {
  tableProps?: any;
  value: any;
  minNumber?: number;
  isView?: boolean;
  onChangeQuantity?: (id: number, quantity: number) => void;
  onDelete?: (id: number) => void;
  onReplace?: ({ id, serviceId, packageId }: IReplaceValue) => void;
  isCart?: boolean;
  showReplace?: boolean;
}

const CartTable = ({
  tableProps = {},
  value = [],
  onChangeQuantity,
  onDelete,
  onReplace,
  isView = false,
  minNumber = 1,
  isCart = false,
  showReplace = true,
}: IProps) => {
  const [dataSource, setDataSource] = useState<any>([]);

  useEffect(() => {
    value && setDataSource(value);
  }, [value]);

  const handleChangeQuantity = useCallback(
    _.debounce((id, quantity) => {
      onChangeQuantity && onChangeQuantity(id, quantity);
    }, 300),
    [],
  );

  const columns = [
    {
      title: 'Gói dịch vụ/Sản Phẩm',
      dataIndex: 'package',
      width: 200,
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      width: 150,
    },
    {
      title: 'Tổng thời gian',
      dataIndex: 'total_time',
      width: 150,
      render: (val) => `${val ?? 1} tháng`,
    },
    {
      title: 'Đơn giá',
      dataIndex: 'amount',
      width: 150,
      render: (val) => formatNumber(val),
    },
    {
      title: 'Giảm trừ gói cũ',
      dataIndex: 'old_package',
      width: 150,
      render: (val) => formatNumber(val),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'total_amount',
      width: 150,
      render: (val) => formatNumber(val),
    },
    ...(!isView
      ? [
          {
            title: '',
            dataIndex: 'action',
            width: showReplace ? 65 : 40,
            algin: 'center',
            fixed: 'right',
            render: (_, record) => (
              <div className={customTwMerge('flex gap-2.5')}>
                <TrashOutlined
                  className="w-5 text-red-500 cursor-pointer"
                  onClick={() => onDelete && onDelete(record?.id)}
                />
                {showReplace && (
                  <ArrowPathOutlined
                    className="w-5 text-blue-500 cursor-pointer"
                    onClick={() =>
                      onReplace &&
                      onReplace({
                        id: record?.id,
                        serviceId: record?.package?.service?.id,
                        packageId: record?.package?.id,
                      })
                    }
                  />
                )}
              </div>
            ),
          },
        ]
      : []),
  ];

  const mergedColumns: any = columns?.map((col: any) => {
    return {
      ...col,
      onCell: (record) => {
        return {
          dataIndex: col.dataIndex,
          title: col.title,
          record,
          onChangeQuantity: handleChangeQuantity,
          isView,
          minNumber,
          hiddenLength: tableProps?.hiddenColumns?.length ?? 0,
          isCart,
        };
      },
    };
  });

  return (
    <TableTera
      {...tableProps}
      zebra={false}
      data={dataSource}
      rowKey={'id'}
      columns={mergedColumns}
      className="rounded-[6px]"
      components={{
        body: {
          cell: EditableCell,
          row: EditableRow,
        },
      }}
    />
  );
};

export default CartTable;
