import { IRouteProps } from '_common/interface/router';
import BusinessInformation from '.';

export const BusinessInformationRouter: IRouteProps[] = [
  {
    key: '1',
    path: 'information',
    component: <BusinessInformation value={'#e1'} />,
  },
  {
    key: '2',
    path: 'represent',
    component: <BusinessInformation value={'#e2'} />,
  },
  {
    key: '3',
    path: 'other',
    component: <BusinessInformation value={'#e3'} />,
  },
  {
    key: '4',
    path: 'registration',
    component: <BusinessInformation value={'#e4'} />,
  },
];
