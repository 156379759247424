import { IRouteProps } from '_common/interface/router';
import UserInformation from '.';

export const UserInformationRouter: IRouteProps[] = [
  {
    key: '1',
    path: 'information',
    component: <UserInformation value={'#e1'} />,
  },
  {
    key: '2',
    path: 'login',
    component: <UserInformation value={'#e2'} />,
  },
  {
    key: '3',
    path: 'registration',
    component: <UserInformation value={'#e3'} />,
  },
];
