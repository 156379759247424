import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const UserEndpoint = `${endpoint}/portal/user`;
export const UserApi = {
  getProfile: async () =>
    await api
      .get(`${UserEndpoint}/get-profile`)
      .then((result) => result.data?.data),
  update: async (params: any) =>
    await api
      .put(`${UserEndpoint}/update-profile`, params)
      .then((result) => result.data),
  changePassword: async (params: any) =>
    await api
      .put(`${UserEndpoint}/change-password`, params)
      .then((result) => result.data?.data),
};
