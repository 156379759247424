const PasswordIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={'1em'}
    height={'1em'}
    fill="none"
    {...props}
  >
    <path
      fill="#374151"
      d="M16.735 8.786h-1.19v-2.38A5.954 5.954 0 0 0 9.591.453 5.955 5.955 0 0 0 3.64 6.406h2.38a3.567 3.567 0 0 1 3.572-3.572 3.567 3.567 0 0 1 3.572 3.572v2.38H2.449a2.388 2.388 0 0 0-2.38 2.381v11.905c0 1.31 1.07 2.381 2.38 2.381h14.286a2.388 2.388 0 0 0 2.381-2.38V11.166c0-1.31-1.072-2.38-2.381-2.38Zm0 14.286H2.449V11.167h14.286v11.905Zm-7.143-3.571c1.31 0 2.381-1.072 2.381-2.381a2.388 2.388 0 0 0-2.38-2.381 2.388 2.388 0 0 0-2.382 2.38c0 1.31 1.072 2.382 2.381 2.382Z"
    />
  </svg>
);
export default PasswordIcon;
