import Menu from 'pages/Dashboard/containers/Menu';
import SideBar from 'pages/Dashboard/containers/SideBar';
import { Outlet } from 'react-router-dom';

const DashboardLayout = () => {
  return (
    <div className="flex h-screen max-h-screen overflow-hidden xl:bg-[#F3F3F9]">
      <div className="hidden xl:block">
        <Menu />
      </div>
      <Outlet />
      <div className="hidden xl:block">
        <SideBar />
      </div>
    </div>
  );
};

export default DashboardLayout;
