export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_BACKEND_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_BACKEND_FORMAT = 'YYYY-MM-DD - HH:mm:ss';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY - HH:mm';
export const LIMIT_MAX_DATE_TIME = '01/01/2038';
export const LIMIT_MIN_DATE_TIME = '01/01/1970';
export const LOCATION_KEY = 'location_id';

export const LocalStorage = {
  Token: 'tera_cms_token',
  Device: 'tera_device_init',
  Permission: 'tera_permission',
  StockId: 'tera_stock_id',
  LocationId: 'tera_location_id',
  RefCode: 'tera_ref_code',
};

// const isDev = process.env.NODE_ENV === 'development';

export const endpointMockData =
  'https://c271729d-0a4c-448c-9d8b-f8523695ef42.mock.pstmn.io';

export const endpoint = `${process.env.REACT_APP_API}/api`;

export const facebookEndpoint = `${process.env.REACT_APP_FACEBOOK_API}`;

export const hrmEndpoint = `${process.env.REACT_APP_HRM_API}/api`;

export const crmEndpoint = `${process.env.REACT_APP_CRM_API}/api`;

export const requestEndpoint = `${process.env.REACT_APP_REQ_API}/api`;

export const authEndpoint = `${process.env.REACT_APP_AUTH_API}/api`;

export const adminEndpoint = `${process.env.REACT_APP_ADMIN_API}/api`;

export const serverUrl = process.env.REACT_APP_API;

export const socketUrl = process.env.REACT_APP_SOCKET;

export const zaloAuthApi = process.env.REACT_APP_ZALO_AUTH_API;

export const zaloOpenApi = process.env.REACT_APP_ZALO_OPEN_API;

export const webApi = `${process.env.REACT_APP_WEB_API}/api`;

export const webClientUrl = `${process.env.REACT_APP_WEB_CLIENT}`;

export const config = {
  google: {
    clientID: '',
    keyGMap: '',
  },
  fbConfig: {
    appId: '',
    version: 'v1.0',
  },
  hasHeader: false,
  hasMobile: true,
  templates: ['tera'],
  languages: ['vn'],
  app: {},
  uploadKey: '9074c259a7',
  appId: '2',
  privateKey: process.env.REACT_APP_PRIVATE_KEY,
};

export const gender = {
  male: 'Nam',
  female: 'Nữ',
};

export const genderFull = {
  all: 'Tất cả',
  male: 'Nam',
  female: 'Nữ',
};

export const REGEX = {
  PHONE_NUMBER: /^0[0-9+ \-]{7,19}$/,
  KEY: /^[a-z]*$/,
  CODE: /^[a-zA-Z0-9_]*$/,
  EMAIL: /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)*(\.[a-zA-Z]{2,})$/,
  IDENTIFY_NUMBER: /^(?:[0-9]{9,12})?$/,
  PASSWORD: /^[a-zA-Z0-9!@#$%^&*()_+=-]+$/,
  USERNAME: /^[a-zA-Z0-9!@#$%^&*()_+=\-\.]+$/,
  WEBSITE: /^(https?|ftp):\/\/([^\s\/]+)(\/[^\s]*)*$/,
  CARD_NUMBER: /^[a-z0-9]+$/u,
};

export const month = {
  1: 'Tháng 1',
  2: 'Tháng 2',
  3: 'Tháng 3',
  4: 'Tháng 4',
  5: 'Tháng 5',
  6: 'Tháng 6',
  7: 'Tháng 7',
  8: 'Tháng 8',
  9: 'Tháng 9',
  10: 'Tháng 10',
  11: 'Tháng 11',
  12: 'Tháng 12',
};

export const timeFormat = {
  date_time: 'DD/MM/YYYY HH:mm',
  YYYY_MM_DD: 'YYYY/MM/DD HH:mm',
  time_half: 'hh:mm',
  time_full: 'HH:mm',
};

export const TypesOverTime = {
  weekday: 'Ngày thường',
  weekday_night: 'Ngày thường (đêm)',
  off: 'Ngày nghỉ',
  off_night: 'Ngày nghỉ (đêm)',
  holiday: 'Ngày lễ',
  holiday_night: 'Ngày lễ (đêm)',
};

export const TypesOverForm = {
  payroll: 'Tính lương',
  compensatory_leave: 'Nghỉ bù',
};

export const StatusYesNo = {
  0: {
    title: 'Không',
    color: 'red03',
  },
  1: {
    title: 'Có',
    color: 'green03',
  },
};

export const optionsCustom = {
  label: 'Đứng đầu',
  value: 0,
};

export const PAGE_KEY = {
  PRICE_QUOTATION: 'crm_quotation',
};

export const STATUS_APPROVAL = {
  CANCEL: 'cancel',
};
