import { useLocation, useNavigate } from 'react-router-dom';
import Searching from './containers/Searching';
import { getQueryParams, Row, updateQueryParams } from 'tera-dls';
import UsedPackage from './containers/UsedPackage';
import RecommendedPackage from './containers/RecommendedPackage';
import { useStores } from '_common/hooks';
import { toJS } from 'mobx';
import NoPermission from '_common/component/NoPermission';

const ServicePackage = () => {
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const navigate = useNavigate();
  const { authStore } = useStores();
  const { user } = authStore;
  const { type, business } = toJS(user);

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }): void => {
    handleUpdateQueryParams({ keyword });
  };

  const isCancel = business?.status === 'cancelled';
  if (type !== 'owner' || isCancel) {
    return <NoPermission />;
  }
  return (
    <main className="px-5 py-[26px] gap-[16px] flex flex-col">
      <header>
        <Searching onSearch={handleSearch} />
      </header>
      <Row className="gap-10">
        <Row>
          <UsedPackage key={1} />
        </Row>
        <Row>
          <RecommendedPackage key={2} />
        </Row>
      </Row>
    </main>
  );
};

export default ServicePackage;
