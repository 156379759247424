import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const InvoiceEndpoint = `${endpoint}/portal/bill`;

const InvoiceApi = {
  getList: async ({ params }) =>
    await api
      .get(`${InvoiceEndpoint}/list`, params)
      .then((result) => result.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${InvoiceEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
  pay: async ({ params }) =>
    await api
      .put(`${InvoiceEndpoint}/pay`, params)
      .then((result) => result.data),
  create: async (params: any) =>
    await api
      .post(`${InvoiceEndpoint}/create`, params)
      .then((result) => result.data),
  // getPayment: async ({ params }) =>
  //   await api
  //     .post(`${CartEndpoint}/payment-cart`, params)
  //     .then((result) => result.data?.data),
  // create: async (params: any) =>
  //   await api
  //     .post(`${CartEndpoint}/create`, params)
  //     .then((result) => result.data),
  // updateQuantity: async ({ id, params }) =>
  //   await api
  //     .put(`${CartEndpoint}/update/${id}`, params)
  //     .then((result) => result.data),
  // changePackage: async ({ id, params }) =>
  //   await api
  //     .put(`${CartEndpoint}/replace/${id}`, params)
  //     .then((result) => result.data),
  // delete: async ({ id }) =>
  //   await api
  //     .delete(`${CartEndpoint}/delete/${id}`)
  //     .then((result) => result.data),
};

export default InvoiceApi;
