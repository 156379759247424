import classNames from 'classnames';
import { ReactNode } from 'react';

interface PrimaryButton {
  title: string | ReactNode;
  size?: 'lg' | 'md';
  onClick?: () => void;
}

function PrimaryButton({ title, size, onClick }: PrimaryButton) {
  return (
    <div
      className={classNames('btn-primary max-w-max', {
        'btn-primary-lg': size === 'lg',
      })}
      onClick={onClick}
    >
      {title}
    </div>
  );
}

export default PrimaryButton;
