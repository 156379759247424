import React from 'react';
import { Search, SearchProps } from 'tera-dls';

function PrimarySearch(props: SearchProps) {
  return (
    <Search
      placeholder="Tìm kiếm ứng dụng"
      containerClassName="w-full"
      className="w-full focus:ring-0 border-transparent p-3 pl-10 rounded-full bg-[#F3F4F6] shadow-[0_4px_4px_0_rgba(0,0,0,0.05),0_-4px_4px_0_rgba(0,0,0,0.03)]"
      {...props}
    />
  );
}

export default PrimarySearch;
