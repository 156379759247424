import { useMutation } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import AffiliateApi from 'pages/Dashboard/_api/affiliate';
import registrationAffiliate from 'styles/images/dashboard/registration_affiliate.png';
import { Modal, XMarkOutlined } from 'tera-dls';

interface IProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => any;
}
const RegisterModal = (props: IProps) => {
  const { onClose, open, onSuccess } = props;

  const { mutate: mutateAction } = useMutation(() => AffiliateApi.register(), {
    onSuccess: (res) => {
      if (res?.code === 200) {
        onClose();
        onSuccess && onSuccess();
      }
    },
    onError(error: any) {
      ErrorToast({ errorProp: error?.data });
    },
  });

  const handleRegister = () => {
    mutateAction();
  };

  return (
    <Modal
      title={''}
      destroyOnClose
      closeIcon={false}
      onCancel={onClose}
      width={1500}
      maskClosable={false}
      open={open}
      centered={true}
      modalRender={() => (
        <div
          className="bg-white text-white rounded-[50px] p-[30px] flex flex-col items-center h-[900px] justify-between"
          style={{
            backgroundImage: `url(${registrationAffiliate})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className=" w-full">
            <XMarkOutlined
              className="w-[50px] float-end cursor-pointer"
              onClick={onClose}
            />
          </div>
          <div className="flex flex-col gap-[20px] w-full ml-[93px] mb-[80px]">
            <h3 className="text-base">Đăng ký AFFILIATES miễn phí.</h3>
            <div
              onClick={handleRegister}
              className="cursor-pointer py-[14px] font-semibold rounded-[51px] w-[132px] flex justify-center text-base"
              style={{
                background:
                  'linear-gradient(133.99deg, #18A1CC 16.37%, #92D1FF 94.77%)',
                boxShadow: '0px 2px 10px rgba(101, 192, 237, 0.5)',
              }}
            >
              Đăng ký
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default RegisterModal;
